import * as React from 'react';
import { useForm } from 'react-hook-form';
import { zodResolver } from '@hookform/resolvers/zod';
import { enqueueSnackbar } from 'notistack';
import { Box, Typography, Grid, Button, Stack, FormControl } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { AxiosError } from 'axios';

import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { getServerSetupSchema } from '../schemas';
import { SubmitServerState, ServerSetupProps, ServerFormState } from '../types';
import { useDeviceDetect } from '../../../hooks/useDeviceDetect';
import { TimezoneSelector } from '../../../components/FormFields/TimezoneSelector/TimezoneSelector';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { SEO } from '../../../components/SEO/SEO';
import { useScrollToTop } from '../../../hooks/useScrollToTop';
import { useKeyPress } from '../../../hooks/useKeyPress';
import { WATCHED_KEYS } from '../constants';
import { ESetupStep } from '../../../api/Api';

export const ServerSetup: React.FC<ServerSetupProps> = (props): JSX.Element => {
	useScrollToTop();
	const device = useDeviceDetect();
	const { setIndexPage, getCurrentStep } = props;
	const { t } = useTranslation();
	const api = useSwaggerApi();
	const [submitServerState, setSubmitServerState] = React.useState<SubmitServerState>({
		creating: false,
		created: false,
		error: null,
	});

	const [serverFormState, setServerFormState] = React.useState<ServerFormState>({
		hostname: '',
		tokenUrl: '',
		timezone: '',
	});

	const [manualTokenUrl, setManualTokenUrl] = React.useState(false);

	const {
		handleSubmit,
		register,
		control,
		formState: { errors },
		reset,
		watch,
		getValues,
	} = useForm<ServerFormState>({
		mode: 'onChange',
		resolver: zodResolver(getServerSetupSchema(t)),
	});

	const hostname = watch('hostname');
	const tokenUrl = watch('tokenUrl');
	const sanitizeHostnameRegex = /^(http:\/\/|https:\/\/)/;
	const trailingBackslashesRegex = /\/+$/;

	const handleOnSubmit = React.useCallback(async (formValues: ServerFormState) => {
		if (submitServerState.creating) {
			return;
		}

		setSubmitServerState({
			created: false,
			creating: true,
			error: null,
		});

		try {
			await api.setup.createServerSetup({
				hostname: formValues.hostname,
				tokenUrl: formValues.tokenUrl,
				timezone: formValues.timezone,
			});
			setSubmitServerState({
				created: true,
				creating: false,
				error: null,
			});
			enqueueSnackbar(t('page.setupWizard.actionMessages.serverSetupSuccess'), {
				variant: 'success',
				persist: false,
			});
			setIndexPage(getCurrentStep(ESetupStep.SMTP));
		} catch (error) {
			setSubmitServerState({
				created: true,
				creating: false,
				error: error as AxiosError,
			});
			console.error(error);
		}
	}, []);

	const handleKeyDown = useKeyPress(WATCHED_KEYS, handleSubmit(handleOnSubmit));

	const loadUpProps = React.useCallback(async () => {
		setSubmitServerState({
			created: false,
			creating: true,
			error: null,
		});

		try {
			const response = await api.setup.getServerSetup();
			const serverSetupData = response.data;

			const fetchedForm: ServerFormState = {
				hostname: serverSetupData.hostname ?? '',
				tokenUrl: serverSetupData.tokenUrl ?? '',
				timezone: serverSetupData.timezone ?? '',
			};

			setSubmitServerState({
				created: true,
				creating: false,
				error: null,
			});

			setServerFormState(fetchedForm);
			reset(fetchedForm);

			// In case that tokenUrl already has different hostname than the one in hostname field
			const sanitizedHostname = fetchedForm.hostname
				.replace(sanitizeHostnameRegex, '')
				.replace(trailingBackslashesRegex, '')
				.trim();
			const standardTokenUrlRegex = new RegExp(`^wss://${sanitizedHostname}/ws/token$`);
			if (fetchedForm.hostname && !fetchedForm.tokenUrl.match(standardTokenUrlRegex)) {
				setManualTokenUrl(true);
			}
		} catch (error) {
			setSubmitServerState({
				created: true,
				creating: false,
				error: error as AxiosError,
			});

			console.error(error);
		}
	}, [serverFormState]);

	React.useEffect(() => {
		loadUpProps();
	}, []);

	React.useEffect(() => {
		if (!hostname && tokenUrl) {
			setManualTokenUrl(true);
		}

		// In case that tokenUrl already has different hostname than the one in hostname field
		if (hostname) {
			const sanitizedHostname = hostname
				.replace(sanitizeHostnameRegex, '')
				.replace(trailingBackslashesRegex, '')
				.trim();
			const standardTokenUrlRegex = new RegExp(`^wss://${sanitizedHostname}/ws/token$`);
			if (tokenUrl && !tokenUrl.match(standardTokenUrlRegex)) {
				setManualTokenUrl(true);
			} else {
				setManualTokenUrl(false);
			}
		}
	}, [tokenUrl]);

	React.useEffect(() => {
		if (hostname && !manualTokenUrl) {
			const sanitizedHostname = hostname
				.replace(sanitizeHostnameRegex, '')
				.replace(trailingBackslashesRegex, '')
				.trim();

			const tokenUrl = `wss://${sanitizedHostname}/ws/token`;

			reset({
				...getValues(),
				tokenUrl,
			});
		}
	}, [hostname]);

	React.useEffect(() => {
		if (!hostname && !tokenUrl) {
			setManualTokenUrl(false);
		}
	}, [tokenUrl, hostname]);

	return (
		<Box onKeyDown={handleKeyDown}>
			<SEO
				title={t('page.setupWizard.texts.serverSetup.title')}
				description={t('page.setupWizard.texts.serverSetup.description')}
			/>
			<Stack direction='column' spacing={2}>
				<Typography align='center'>{t('page.setupWizard.texts.serverSetup.text')}</Typography>
				<br />
			</Stack>
			<Grid container spacing={2}>
				<Grid item xs={12}>
					<TextField
						name={'hostname'}
						register={register}
						label={t('page.setupWizard.texts.serverSetup.hostname.label')}
						InputLabelProps={{ shrink: true }}
						error={errors.hostname}
						disabled={submitServerState.creating}
						helperText={t('page.setupWizard.texts.serverSetup.hostname.helperText')}
					/>
				</Grid>
				<Grid item xs={12}>
					<TextField
						name={'tokenUrl'}
						register={register}
						label={t('page.setupWizard.texts.serverSetup.tokenUrl.label')}
						InputLabelProps={{ shrink: true }}
						error={errors.tokenUrl}
						disabled={submitServerState.creating}
						helperText={t('page.setupWizard.texts.serverSetup.tokenUrl.helperText')}
					/>
				</Grid>
				<Grid item xs={12}>
					<FormControl fullWidth>
						<TimezoneSelector
							control={control}
							name={'timezone'}
							error={errors.timezone}
							disabled={submitServerState.creating}
							updateValue={serverFormState.timezone}
						/>
					</FormControl>
				</Grid>
				<Grid
					item
					xs={12}
					sx={{
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<Button
						fullWidth={device !== 'desktop'}
						variant='contained'
						disabled={submitServerState.creating}
						type='submit'
						onClick={handleSubmit(handleOnSubmit)}
					>
						{t('page.setupWizard.texts.serverSetup.next')}
					</Button>
				</Grid>
			</Grid>
		</Box>
	);
};
