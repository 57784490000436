export enum ETokenDetailSections {
	GENERAL = 'GENERAL',
	AUDIT_LOGS = 'AUDIT_LOGS',
}

export enum ESystemName {
	ANDROID = 'android',
	IOS = 'ios',
	IPADOS = 'ipados',
	WINDOWS = 'windows',
}

export enum ECertificateStatus {
	ACTIVE = 'active',
	REVOKED = 'revoked',
	EXPIRED = 'expired',
}
