import { useEffect, useMemo, useState } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import {
	Add as AddIcon,
	Delete as DeleteIcon,
	Edit as EditIcon,
	Public as PublicIcon,
	Publish as PublishIcon,
} from '@mui/icons-material';
import { Box, Button, IconButton, Tooltip } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	type MRT_ColumnDef as MRTColumnDef,
	MaterialReactTable,
	useMaterialReactTable,
} from 'material-react-table';
import { enqueueSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { FloatingButtonSave } from '../Buttons/FloatingButton/FloatingButtonSave';
import { NetworkAddressesImportModal } from '../NetworkAddressesImportModal/NetworkAddressesImportModal';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { useTableQuery } from '../../hooks/useTableQuery';
import { CreateNetworkPolicyDto } from '../../api/Api';
import { FormValues, NetworkAddress, Props } from './types';
import { networkAddressSchema, networkPolicyFormSchema } from './schema';
import { useUnsavedChangesContext } from '../../hooks/useUnsavedChangesContext';
import { areNetworkAddressArraysEqual, validateNetworkAddress } from './utils';

export const NetworkAddressTable: React.FC<Props> = ({ tenantID }) => {
	const [networkAddresses, setNetworkAddresses] = useState<CreateNetworkPolicyDto['networkAddresses']>([]);
	const [validationErrors, setValidationErrors] = useState<Record<string, string | undefined>>({});
	const [importModalOpen, setImportModalOpen] = useState(false);

	const { setHasUnsavedChanges } = useUnsavedChangesContext();

	const { t } = useTranslation();

	const { isAllowed } = useACL();

	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const { rowSelection, setRowSelection } = useTableQuery(['networkAddress']);

	const api = useSwaggerApi();

	const { data, error, isLoading, isRefetching, refetch } = useQuery({
		queryKey: [EQueryKey.NETWORK_POLICY_LIST_QUERY, tenantID],
		queryFn: async () => {
			try {
				const { data } = await api.networkPolicies.getNetworkPolicies({
					baseURL: tenantID ? `/api/v1/tenants/${tenantID}` : undefined,
				});

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		select: (data) => {
			data.entities.forEach((networkAddress) => {
				networkAddressSchema.parse(networkAddress);
			});

			return data;
		},
	});

	useEffect(() => {
		if (data && data.entities.length > 0) {
			setNetworkAddresses(data.entities.map((entity) => entity.networkAddress));
		}
	}, [data]);

	useEffect(() => {
		if (
			areNetworkAddressArraysEqual(networkAddresses, data?.entities.map((entity) => entity.networkAddress) ?? [])
		) {
			setHasUnsavedChanges(false);
		} else {
			setHasUnsavedChanges(true);
		}
	}, [networkAddresses, data]);

	const { mutate, isPending } = useMutation({
		mutationFn: (data: CreateNetworkPolicyDto) =>
			api.networkPolicies.createNetworkPolicy(data, {
				baseURL: tenantID ? `/api/v1/tenants/${tenantID}` : undefined,
			}),
		onSuccess: () => {
			refetch();
			enqueueSnackbar(t('component.networkAddressTable.actionMessage.updateSuccessful'), {
				variant: 'success',
			});
		},
	});

	const { control, trigger, reset, setValue } = useForm<FormValues>({
		defaultValues: { file: null },
		mode: 'onChange',
		resolver: zodResolver(networkPolicyFormSchema(t)),
	});

	const columns: MRTColumnDef<NetworkAddress>[] = [
		{
			accessorKey: 'networkAddress',
			header: t('component.networkAddressTable.header.networkAddress'),
			enableHiding: false,
			Cell: ({ renderedCellValue }) => (
				<Box
					sx={{
						display: 'flex',
						alignItems: 'center',
						gap: '1rem',
					}}
				>
					<PublicIcon />
					<span>{renderedCellValue}</span>
				</Box>
			),
			muiEditTextFieldProps: () => ({
				type: 'text',
				required: true,
				error: !!validationErrors?.networkAddress,
				helperText: validationErrors?.networkAddress,
				onFocus: () =>
					setValidationErrors({
						...validationErrors,
						networkAddress: undefined,
					}),
			}),
		},
	];

	const memoizedData = useMemo(
		() => networkAddresses.map((networkAddress, index) => ({ id: index, networkAddress })),
		[networkAddresses],
	);

	const importNetworkAddresses = (importedNetworkAddresses: string[]) => {
		setNetworkAddresses((prev) => {
			const uniqueAddresses = importedNetworkAddresses.filter((range) => !prev.includes(range));

			return [...prev, ...uniqueAddresses];
		});
	};
	const table = useMaterialReactTable({
		columns,
		data: memoizedData,
		createDisplayMode: 'row',
		editDisplayMode: 'row',
		enableEditing: true,
		enableRowActions: true,
		enableRowSelection: isAllowed([EPermission.NETWORK_POLICY_DELETE_ALL]),
		initialState: { density: 'compact' },
		layoutMode: 'grid',
		localization: MRTLocalization,
		muiToolbarAlertBannerProps: {
			color: 'error',
			children: error ? <>{error}</> : undefined,
		},
		positionActionsColumn: 'last',
		rowCount: networkAddresses.length,
		state: {
			isLoading,
			rowSelection,
			isSaving: isPending,
			showAlertBanner: error !== null,
			showProgressBars: isRefetching,
		},
		getRowId: (row) => row.id?.toString() || '',
		muiTablePaperProps: ({ table }) => ({
			style: {
				zIndex: table.getState().isFullScreen ? 1250 : undefined,
				boxShadow: 'none',
				outline: '1px solid #e0e0e0',
			},
		}),
		onCreatingRowCancel: () => setValidationErrors({}),
		onCreatingRowSave: ({ exitCreatingMode, values }) => {
			if (!isAllowed([EPermission.NETWORK_POLICY_CREATE])) {
				return;
			}

			const newValidationError = validateNetworkAddress(values.networkAddress, t);
			if (newValidationError) {
				setValidationErrors({ networkAddress: newValidationError });

				return;
			}

			setValidationErrors({});
			setNetworkAddresses((prevState) => [...prevState, values.networkAddress]);
			exitCreatingMode();
		},
		onEditingRowCancel: () => setValidationErrors({}),
		onEditingRowSave: ({ exitEditingMode, values, row }) => {
			if (!isAllowed([EPermission.NETWORK_POLICY_UPDATE])) {
				return;
			}

			const newValidationError = validateNetworkAddress(values.networkAddress, t);
			if (newValidationError) {
				setValidationErrors({ networkAddress: newValidationError });

				return;
			}

			setValidationErrors({});
			setNetworkAddresses((prevState) => {
				const updatedNetworkAddresses = [...prevState];
				updatedNetworkAddresses[row.index] = values.networkAddress;

				return updatedNetworkAddresses;
			});
			exitEditingMode();
		},
		onRowSelectionChange: setRowSelection,
		renderRowActions: ({ row, table }) => (
			<Box sx={{ display: 'flex', gap: '1rem' }}>
				{isAllowed([EPermission.NETWORK_POLICY_UPDATE]) && (
					<Tooltip
						arrow
						placement='bottom'
						title={t('component.networkAddressTable.tooltip.edit')}
						enterDelay={500}
					>
						<IconButton onClick={() => table.setEditingRow(row)}>
							<EditIcon />
						</IconButton>
					</Tooltip>
				)}
				{isAllowed([EPermission.NETWORK_POLICY_DELETE_ALL]) && (
					<Tooltip
						arrow
						placement='bottom'
						title={t('component.networkAddressTable.tooltip.delete')}
						enterDelay={500}
					>
						<IconButton
							color='error'
							onClick={() => {
								setNetworkAddresses((prev) =>
									prev.filter((networkAddress) => networkAddress !== row.original.networkAddress),
								);
								setRowSelection({});
							}}
						>
							<DeleteIcon />
						</IconButton>
					</Tooltip>
				)}
			</Box>
		),
		renderToolbarInternalActions: ({ table }) => {
			const selectedNetworkAddresses = table.getSelectedRowModel().rows.map((row) => row.original.networkAddress);

			return (
				<Box sx={{ display: 'flex', gap: '1rem' }}>
					<MRTToggleGlobalFilterButton table={table} />
					<MRTToggleFiltersButton table={table} />
					<MRTToggleDensePaddingButton table={table} />
					<MRTFullScreenToggleButton table={table} />
					{isAllowed([EPermission.NETWORK_POLICY_CREATE]) && (
						<Tooltip
							arrow
							placement='top'
							title={t('component.networkAddressTable.tooltip.import')}
							enterDelay={500}
						>
							<span>
								<Button
									startIcon={<PublishIcon />}
									variant='outlined'
									onClick={() => setImportModalOpen(true)}
								>
									{t('component.networkAddressTable.header.import')}
								</Button>
							</span>
						</Tooltip>
					)}
					<Tooltip
						arrow
						placement='top'
						title={t('component.networkAddressTable.tooltip.delete')}
						enterDelay={500}
					>
						<span>
							<Button
								color='error'
								variant='contained'
								disabled={selectedNetworkAddresses.length === 0}
								onClick={() => {
									setNetworkAddresses((prev) =>
										prev.filter(
											(networkAddress) => !selectedNetworkAddresses.includes(networkAddress),
										),
									);
									setRowSelection({});
								}}
								sx={{
									display: 'flex',
									alignItems: 'flex-start',
									gap: '0.5rem',
									width: 130,
								}}
							>
								<DeleteIcon />
								{t('component.networkAddressTable.header.delete')}
							</Button>
						</span>
					</Tooltip>
				</Box>
			);
		},
		renderTopToolbarCustomActions: ({ table }) => (
			<>
				{isAllowed([EPermission.NETWORK_POLICY_CREATE]) && (
					<Tooltip
						arrow
						placement='top'
						title={t('component.networkAddressTable.tooltip.add')}
						enterDelay={500}
					>
						<span>
							<Button
								startIcon={<AddIcon />}
								variant='outlined'
								onClick={() => table.setCreatingRow(true)}
							>
								{t('component.networkAddressTable.header.add')}
							</Button>
						</span>
					</Tooltip>
				)}
			</>
		),
	});

	return (
		<Box>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
				<MaterialReactTable table={table} />
			</LocalizationProvider>
			{isAllowed([EPermission.NETWORK_POLICY_CREATE, EPermission.NETWORK_POLICY_UPDATE], false) && (
				<FloatingButtonSave
					disabled={isPending}
					ariaLabel={t('component.networkAddressTable.ariaLabel.save')}
					tooltipTitle={t('component.networkAddressTable.tooltip.save')}
					tooltipDelay={500}
					onClick={() => mutate({ networkAddresses })}
				/>
			)}
			{isAllowed([EPermission.NETWORK_POLICY_CREATE]) && (
				<Controller
					name={'file'}
					control={control}
					render={({ field: { value }, fieldState: { error } }) => (
						<NetworkAddressesImportModal
							open={importModalOpen}
							control={control}
							name={'file'}
							value={value}
							error={error}
							disabled={isLoading || isPending}
							trigger={trigger}
							onClose={() => {
								setImportModalOpen(false);
								reset();
							}}
							onImport={importNetworkAddresses}
							setValue={setValue}
						/>
					)}
				/>
			)}
		</Box>
	);
};
