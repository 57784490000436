import * as React from 'react';
import { Box, Tabs, Tab, Paper, useTheme } from '@mui/material';
import { TabPanel, TabContext } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { useNavigate } from '../../hooks/useNavigate';
import { TabConfig } from './types';
import { ETenantSections } from './enums';
import { usePathName } from '../../hooks/usePathName';
import { Preloader } from '../../components/Preloader/Preloader';
import { useACL } from '../../hooks/useACL';
import { EPermission } from '../../enums/permission/EPermission';
import { SEO } from '../../components/SEO/SEO';

const GeneralSection = React.lazy(() => import('./Components/GeneralSection'));
const IdentityStoreSection = React.lazy(() => import('./Components/IdentityStoreSection'));
const UsersSection = React.lazy(() => import('./Components/UsersSection'));
const InvitationsSection = React.lazy(() => import('./Components/InvitationsSection'));
const NetworkSection = React.lazy(() => import('./Components/NetworkSection'));

export const TenantDetail: React.FC = (): JSX.Element => {
	const theme = useTheme();
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const location = useLocation();
	const navigate = useNavigate();
	const pathname = usePathName();

	const [currentSection, setCurrentSection] = React.useState<ETenantSections>(
		location.state?.tab ?? ETenantSections.GENERAL,
	);

	const handleOnChangeTab = React.useCallback(
		(event: React.ChangeEvent<{}>, newValue: ETenantSections) => {
			navigate(pathname, { state: { tab: newValue } });
		},
		[navigate, pathname],
	);

	const tabs = React.useMemo<TabConfig[]>(
		() => [
			{
				value: ETenantSections.GENERAL,
				label: t('page.tenants.sections.general.title'),
			},
			{
				value: ETenantSections.IDENTITY_STORES,
				label: t('page.tenants.sections.identityStore.list.title'),
			},
			{
				value: ETenantSections.USERS,
				label: t('page.tenants.sections.users.list.title'),
			},
			{
				value: ETenantSections.INVITATIONS,
				label: t('page.tenants.sections.invitations.list.title'),
			},
			{
				value: ETenantSections.NETWORK,
				label: t('page.tenants.sections.network.title'),
			},
		],
		[],
	);

	React.useEffect(() => {
		if (location.state?.tab) {
			setCurrentSection(location.state?.tab);
		}
	}, [location.state?.tab]);

	React.useEffect(() => {
		if (currentSection && location.state?.tab !== currentSection) {
			navigate(`${pathname}`, { state: { ...location.state, tab: currentSection }, replace: true });
		}
	}, []);

	return (
		<Box sx={{ height: '100%', marginBottom: 10 }}>
			<SEO title={t('page.tenants.detail.title')} description={t('page.tenants.detail.description')} />
			<Paper elevation={3}>
				<TabContext value={currentSection}>
					<Box sx={{ borderBottom: 1, borderColor: theme.palette.divider }}>
						<Tabs
							value={currentSection}
							onChange={handleOnChangeTab}
							TabIndicatorProps={{ style: { transition: 'none' } }}
						>
							{tabs.map((tab) => (
								<Tab key={tab.label} label={tab.label} value={tab.value} />
							))}
						</Tabs>
					</Box>
					<React.Suspense fallback={<Preloader percentageHeight={50} />}>
						{isAllowed([EPermission.TENANTS_READ]) && (
							<TabPanel value={ETenantSections.GENERAL}>{<GeneralSection />}</TabPanel>
						)}
						{isAllowed([EPermission.IDENTITY_STORES_READ]) && (
							<TabPanel value={ETenantSections.IDENTITY_STORES}>{<IdentityStoreSection />}</TabPanel>
						)}

						{isAllowed([EPermission.USERS_READ_ALL]) && (
							<TabPanel value={ETenantSections.USERS}>{<UsersSection />}</TabPanel>
						)}
						{isAllowed([EPermission.INVITATIONS_READ]) && (
							<TabPanel value={ETenantSections.INVITATIONS}>{<InvitationsSection />}</TabPanel>
						)}
						{isAllowed([EPermission.NETWORK_POLICY_READ_ALL]) && (
							<TabPanel value={ETenantSections.NETWORK}>{<NetworkSection />}</TabPanel>
						)}
					</React.Suspense>
				</TabContext>
			</Paper>
		</Box>
	);
};
