import * as React from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Paper, Box, Stack, Typography, useMediaQuery, useTheme, Grid, Button, Divider, Tooltip } from '@mui/material';
import { Policy as PolicyIcon } from '@mui/icons-material';
import { Link } from '../../components/Link/Link';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { SecurityPolicyGroupsState, SecurityPolicyRuleSetsState, SecurityPolicyState } from './types';
import { FloatingButtonEdit } from '../../components/Buttons/FloatingButton/FloatingButtonEdit';
import { Heading } from '../../components/Heading/Heading';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { ChipArray } from '../../components/ChipArray/ChipArray';
import { LabeledBox } from '../../components/LabeledBox/LabeledBox';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { GroupDto } from '../../api/Api';
import { useNavigate } from '../../hooks/useNavigate';
import { RuleSetPreview } from './Components/RuleSetPreview/RuleSetPreview';
import { AuthContext } from '../../contexts/AuthContext/AuthContext';

export const SecurityPolicyDetail: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const authContext = React.useContext(AuthContext);
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const [expandedAccordions, setExpandedAccordions] = React.useState<number[]>([]);

	const [securityPolicyState, setSecurityPolicyState] = React.useState<SecurityPolicyState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const [securityPolicyGroupsState, setSecurityPolicyGroupsState] = React.useState<SecurityPolicyGroupsState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const [securityPolicyRuleSetsState, setSecurityPolicyRuleSetsState] = React.useState<SecurityPolicyRuleSetsState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const canEditSecurityPolicy = React.useCallback(() => {
		if (!securityPolicyState.data) {
			return false;
		}
		const userTenantID = authContext.user.user?.tenantID;

		if (typeof userTenantID !== 'number' && userTenantID !== null) {
			return false;
		}

		const securityPolicyTenantID = securityPolicyState.data.tenantID;

		if (userTenantID === null) {
			return true;
		}

		if (securityPolicyTenantID === null) {
			return false;
		}

		const isSameTenantID = securityPolicyTenantID === userTenantID;
		if (isSameTenantID) {
			return true;
		}

		return false;
	}, [securityPolicyState.data, authContext.user.user?.tenantID]);

	const getSecurityPolicy = React.useCallback(async (securityPolicyID: number): Promise<void> => {
		setSecurityPolicyState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});

		try {
			const response = await api.securityPolicies.getSecurityPolicy(securityPolicyID);

			setSecurityPolicyState({
				loading: false,
				loaded: true,
				data: response.data,
				error: null,
			});
		} catch (error) {
			console.error(error);
			setSecurityPolicyState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
		}

		setSecurityPolicyGroupsState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});

		try {
			const query = {
				limit: 2000,
				offset: 0,
			};

			const [userGroupsData, pamTargeGroupsData] = await Promise.all([
				api.securityPolicy.getUserGroupsForSecurityPolicy(securityPolicyID, query),
				api.securityPolicy.getPamTargetGroupsForSecurityPolicy(securityPolicyID, query),
			]);

			const groupsResponse = {
				userGroups: userGroupsData.data.entities.filter((group) => {
					return group.id && group.name;
				}),
				pamTargetGroups: pamTargeGroupsData.data.entities.filter((group) => {
					return group.id && group.name;
				}),
				ruleSets: [],
			};

			setSecurityPolicyGroupsState({
				loading: false,
				loaded: true,
				data: groupsResponse,
				error: null,
			});
		} catch (error) {
			console.error(error);
			setSecurityPolicyGroupsState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
		}

		setSecurityPolicyRuleSetsState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});

		try {
			const query = {
				limit: 2000,
				offset: 0,
			};

			const responseRuleSets = await api.securityPolicy.getDetailedRuleSetsForSecurityPolicy(
				securityPolicyID,
				query,
			);

			setSecurityPolicyRuleSetsState({
				loading: false,
				loaded: true,
				data: responseRuleSets.data.entities,
				error: null,
			});
		} catch (error) {
			console.error(error);
			setSecurityPolicyRuleSetsState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
		}
	}, []);

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	const handleOnUserGroupChipClick = React.useCallback(
		(group: GroupDto) => {
			if (!isAllowed([EPermission.USER_GROUPS_READ])) {
				return;
			}
			navigate(`/users/userGroups/${group.id}`);
		},
		[isAllowed, id],
	);

	const handleOnTargetGroupChipClick = React.useCallback(
		(group: GroupDto) => {
			if (!isAllowed([EPermission.PAM_GROUPS_READ])) {
				return;
			}
			navigate(`/pam/groups/${group.id}`);
		},
		[isAllowed, id],
	);

	const handleToggleAccordion = React.useCallback((id: number, isExpanded: boolean) => {
		setExpandedAccordions((prev) => {
			if (isExpanded) {
				return prev.includes(id) ? prev : [...prev, id];
			}

			return prev.filter((accordionId) => accordionId !== id);
		});
	}, []);

	const handleOnButtonExpandAll = React.useCallback(() => {
		const allAccordionIds = securityPolicyRuleSetsState.data?.map((ruleSet) => ruleSet.id);
		setExpandedAccordions(allAccordionIds ? allAccordionIds : []);
	}, [securityPolicyRuleSetsState.data]);

	const handleOnButtonCollapseAll = React.useCallback(() => {
		setExpandedAccordions([]);
	}, []);

	React.useEffect(() => {
		if (id && !securityPolicyState.loading && !securityPolicyState.loaded && !securityPolicyState.error) {
			getSecurityPolicy(Number(id));
		}
	}, [id, securityPolicyState]);

	React.useEffect(() => {
		if (securityPolicyRuleSetsState.data) {
			setExpandedAccordions(securityPolicyRuleSetsState.data.map((ruleSet) => ruleSet.id));
		}
	}, [securityPolicyRuleSetsState.data]);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={2}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.securityPolicy.detail.title')}
						description={t('page.securityPolicy.detail.description')}
						icon={PolicyIcon}
					/>
					{securityPolicyState.data && (
						<Stack
							spacing={1}
							sx={{
								width: getStackWidth(),
							}}
						>
							<Heading label={t('page.securityPolicy.detail.subtitle.general')} />
							<Grid container gap={2}>
								<Grid item xs={12}>
									<Grid container spacing={1}>
										<Grid item xs={12} sm={4} md={3}>
											<Typography px={1} sx={{ fontWeight: 'bold' }}>
												{`${t('page.securityPolicy.detail.body.name')}: `}
											</Typography>
										</Grid>
										<Grid item xs={12} sm={8} md={9}>
											<Typography px={2}>{securityPolicyState.data.name}</Typography>
										</Grid>
									</Grid>
								</Grid>
								{securityPolicyState.data.description && (
									<Grid item xs={12}>
										<Grid container spacing={1}>
											<Grid item xs={12} sm={4} md={3}>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.securityPolicy.detail.body.description')}: `}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={8} md={9}>
												<Typography px={2}>{securityPolicyState.data.description}</Typography>
											</Grid>
										</Grid>
									</Grid>
								)}
							</Grid>
						</Stack>
					)}

					{securityPolicyGroupsState && (
						<Stack
							spacing={2}
							sx={{
								width: getStackWidth(),
							}}
						>
							<Heading label={t('page.securityPolicy.detail.subtitle.groups')} />
							<LabeledBox label={t('page.securityPolicy.detail.subtitle.userGroups')} sx={{ padding: 2 }}>
								<ChipArray
									chipList={
										isAllowed([EPermission.USER_GROUPS_READ]) ?
											securityPolicyGroupsState.data?.userGroups
										:	undefined
									}
									onChipClick={handleOnUserGroupChipClick}
									wrap={true}
								/>
							</LabeledBox>
							<LabeledBox
								label={t('page.securityPolicy.detail.subtitle.pamTargetGroups')}
								sx={{ padding: 2 }}
							>
								<ChipArray
									chipList={
										isAllowed([EPermission.PAM_GROUPS_READ]) ?
											securityPolicyGroupsState.data?.pamTargetGroups
										:	undefined
									}
									onChipClick={handleOnTargetGroupChipClick}
									wrap={true}
								/>
							</LabeledBox>
						</Stack>
					)}
					{securityPolicyRuleSetsState && (
						<Grid container>
							<Grid item xs={12} lg={8}>
								<Stack spacing={2}>
									<Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
										<Heading label={t('page.securityPolicy.detail.subtitle.ruleSets')} />
										<Box
											sx={{
												display: 'flex',
												justifyContent: 'space-between',
												alignItems: 'center',
												gap: 1,
											}}
										>
											<Tooltip
												title={t('page.securityPolicy.detail.tooltips.expandAll')}
												placement='top'
												enterDelay={500}
												arrow
											>
												<Button
													variant='text'
													color='primary'
													onClick={handleOnButtonExpandAll}
												>
													{t('page.securityPolicy.detail.body.expandAll')}
												</Button>
											</Tooltip>
											<Divider orientation='vertical' flexItem />
											<Tooltip
												title={t('page.securityPolicy.detail.tooltips.collapseAll')}
												placement='top'
												enterDelay={500}
												arrow
											>
												<Button
													variant='text'
													color='primary'
													onClick={handleOnButtonCollapseAll}
												>
													{t('page.securityPolicy.detail.body.collapseAll')}
												</Button>
											</Tooltip>
										</Box>
									</Box>
									<Stack spacing={3}>
										{securityPolicyRuleSetsState.data?.map((ruleSet) => (
											<RuleSetPreview
												key={`rule-set-preview-${ruleSet.id}`}
												data={ruleSet}
												expanded={expandedAccordions.includes(ruleSet.id)}
												onToggle={handleToggleAccordion}
											/>
										))}
									</Stack>
								</Stack>
							</Grid>
						</Grid>
					)}
				</Stack>
			</Paper>
			{isAllowed([EPermission.SECURITY_POLICIES_UPDATE]) && canEditSecurityPolicy() && (
				<Link to={`/security/securityPolicy/edit/${id}`}>
					<FloatingButtonEdit
						ariaLabel={t('page.securityPolicy.list.ariaLabel.editSecurityPolicy')}
						tooltipTitle={t('page.securityPolicy.list.tooltips.editSecurityPolicy')}
					/>
				</Link>
			)}
		</Box>
	);
};
