import * as React from 'react';
import { ValidationResultSectionProps } from './types';
import { Check as CheckIcon, Close as CloseIcon } from '@mui/icons-material';
import {
	Timeline,
	TimelineConnector,
	TimelineContent,
	TimelineItem,
	TimelineOppositeContent,
	timelineOppositeContentClasses,
	TimelineSeparator,
} from '@mui/lab';
import { Box, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { EResultType } from '../../enums';

export const ValidationResultSection: React.FC<ValidationResultSectionProps> = ({ data }) => {
	const { t } = useTranslation();
	const theme = useTheme();
	const formatDate = useFormatDate();
	const matchesMd = useMediaQuery(theme.breakpoints.up('md'));

	const getBgColor = React.useCallback((result: string) => {
		if (result.includes('SUCCESS')) {
			return 'success.light';
		}

		return 'error.light';
	}, []);

	const getActionValueAsString = React.useCallback((value: string, resultType: EResultType) => {
		const parseValue = (value: string): string => {
			try {
				const jsonObject = JSON.parse(value);
				if (typeof jsonObject === 'object' && jsonObject !== null) {
					return Object.keys(jsonObject)
						.map((key) => `${key}: ${jsonObject[key]}`)
						.join(', ');
				}

				return jsonObject;
			} catch (error) {
				console.error(error);

				return value;
			}
		};
		const parseValueToObject = (value: string): Record<any, any> => {
			try {
				const jsonObject = JSON.parse(value);
				if (typeof jsonObject === 'object' && jsonObject !== null) {
					return jsonObject;
				}

				return jsonObject;
			} catch (error) {
				console.error(error);

				return {};
			}
		};

		switch (resultType) {
			case EResultType.FACTORS:
				return {
					name: t('page.action.detail.sections.validationResult.body.factor'),
					value: parseValue(value as string),
				};
			case EResultType.TIME_VALIDATION: {
				const parsedTimeValue = parseValue(value as string);

				return {
					name: t('page.action.detail.sections.validationResult.body.currentTime'),
					value:
						parsedTimeValue ? parsedTimeValue : (
							t('page.action.detail.sections.validationResult.body.invalidData')
						),
				};
			}
			case EResultType.DATE_VALIDATION: {
				const parsedDateValue = parseValue(value as string);

				return {
					name: t('page.action.detail.sections.validationResult.body.currentDate'),
					value:
						parsedDateValue ?
							formatDate(parsedDateValue)
						:	t('page.action.detail.sections.validationResult.body.invalidData'),
				};
			}
			case EResultType.DAY_VALIDATION: {
				const parsedDayValue = parseValue(value as string);

				return {
					name: t('page.action.detail.sections.validationResult.body.currentDay'),
					value:
						parsedDayValue ? parsedDayValue : (
							t('page.action.detail.sections.validationResult.body.invalidData')
						),
				};
			}
			case EResultType.PHONE_CONNECTION_STATUS_VALIDATION:
				return {
					name: t('page.action.detail.sections.validationResult.body.phoneConnectionStatus'),
					value: parseValue(value as string),
				};
			case EResultType.PHONE_IP_VALIDATION:
				return {
					name: t('page.action.detail.sections.validationResult.body.phoneIP'),
					value: parseValue(value as string),
				};
			case EResultType.TIME_RANGES:
				return {
					name: t('page.action.detail.sections.validationResult.body.currentTime'),
					value: parseValue(value as string),
				};
			case EResultType.DATE_TIME_TANGE:
				return {
					name: t('page.action.detail.sections.validationResult.body.currentDateTime'),
					value: parseValue(value as string),
				};
			case EResultType.DAYS:
				return {
					name: t('page.action.detail.sections.validationResult.body.currentDay'),
					value: parseValue(value as string),
				};
			case EResultType.PHONE_IP_AND_RANGE:
				return {
					name: t('page.action.detail.sections.validationResult.body.phoneIP'),
					value: parseValue(value as string),
				};
			case EResultType.LOCATION:
				return {
					name: t('page.action.detail.sections.validationResult.body.location'),
					value: parseValue(value as string),
				};
			case EResultType.PHONE_CONNECTION_STATUSE:
				return {
					name: t('page.action.detail.sections.validationResult.body.phoneConnectionStatus'),
					value: parseValue(value as string),
				};
			case EResultType.SIGNATURE:
				return null;
			case EResultType.INTEGRITY_CHECK:
				return {
					name: t('page.action.detail.sections.validationResult.body.integrityCheck'),
					value:
						parseValueToObject(value as string).isValid ?
							t('page.action.detail.sections.validationResult.body.integrityCheckTrusted')
						:	t('page.action.detail.sections.validationResult.body.integrityCheckUntrusted'),
				};
			default:
				return {
					name: t('page.action.detail.sections.validationResult.body.unknown'),
					value: t('page.action.detail.sections.validationResult.body.invalidData'),
				};
		}
	}, []);

	const getResultTranslationKey = React.useCallback((result: string) => {
		const resultTranslationKey: { [key: string]: string } = {
			LOCATION_VALIDATION_SUCCESS: 'successMessage.locationValidation',
			PIN_VALIDATION_SUCCESS: 'successMessage.pinValidation',
			BIOMETRY_VALIDATION_SUCCESS: 'successMessage.biometryValidation',
			TIME_VALIDATION_SUCCESS: 'successMessage.timeValidation',
			DATE_VALIDATION_SUCCESS: 'successMessage.dateValidation',
			DAY_VALIDATION_SUCCESS: 'successMessage.dayValidation',
			PHONE_IP_VALIDATION_SUCCESS: 'successMessage.phoneIPValidation',
			PHONE_CONNECTION_STATUS_VALIDATION_SUCCESS: 'successMessage.phoneConnectionStatusValidation',
			INTEGRITY_CHECK_SUCCESS: 'successMessage.integrityCheckValidation',

			TIME_NOT_WITHIN_TIMERANGES: 'errorMessage.timeNotWithinTimeRanges',
			DAY_NOT_WITHIN_DAYS: 'errorMessage.dayNotWithinDays',
			GEO_LOCATION_NOT_WITHIN_GEOLOCATIONS: 'errorMessage.geoLocationNotWithinGeolocations',
			PHONE_CONNECTION_STATUS_NOT_PROVIDED: 'errorMessage.phoneConnectionStatusNotProvided',
			PHONE_CONNECTION_STATUS_NOT_ALLOWED: 'errorMessage.phoneConnectionStatusNotAllowed',
			DATE_TIME_NOT_WITHIN_RANGE: 'errorMessage.dateTimeNotWithinRange',
			FACTORS_NOT_PROVIDED: 'errorMessage.factorsNotProvided',
			FACTORS_INSUFFICIENT: 'errorMessage.factorsInsufficient',
			PHONE_IP_NOT_PROVIDED: 'errorMessage.phoneIPNotProvided',
			PHONE_IP_NOT_ALLOWED: 'errorMessage.phoneIPNotAllowed',
			PHONE_IP_NOT_WITHIN_RANGES: 'errorMessage.phoneIPNotWithinRanges',
			INVALID_PIN: 'errorMessage.invalidPIN',
			SIGNATURE_VERIFICATION_FAILED: 'errorMessage.signatureVerificationFailed',
			INTEGRITY_CHECK_FAILED: 'errorMessage.integrityCheckFailed',
		};

		return resultTranslationKey[result];
	}, []);

	return (
		<Box>
			<Timeline
				sx={{
					[`& .${timelineOppositeContentClasses.root}`]: {
						flex: matchesMd ? 0.2 : 1,
						maxWidth: '50px',
					},
					p: 0,
				}}
			>
				{data &&
					data.map((actionResult, index) => {
						const { value, resultType, result } = actionResult;
						const valuesSection = getActionValueAsString(value, resultType as EResultType);

						return (
							<TimelineItem
								key={`${resultType}-${index}`}
								sx={{ flexDirection: matchesMd ? 'row' : 'column', my: 1 }}
							>
								<TimelineOppositeContent
									sx={{ pl: 0, pr: matchesMd ? 2 : 0, py: matchesMd ? 0 : 1, width: '100%' }}
								>
									<Box>
										{result.includes('SUCCESS') ?
											<CheckIcon sx={{ color: 'success.main' }} />
										:	<CloseIcon sx={{ color: 'error.main' }} />}
									</Box>
								</TimelineOppositeContent>
								<TimelineSeparator>
									<TimelineConnector
										sx={{
											width: matchesMd ? 4 : '100%',
											height: matchesMd ? '100%' : 4,
											bgcolor: getBgColor(result),
										}}
									/>
								</TimelineSeparator>
								<TimelineContent sx={{ bgcolor: '#fafafa' }}>
									<Stack spacing={1}>
										<Stack spacing={0}>
											<Typography color='black' fontWeight='bold'>
												{t(
													`page.action.detail.sections.validationResult.subtitle.${resultType}`,
												)}
											</Typography>
											{valuesSection && (
												<Typography color='grey'>
													{`${valuesSection.name}: `}
													<Box component='span' color='black' fontWeight='bold'>
														{valuesSection.value}
													</Box>
												</Typography>
											)}
											<Typography color='grey'>
												{`${t('page.action.detail.sections.validationResult.body.result')}: `}
												<Box component='span' color='black' fontWeight='bold'>
													{`${t(`page.action.detail.sections.validationResult.${getResultTranslationKey(result)}`)}`}
												</Box>
											</Typography>
										</Stack>
									</Stack>
								</TimelineContent>
							</TimelineItem>
						);
					})}
			</Timeline>
		</Box>
	);
};
