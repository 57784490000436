import * as React from 'react';

export const useKeyPress = (keys: string[], callback: () => void, excludeTags: string[] = []) => {
	const handleKeyDown = React.useCallback(
		(event: React.KeyboardEvent<HTMLInputElement>) => {
			const target = event.target as HTMLElement;
			if (excludeTags.length > 0 && excludeTags.includes(target.tagName)) {
				return;
			}
			if (keys.includes(event.key)) {
				event.preventDefault();
				callback();
			}
		},
		[callback, keys, excludeTags],
	);

	return handleKeyDown;
};
