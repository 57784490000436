import { TFunction } from 'i18next';
import { z } from 'zod';
import { validateGoogleApiKey } from '../../utils/GoogleMap';
import { getDurationInUnits } from '../../utils/DateTime';

export const smtpConfigurationSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	sender: z.string(),
	host: z.string(),
	port: z.number(),
	username: z.string(),
	createdAt: z.string(),
	active: z.boolean(),
	verificationStatus: z.string(),
});

/*
 * Validation schema for server settings
 */
export const getServerSettingsSchema = (t: TFunction) => {
	return z.object({
		hostname: z
			.string()
			.min(1, t('page.setupWizard.validationMessages.hostname.required'))
			.max(255, t('page.serverSettings.edit.form.hostname.maxLength', { maxLength: 255 })),
		tokenUrl: z
			.string()
			.max(1600, t('page.serverSettings.edit.form.tokenUrl.maxLength', { maxLength: 1600 }))
			.optional(),
		timezone: z.string().min(1, t('page.setupWizard.validationMessages.timezone.invalid')).optional(),
		k8sOAuthUrl: z
			.string()
			.max(2048, t('page.serverSettings.edit.form.k8sOAuthUrl.maxLength', { maxLength: 2048 }))
			.optional(),
		k8sOAuthUsername: z
			.string()
			.max(255, t('page.serverSettings.edit.form.k8sOAuthUsername.maxLength', { maxLength: 255 }))
			.optional(),
		k8sOAuthPassword: z
			.string()
			.max(255, t('page.serverSettings.edit.form.k8sOAuthPassword.maxLength', { maxLength: 255 }))
			.optional(),
		k8sAPIUrl: z
			.string()
			.max(2048, t('page.serverSettings.edit.form.k8sAPIUrl.maxLength', { maxLength: 2048 }))
			.optional(),
		k8sAPINamespace: z
			.string()
			.max(255, t('page.serverSettings.edit.form.k8sAPINamespace.maxLength', { maxLength: 255 }))
			.optional(),
	});
};

/*
 * Validation schema map settings
 */
export const getMapSettingsSchema = (t: TFunction) => {
	return z.object({
		enabledPlacesApi: z.boolean(),
		googleApiKey: z
			.string()
			.optional()
			.refine(
				async (googleApiKey) => {
					if (!googleApiKey) {
						return true;
					}
					const isValid = await validateGoogleApiKey(googleApiKey);

					return isValid;
				},
				{
					message: t('page.setupWizard.validationMessages.googleApiKey.invalid'),
				},
			),
	});
};

export const getExpirationTimesSchema = (t: TFunction) => {
	return z.object({
		qrVerificationEmailExpiry: z.string().refine(
			(value) => {
				const expirationValueInMinutes = getDurationInUnits(value, 'minute');

				return Number.isInteger(expirationValueInMinutes) && expirationValueInMinutes >= 1;
			},
			{
				message: t('page.expirationTimes.error', {
					field: t('page.expirationTimes.qrVerificationEmailExpiry.label'),
				}),
			},
		),
		smtpVerificationEmailExpiry: z.string().refine(
			(value) => {
				const expirationValueInMinutes = getDurationInUnits(value, 'minute');

				return Number.isInteger(expirationValueInMinutes) && expirationValueInMinutes >= 1;
			},
			{
				message: t('page.expirationTimes.error', {
					field: t('page.expirationTimes.smtpVerificationEmailExpiry.label'),
				}),
			},
		),
		invitationEmailExpiry: z.string().refine(
			(value) => {
				const expirationValueInMinutes = getDurationInUnits(value, 'minute');

				return Number.isInteger(expirationValueInMinutes) && expirationValueInMinutes >= 1;
			},
			{
				message: t('page.expirationTimes.error', {
					field: t('page.expirationTimes.invitationEmailExpiry.label'),
				}),
			},
		),
	});
};
