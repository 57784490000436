import { parse, format } from 'date-fns';
import { GeoType, RequestData } from './types';

export const formatTime = (time: string) => {
	const localTime = new Date(time);

	return format(localTime, 'HH:mm');
};

export const formatDateTime = (dateTime: string) => {
	const localTime = new Date(dateTime);

	return format(localTime, 'yyyy.MM.dd HH:mm');
};

export const getRequestData = (formValues: Record<any, any>) => {
	const requestData: RequestData = {
		name: formValues.name,
		description: formValues.description,
		timeRanges: [],
		days: [],
		factors: [],
		geofences: [],
		geofenceGroups: [],
		phoneConnectionStatuses: [],
		phoneConnectionIPs: [],
		ips: [],
		integrityCheck: false,
	};
	if (formValues.type === 'type2') {
		requestData.timeRange = {
			start: formatDateTime(formValues.timeRange.start),
			end: formatDateTime(formValues.timeRange.end),
		};
	}
	if (formValues.type === 'type3') {
		requestData.days = formValues.days;
	}
	for (const rule of formValues.rules) {
		if (rule.type === 'time') {
			for (const timeRange of rule.time.timeRanges) {
				const formatedTimes = {
					from: formatTime(timeRange.from),
					to: formatTime(timeRange.to),
				};
				requestData.timeRanges.push(formatedTimes);
			}
		}
		if (rule.type === 'factors') {
			for (const factor of rule.factors.factors) {
				requestData.factors.push(factor.key);
			}
		}
		if (rule.type === 'geofence') {
			for (const geo of rule.geofence.geofences) {
				if (geo.type === 'geofences') {
					requestData.geofences.push([...geo.items.map((item: GeoType) => item.id)]);
				}
				if (geo.type === 'geofenceGroups') {
					requestData.geofenceGroups.push([...geo.items.map((item: GeoType) => item.id)]);
				}
			}
		}
		if (rule.type === 'phone') {
			for (const phoneConnectionRule of rule.phoneConnections.rules) {
				if (phoneConnectionRule.type === 'status') {
					requestData.phoneConnectionStatuses.push(phoneConnectionRule.value.type);
				}
				if (phoneConnectionRule.type === 'ipRange') {
					requestData.phoneConnectionIPs.push(phoneConnectionRule.value);
				}
				if (phoneConnectionRule.type === 'ip') {
					requestData.ips.push(phoneConnectionRule.value.ip);
				}
				if (phoneConnectionRule.type === 'integrityCheck') {
					requestData.integrityCheck = true;
				}
			}
		}
	}

	return requestData;
};
