import parse, { Units } from 'parse-duration';
import { formatDuration, format, isValid, Duration } from 'date-fns';

/**
 * @note This function __doesn't__ support `nanosecond` and `microsecond` units unlike `parse-duration` lib.
 * @note Overflow is possible if you pass (1_000_000, 'year'), so please be careful.
 * @description This helper function is the opposite of `parse-duration` parse function and converts a number to a duration object.
 *
 * It uses linear months representation similar to `parse-duration` library.
 *
 * 1 year = 365.25 days
 *
 * 1 month = 1 year / 12 = 30.4375 days
 * @param {number} duration number that represents time duration
 * @param {Units} format time format units
 * @returns {Duration} duration object
 * @example convertNumberToDuration(3666, 'second') // { years: 0, months: 0, days: 0, hours: 1, minutes: 1, seconds: 1 }
 */
export const convertNumberToDuration = (duration: number, format: Units): Duration => {
	// Constants for time units in nanoseconds. BigInt is used to avoid overflow issues.
	const MILLISECOND = 1;
	const SECOND = 1000 * MILLISECOND;
	const MINUTE = 60 * SECOND;
	const HOUR = 60 * MINUTE;
	const DAY = 24 * HOUR;
	// Assumes all months have the same number of days (30.4375 days per month)
	const MONTH = ((365.25 * 24 * 60) / 12) * MINUTE;
	const YEAR = 365.25 * 24 * HOUR;

	// Convert duration to milliseconds - the smallest unit
	let durationMs = Math.abs(duration);
	if (format === 'millisecond' || format === 'ms') {
		durationMs = durationMs * MILLISECOND;
	} else if (format === 'second' || format === 'sec' || format === 's') {
		durationMs = durationMs * SECOND;
	} else if (format === 'minute' || format === 'min' || format === 'm') {
		durationMs = durationMs * MINUTE;
	} else if (format === 'hour' || format === 'hr' || format === 'h') {
		durationMs = durationMs * HOUR;
	} else if (format === 'day' || format === 'd') {
		durationMs = durationMs * DAY;
	} else if (format === 'month' || format === 'b') {
		durationMs = durationMs * MONTH;
	} else if (format === 'year' || format === 'yr' || format === 'y') {
		durationMs = durationMs * YEAR;
	} else {
		throw new Error('Invalid units');
	}

	// Convert milliseconds to years, months, days, hours, minutes, and seconds
	const years = Math.floor(durationMs / YEAR);
	durationMs -= years * YEAR;
	const months = Math.floor(durationMs / MONTH);
	durationMs -= months * MONTH;
	const days = Math.floor(durationMs / DAY);
	durationMs -= days * DAY;
	const hours = Math.floor(durationMs / HOUR);
	durationMs -= hours * HOUR;
	const minutes = Math.floor(durationMs / MINUTE);
	durationMs -= minutes * MINUTE;
	const seconds = Math.floor(durationMs / SECOND);

	return {
		years,
		months,
		days,
		hours,
		minutes,
		seconds,
	};
};

/**
 * @description Parse a string with duration to a number.
 *
 * It uses linear months representation similar to `parse-duration` library.
 *
 * 1 year = 365.25 days
 *
 * 1 month = 1 year / 12 = 30.4375 days
 * @example getDurationInUnits('2 minutes', 's') // 120
 * @example getDurationInUnits('1 hour', 's') // 3600
 * @example getDurationInUnits('1 hour', 'm') // 60
 */
export const getDurationInUnits = (time: string, format: Units): number => {
	try {
		const result = Math.round(parse(time, format) ?? 0);
		if (!result) {
			return 0;
		}

		return result >= 1 ? result : 0;
	} catch {
		return 0;
	}
};

/**
 * This function is opposite of `getDurationInUnits`.
 *
 * If you parse a string with duration using `getDurationInUnits` then you should use this function to format it back to string.
 *
 * This function always returns a string with possible largest integer units first, example - `3600 seconds` is `1 hour`, __not__ `60 minutes`.
 *
 * It uses linear months representation similar to `parse-duration` library.
 *
 * 1 year = 365.25 days
 *
 * 1 month = 1 year / 12 = 30.4375 days
 * @param {number} time number that represents time duration
 * @param {Units} format time format units
 * @returns {string} formatted duration. Example: '1 hour 2 minutes 3 seconds' for , ''
 * @example getDurationInString(3666, 'second') // '1 hour 1 minute 1 second'
 * @example getDurationInString(120, 'second') // '2 minutes'
 */
export const getDurationInString = (time: number, format: Units): string => {
	const duration = convertNumberToDuration(time, format);
	const { years, months, days, hours, minutes, seconds } = duration;

	return formatDuration({ years, months, days, hours, minutes, seconds });
};

export const formatDateTime = (date: Date | string | undefined, includeTime = false) => {
	if (!date) {
		return 'N/A';
	}

	const parsedDate = new Date(date);

	if (!isValid(parsedDate)) {
		return 'N/A';
	}

	const formatString = includeTime ? 'dd.MM.yyyy HH:mm' : 'dd.MM.yyyy';

	return format(parsedDate, formatString);
};

export const getDurationInMin = (time: string): number => {
	try {
		const result = Math.round(parse(time, 'minute') ?? 0);

		if (!result) {
			return 0;
		}

		return result >= 1 ? result : 0;
	} catch {
		return 0;
	}
};
