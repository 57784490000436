import { zodResolver } from '@hookform/resolvers/zod';
import {
	Box,
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogContentText,
	DialogTitle,
	Stack,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useForm } from 'react-hook-form';

import { PasswordField } from '../../components/FormFields/PasswordField/PasswordField';
import { ChangePasswordDialogProps } from './types';
import { getChangePasswordFormSchema } from './schema';
import { LoadingButton } from '@mui/lab';

export const ChangePasswordDialog: React.FC<ChangePasswordDialogProps> = ({ open, username, onClose, onConfirm }) => {
	const { t } = useTranslation();

	const {
		handleSubmit,
		register,
		formState: { errors, isSubmitting },
	} = useForm<{ password: string; passwordAgain: string }>({
		mode: 'onChange',
		defaultValues: { password: '', passwordAgain: '' },
		resolver: zodResolver(getChangePasswordFormSchema(t)),
	});

	return (
		<Dialog open={open} onClose={onClose}>
			<Box component='form' noValidate onSubmit={handleSubmit((formValues) => onConfirm(formValues.password))}>
				<DialogTitle>{t('page.pam.list.changePasswordDialog.title')}</DialogTitle>
				<DialogContent>
					<Stack spacing={2}>
						<DialogContentText>
							{t('page.pam.list.changePasswordDialog.description', { username })}
						</DialogContentText>
						<PasswordField
							helperText={t('page.pam.list.changePasswordDialog.password.helperText')}
							label={t('page.pam.list.changePasswordDialog.password.label')}
							name='password'
							register={register}
							error={errors.password}
						/>
						<PasswordField
							helperText={t('page.pam.list.changePasswordDialog.passwordAgain.helperText')}
							label={t('page.pam.list.changePasswordDialog.passwordAgain.label')}
							name='passwordAgain'
							register={register}
							error={errors.passwordAgain}
						/>
					</Stack>
				</DialogContent>
				<DialogActions>
					<Button disabled={isSubmitting} onClick={onClose} color='primary'>
						{t('page.pam.list.changePasswordDialog.cancel')}
					</Button>
					<LoadingButton color='primary' loading={isSubmitting} type='submit'>
						{t('page.pam.list.changePasswordDialog.confirm')}
					</LoadingButton>
				</DialogActions>
			</Box>
		</Dialog>
	);
};
