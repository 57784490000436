import * as React from 'react';
import { Box, Typography, Paper } from '@mui/material';
import { Remove as RemoveIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { RulesPreviewProps } from './types';
import { LabeledBox } from '../../../../components/LabeledBox/LabeledBox';

export const PhoneConnectionPreview: React.FC<RulesPreviewProps> = ({ data }): JSX.Element => {
	const { t } = useTranslation();

	return (
		<Paper
			elevation={3}
			sx={{
				display: 'flex',
				flexWrap: { xs: 'wrap', md: 'nowrap' },
				width: '100%',
				gap: 2,
				p: 2,
			}}
		>
			<LabeledBox label={t('page.securityPolicy.detail.rules.body.rule')} sx={{ flex: '0 0 180px' }}>
				<Typography>{t('page.securityPolicy.detail.rules.title.phoneConnection')}</Typography>
			</LabeledBox>
			<Box sx={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
				<Box
					sx={{
						position: 'relative',
						display: 'flex',
						justifyContent: 'space-between',
						flexDirection: 'column',
						flexWrap: { xs: 'wrap', md: 'nowrap' },
						gap: 2,
						mb: 2,
						maxWidth: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							flexWrap: { xs: 'wrap', md: 'nowrap' },
							alignItems: 'flex-start',
							gap: 2,
							width: '100%',
						}}
					>
						<LabeledBox
							label={t('page.securityPolicy.detail.rules.body.integrityCheck')}
							sx={{ width: { xs: '100%', md: '50%' }, height: 'auto' }}
						>
							<Typography px={1}>
								{data.integrityCheck ?
									t('page.securityPolicy.detail.rules.body.integrityCheckYes')
								:	t('page.securityPolicy.detail.rules.body.integrityCheckNo')}
							</Typography>
						</LabeledBox>
					</Box>
				</Box>
				{data.phoneConnectionStatuses.map((status, index) => (
					<Box
						key={`phone-connection-status-${index}`}
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'column',
							flexWrap: { xs: 'wrap', md: 'nowrap' },
							gap: 2,
							mb: 2,
							maxWidth: '100%',
						}}
					>
						{index > 0 && (
							<Box
								sx={{
									position: { xs: 'relative', md: 'absolute' },
									top: 0,
									bottom: 0,
									left: { xs: 0, md: '-50px' },
									display: 'flex',
									width: { xs: '100%', md: 'auto' },
								}}
							>
								<Typography sx={{ marginY: '17px' }}>{t('page.ruleSet.form.body.or')}</Typography>
							</Box>
						)}
						<Box
							sx={{
								display: 'flex',
								flexWrap: { xs: 'wrap', md: 'nowrap' },
								alignItems: 'flex-start',
								gap: 2,
								width: '100%',
							}}
						>
							<LabeledBox
								label={t('page.securityPolicy.detail.rules.body.connection')}
								sx={{ width: { xs: '100%', md: '50%' }, height: 'auto' }}
							>
								<Typography px={1}>{t('page.securityPolicy.detail.rules.body.status')}</Typography>
							</LabeledBox>
							<LabeledBox
								label={t('page.securityPolicy.detail.rules.body.type')}
								sx={{ width: { xs: '100%', md: '50%' }, height: 'auto' }}
							>
								<Typography px={1}>{status}</Typography>
							</LabeledBox>
						</Box>
					</Box>
				))}
				{data.ips.map((ip, index) => (
					<Box
						key={`phone-connection-ips-${index}`}
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'column',
							flexWrap: { xs: 'wrap', md: 'nowrap' },
							gap: 2,
							mb: 2,
							maxWidth: '100%',
						}}
					>
						{(index > 0 || data.phoneConnectionStatuses.length > 0) && (
							<Box
								sx={{
									position: { xs: 'relative', md: 'absolute' },
									top: 0,
									bottom: 0,
									left: { xs: 0, md: '-50px' },
									display: 'flex',
									width: { xs: '100%', md: 'auto' },
								}}
							>
								<Typography sx={{ marginY: '17px' }}>{t('page.ruleSet.form.body.or')}</Typography>
							</Box>
						)}
						<Box
							sx={{
								display: 'flex',
								flexWrap: { xs: 'wrap', md: 'nowrap' },
								alignItems: 'flex-start',
								gap: 2,
								width: '100%',
							}}
						>
							<LabeledBox
								label={t('page.securityPolicy.detail.rules.body.connection')}
								sx={{ width: { xs: '100%', md: '50%' }, height: 'auto' }}
							>
								<Typography px={1}>{t('page.securityPolicy.detail.rules.body.ipAddress')}</Typography>
							</LabeledBox>
							<LabeledBox
								label={t('page.securityPolicy.detail.rules.body.ipAddress')}
								sx={{ width: { xs: '100%', md: '50%' }, height: 'auto' }}
							>
								<Typography px={1}>{ip}</Typography>
							</LabeledBox>
						</Box>
					</Box>
				))}
				{data.phoneConnectionIPs.map((range, index) => (
					<Box
						key={`phone-connection-range-${index}`}
						sx={{
							position: 'relative',
							display: 'flex',
							justifyContent: 'space-between',
							flexDirection: 'column',
							flexWrap: { xs: 'wrap', md: 'nowrap' },
							gap: 2,
							mb: 2,
							maxWidth: '100%',
						}}
					>
						{(index > 0 || data.phoneConnectionStatuses.length > 0 || data.ips.length > 0) && (
							<Box
								sx={{
									position: { xs: 'relative', md: 'absolute' },
									top: 0,
									bottom: 0,
									left: { xs: 0, md: '-50px' },
									display: 'flex',
									width: { xs: '100%', md: 'auto' },
								}}
							>
								<Typography sx={{ marginY: '17px' }}>{t('page.ruleSet.form.body.or')}</Typography>
							</Box>
						)}
						<Box
							sx={{
								display: 'flex',
								flexWrap: { xs: 'wrap', md: 'nowrap' },
								alignItems: 'flex-start',
								gap: 2,
								width: '100%',
							}}
						>
							<LabeledBox
								label={t('page.securityPolicy.detail.rules.body.connection')}
								sx={{ width: { xs: '100%', md: '50%' }, height: 'auto' }}
							>
								<Typography px={1}>{'IP Ranges'}</Typography>
							</LabeledBox>
							<Box
								sx={{
									display: 'flex',
									flexFlow: 'row wrap',
									alignItems: 'center',
									gap: 2,
									width: { xs: '100%', md: '50%' },
								}}
							>
								<LabeledBox
									label={t('page.securityPolicy.detail.rules.body.from')}
									sx={{ flex: '1 1 180px', width: '50%' }}
								>
									<Typography px={1}>{range.from}</Typography>
								</LabeledBox>
								<RemoveIcon />
								<LabeledBox
									label={t('page.securityPolicy.detail.rules.body.to')}
									sx={{ flex: '1 1 180px', width: '50%' }}
								>
									<Typography px={1}>{range.to}</Typography>
								</LabeledBox>
							</Box>
						</Box>
					</Box>
				))}
			</Box>
		</Paper>
	);
};
