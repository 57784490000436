import { Suspense } from 'react';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { BrowserRouter } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@mui/material';

import { AuthProvider } from './contexts/AuthContext/AuthContext';
import { SwaggerApiProvider } from './contexts/SwaggerApiContext/SwaggerApiContext';
import { LocalizationProvider } from './contexts/LocalizationContext/LocalizationContext';
import { GeofenceContextProvider } from './contexts/GeofenceContext/GeofenceContext';
import { SessionProvider } from './contexts/SessionContext/SessionContext';

import { Router } from './services/Router/Router';
import { NetworkContextProvider } from './contexts/NetworkContext/NetworkContext';
import { theme } from './theme';
import { UnsavedChangesModalContextProvider } from './contexts/UnsavedChangesModalContext/UnsavedChangesModalContext';

const queryClient = new QueryClient();

export const App = (): JSX.Element => {
	return (
		<Suspense fallback={<></>}>
			<QueryClientProvider client={queryClient}>
				<ThemeProvider theme={theme}>
					<ProSidebarProvider>
						<BrowserRouter>
							<LocalizationProvider>
								<NetworkContextProvider>
									<SwaggerApiProvider>
										<AuthProvider>
											<SessionProvider>
												<GeofenceContextProvider>
													<UnsavedChangesModalContextProvider>
														<Router />
													</UnsavedChangesModalContextProvider>
												</GeofenceContextProvider>
											</SessionProvider>
										</AuthProvider>
									</SwaggerApiProvider>
								</NetworkContextProvider>
							</LocalizationProvider>
						</BrowserRouter>
					</ProSidebarProvider>
				</ThemeProvider>
			</QueryClientProvider>
		</Suspense>
	);
};
