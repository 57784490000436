import { TFunction } from 'i18next';

import { CreateNetworkPolicyDto } from '../../api/Api';

export const validateNetworkAddress = (networkAddress: string, t: TFunction) => {
	if (networkAddress === '') {
		return t('component.networkAddressTable.errorMessage.emptyNetworkAddress');
	}

	return;
};

export const areNetworkAddressArraysEqual = (
	array1: CreateNetworkPolicyDto['networkAddresses'],
	array2: CreateNetworkPolicyDto['networkAddresses'],
) => {
	if (array1.length !== array2.length) {
		return false;
	}

	return array1.every((element, index) => element === array2[index]);
};
