import * as React from 'react';
import { Box, Avatar, IconButton, Tooltip, Paper, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	MRT_ColumnDef,
	MRT_ToggleGlobalFilterButton,
	MRT_ToggleFiltersButton,
	MRT_ShowHideColumnsButton,
	MRT_ToggleDensePaddingButton,
	MRT_ToggleFullScreenButton,
	MaterialReactTable,
	MRT_TableInstance,
} from 'material-react-table';
import {
	ForwardToInboxOutlined as ForwardToInboxOutlinedIcon,
	FolderShared,
	GroupAdd as GroupAddIcon,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { useLocation } from 'react-router-dom';
import { useQuery, keepPreviousData } from '@tanstack/react-query';

import { useNavigate } from '../../hooks/useNavigate';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { RequestParams, UserCachedFromIdentityStore } from '../../api/Api';
import { IdentityStoreListState, IdentityStoreSelection } from './types';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { identityStoreUserListSchema } from './schema';
import { useACL } from '../../hooks/useACL';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { usePreviousValue } from '../../hooks/usePreviousValue';
import { useTableQuery } from '../../hooks/useTableQuery';
import { TableSelect } from '../../components/TableSelect/TableSelect';
import { useTenantBasePath } from '../../hooks/useTenantBasePath';
import { FloatingButtonBack } from '../../components/Buttons/FloatingButton/FloatingButtonBack';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useDeviceDetect } from '../../hooks/useDeviceDetect';
import InviteUserDialog from '../../components/InviteUserDialog/InviteUserDialog';
import { UserGroupsIDs } from '../../components/InviteUserDialog/types';
import { useCreateInvitations } from '../../hooks/query/users/useCreateInvitations';
import { useFetchUserGroupsOptionsPaginated } from '../../hooks/query/userGroups/useFetchUserGroupsPaginated';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { EPermission } from '../../enums/permission/EPermission';

export const AddInvitationsPage: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const device = useDeviceDetect();
	const authContext = useAuthContext();
	const previousActiveTenantId = usePreviousValue(authContext.userTenants?.activeTenantID);
	const { t } = useTranslation();
	const location = useLocation();
	const { isAllowed } = useACL();
	const navigate = useNavigate();
	const basePath = useTenantBasePath(location.state?.tenantIdOrSlug);
	const reactQueryClient = useReactQueryClient();
	const { MRTLocalization } = useMRTLocalization();

	const [uuIDToInvite, setUuIDToInvite] = React.useState<string | null>(null);
	const [confirmationText, setConfirmationText] = React.useState('');
	const {
		rowSelection,
		setRowSelection,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['userFullName', 'email']);
	const [uuidsToInvite, setUuidsToInvite] = React.useState<string[]>([]);
	const [open, setOpen] = React.useState(false);
	const [selectedIdentityStoreID, setSelectedIdentityStoreID] = React.useState<number | undefined>(undefined);
	const [identityStoreListState, setIdentityStoreListState] = React.useState<IdentityStoreListState>({
		loading: false,
		loaded: false,
		data: null,
	});

	const pageTitle = React.useMemo(() => {
		const tenantName = location.state?.tenantName ? location.state?.tenantName : '';

		return tenantName ? t('page.addInvitations.title.tenant', { tenantName }) : t('page.addInvitations.title.main');
	}, [location.state?.tenantName]);

	const handleOnLoadIdentityStores = React.useCallback(async () => {
		if (identityStoreListState.loading) {
			return;
		}
		setIdentityStoreListState({
			...identityStoreListState,
			loading: true,
		});
		try {
			const params: RequestParams | undefined = basePath ? { baseURL: basePath } : undefined;
			const identityStoreListResponse = await api.identityStores.getAllIdentityStores({}, params);
			setIdentityStoreListState({
				loading: false,
				loaded: true,
				data: identityStoreListResponse.data,
			});
			const headIdentityStoreId =
				identityStoreListResponse.data?.entities[0] && identityStoreListResponse.data?.entities[0].id ?
					identityStoreListResponse.data?.entities[0].id
				:	undefined;
			setSelectedIdentityStoreID(headIdentityStoreId);
		} catch (error) {
			console.error(error);
			setIdentityStoreListState({
				loading: false,
				loaded: false,
				data: null,
			});
		}
	}, [identityStoreListState, basePath]);

	React.useEffect(() => {
		if (authContext.userTenants?.activeTenantID !== previousActiveTenantId) {
			handleOnLoadIdentityStores();
		}
	}, [
		handleOnLoadIdentityStores,
		authContext.userTenants?.activeTenantID,
		previousActiveTenantId,
		identityStoreListState,
	]);

	const { data, isRefetching, isLoading, error } = useQuery<{
		entities: UserCachedFromIdentityStore[];
		total: number;
	}>({
		queryKey: [EQueryKey.IDENTITY_STORE_USER_LIST_QUERY, selectedIdentityStoreID, swaggerQuery, basePath],
		queryFn: async () => {
			try {
				if (!selectedIdentityStoreID) {
					return { entities: [], total: 0 };
				}

				const params: RequestParams | undefined = basePath ? { baseURL: basePath } : undefined;

				const response = await api.identityStores.getUsers(
					selectedIdentityStoreID as number,
					{
						limit: swaggerQuery.limit,
						offset: swaggerQuery.offset,
						filter: swaggerQuery.filter,
						sort: swaggerQuery.sort,
						columns: swaggerQuery.columns,
					},
					params,
				);
				response.data.entities.forEach((identityStoreUser) => {
					identityStoreUserListSchema.parse(identityStoreUser);
				});

				return {
					entities: response.data.entities,
					total: response.data.total,
				};
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data ? data : {};

	const updateRowSelection = React.useCallback(
		(deletedIds: string[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const closeModal = () => setOpen(false);
	const openModal = () => setOpen(true);

	const handleOnOpenMultipleUsersInvite = React.useCallback(
		(table: MRT_TableInstance<UserCachedFromIdentityStore>) => () => {
			const selectedRowsOnActivePageIds = table
				.getSelectedRowModel()
				.rows.map((row) => row.original.uuid as string);

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.addInvitations.errorMessages.noUsersSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setUuidsToInvite(selectedRowsOnActivePageIds);
			}

			openModal();
		},
		[enqueueSnackbar],
	);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, [isAllowed]);

	const { data: userGroupsOptions = [] } = useFetchUserGroupsOptionsPaginated(
		basePath ? Number(basePath) : undefined,
	);

	const { mutate } = useCreateInvitations();

	const createInvitations = (userGroupsIDs: UserGroupsIDs) => {
		if (selectedIdentityStoreID === undefined) {
			return;
		}

		const params: RequestParams | undefined = basePath ? { baseURL: basePath } : undefined;

		mutate(
			{
				params,
				data: {
					userGroupsIDs,
					identityStoreID: selectedIdentityStoreID,
					uuids: uuidsToInvite,
				},
			},
			{
				onError: (error) => {
					// eslint-disable-next-line no-console
					console.error(error);
				},
				onSuccess: (invitations) => {
					if (invitations.length > 1) {
						enqueueSnackbar(t('page.addInvitations.actionMessages.userInvitationsSuccess'), {
							variant: 'success',
							persist: false,
						});
					} else {
						enqueueSnackbar(t('page.addInvitations.actionMessages.userInvitationSuccess'), {
							variant: 'success',
							persist: false,
						});
					}

					setUuidsToInvite([]);
					updateRowSelection(uuidsToInvite);
				},
			},
		);
	};

	const columns = React.useMemo<MRT_ColumnDef<UserCachedFromIdentityStore>[]>(
		() => [
			{
				accessorFn: (user) => `${user.name} ${user.surname ? user.surname : ''}`,
				accessorKey: 'userFullName',
				header: t('page.addInvitations.table.header.name'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={`${renderedCellValue}`} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorKey: 'email',
				header: t('page.addInvitations.table.header.email'),
			},
		],
		[],
	);

	const selectedOption: IdentityStoreSelection = React.useMemo(() => {
		if (
			identityStoreListState.data &&
			identityStoreListState.data.entities.length > 0 &&
			!selectedIdentityStoreID
		) {
			return {
				id: identityStoreListState.data.entities[0].id as number,
				label: identityStoreListState.data.entities[0].name as string,
			};
		} else if (
			selectedIdentityStoreID &&
			identityStoreListState.data &&
			identityStoreListState.data.entities.length > 0
		) {
			return {
				id: selectedIdentityStoreID,
				label: identityStoreListState.data.entities.find((entity) => entity.id === selectedIdentityStoreID)
					?.name as string,
			};
		}

		return {
			id: 0,
			label: '',
		};
	}, [identityStoreListState, selectedIdentityStoreID]);

	const options: IdentityStoreSelection[] = React.useMemo(() => {
		if (!identityStoreListState.data) {
			return [];
		}
		const optns = identityStoreListState.data.entities.map((identityStoreEntity) => {
			const option: IdentityStoreSelection = {
				label: identityStoreEntity.name ? identityStoreEntity.name : '',
				id: identityStoreEntity.id ? identityStoreEntity.id : 0,
			};

			return option;
		});

		return optns;
	}, [identityStoreListState]);

	const handleOnClickBack = React.useCallback(() => {
		navigate(-1);
	}, [navigate]);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={pageTitle}
						description={t('page.addInvitations.description')}
						icon={GroupAddIcon}
					/>
					<MaterialReactTable
						columns={columns}
						data={entities}
						rowCount={total}
						state={{
							isLoading: isLoading,
							pagination,
							showProgressBars: isRefetching,
							showAlertBanner: error !== null,
							rowSelection,
							globalFilter,
							columnFilters,
							sorting,
							columnVisibility,
						}}
						manualPagination
						manualFiltering
						enableRowActions
						enableRowSelection
						manualSorting
						onSortingChange={setSorting}
						onColumnFiltersChange={setColumnFilters}
						onRowSelectionChange={setRowSelection}
						getRowId={(originalRow) => originalRow.uuid?.toString() || ''}
						enableStickyHeader={false}
						onColumnVisibilityChange={setColumnVisibility}
						onPaginationChange={setPagination}
						onGlobalFilterChange={setGlobalFilter}
						initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
						muiTopToolbarProps={{
							sx: { overflow: 'visible' },
						}}
						renderRowActions={({ row, table }) => (
							<Box
								sx={{
									display: 'flex',
									alignItems: 'center',
									gap: '1rem',
								}}
							>
								<Tooltip
									title={t('page.addInvitations.tooltips.invite')}
									placement='right'
									enterDelay={500}
									arrow
								>
									<span>
										<IconButton
											disabled={table.getSelectedRowModel().rows.length > 0}
											onClick={() => {
												setUuidsToInvite([row.original.uuid]);
												openModal();
											}}
										>
											<ForwardToInboxOutlinedIcon />
										</IconButton>
									</span>
								</Tooltip>
							</Box>
						)}
						renderToolbarInternalActions={({ table }) => (
							<Box sx={{ display: 'flex', alignItems: 'center', zIndex: 99900 }}>
								<MRT_ToggleGlobalFilterButton table={table} />
								<MRT_ToggleFiltersButton table={table} />
								<MRT_ShowHideColumnsButton table={table} />
								<MRT_ToggleDensePaddingButton table={table} />
								<MRT_ToggleFullScreenButton table={table} />
								{isAllowed([EPermission.INVITATIONS_CREATE]) && (
									<Tooltip title={t('page.addInvitations.tooltips.inviteSelected')} enterDelay={500}>
										<span>
											<IconButton
												color='primary'
												disabled={table.getSelectedRowModel().rows.length === 0}
												onClick={handleOnOpenMultipleUsersInvite(table)}
											>
												<ForwardToInboxOutlinedIcon />
											</IconButton>
										</span>
									</Tooltip>
								)}
								<Tooltip
									title={t('page.addInvitations.tooltips.tableSelect')}
									placement='top'
									enterDelay={1000}
								>
									<span>
										<TableSelect
											defaultValue={selectedOption}
											onChange={(
												_: React.SyntheticEvent<Element, Event>,
												value: IdentityStoreSelection,
											) => {
												setSelectedIdentityStoreID(value.id ? value.id : undefined);
											}}
											options={options}
											value={selectedOption}
											identifier='identity-store-selector'
											icon={FolderShared}
											alwaysVisible={device === 'desktop'}
										/>
									</span>
								</Tooltip>
							</Box>
						)}
						displayColumnDefOptions={{
							'mrt-row-actions': {
								header: t('page.addInvitations.table.header.actions'),
							},
							'mrt-row-select': {
								enableHiding: true,
								visibleInShowHideMenu: false,
							},
						}}
						muiTablePaperProps={({ table }) => ({
							style: {
								overflow: 'visible',
								zIndex: table.getState().isFullScreen ? 1250 : undefined,
								boxShadow: 'none',
								outline: '1px solid #e0e0e0',
							},
						})}
						muiSelectCheckboxProps={() => ({
							sx: {
								width: '50px',
								height: '50px',
							},
						})}
						muiSelectAllCheckboxProps={() => ({
							sx: {
								width: '50px',
								height: '50px',
							},
						})}
						muiTableHeadCellProps={() => ({
							sx: {
								verticalAlign: 'baseline',
							},
						})}
						localization={MRTLocalization}
						positionActionsColumn='last'
					/>
				</Stack>

				<FloatingButtonBack
					onClick={handleOnClickBack}
					ariaLabel={t('page.addInvitations.ariaLabel.back')}
					tooltipTitle={t('page.addInvitations.tooltips.back')}
				/>

				{isAllowed([EPermission.INVITATIONS_CREATE, EPermission.INVITATIONS_UPDATE], false) && (
					<InviteUserDialog
						open={open}
						confirmText={t('page.addInvitations.modalTexts.confirmInvite')}
						text={
							uuidsToInvite.length > 1 ?
								t('page.addInvitations.modalTexts.inviteMultipleText')
							:	t('page.addInvitations.modalTexts.inviteSingleText')
						}
						title={
							uuidsToInvite.length > 1 ?
								t('page.addInvitations.modalTexts.inviteMultipleTitle')
							:	t('page.addInvitations.modalTexts.inviteSingleTitle')
						}
						onClose={closeModal}
						onConfirm={createInvitations}
					/>
				)}
			</Paper>
		</Box>
	);
};
