export enum EPermission {
	ALL = 'all',
	// Users
	USERS_READ_ALL = 'users_read_all',
	USERS_READ_SUBORDINATES = 'users_read_subordinates',
	USERS_DELETE = 'users_delete',
	// Invitations
	INVITATIONS_READ = 'invitations_read',
	INVITATIONS_CREATE = 'invitations_create',
	INVITATIONS_UPDATE = 'invitations_update',
	INVITATIONS_DELETE = 'invitations_delete',
	// User groups
	USER_GROUPS_READ = 'user_groups_read',
	USER_GROUPS_READ_OWN = 'user_groups_read_own',
	USER_GROUPS_CREATE = 'user_groups_create',
	USER_GROUPS_UPDATE = 'user_groups_update',
	USER_GROUPS_DELETE = 'user_groups_delete',
	// PAM
	PAM_TARGETS_CREATE = 'pam_targets_create',
	PAM_TARGETS_READ_ALL = 'pam_targets_read_all',
	PAM_TARGETS_READ_OWN = 'pam_targets_read_own',
	PAM_TARGETS_UPDATE = 'pam_targets_update',
	PAM_TARGETS_DELETE = 'pam_targets_delete',
	PAM_SESSIONS_CREATE = 'pam_sessions_create',
	PAM_SESSIONS_READ_ALL = 'pam_sessions_read_all',
	PAM_SESSIONS_READ_OWN = 'pam_sessions_read_own',
	PAM_RECORDINGS_READ = 'pam_recordings_read',
	PAM_GROUPS_CREATE = 'pam_groups_create',
	PAM_GROUPS_READ = 'pam_groups_read',
	PAM_GROUPS_UPDATE = 'pam_groups_update',
	PAM_GROUPS_DELETE = 'pam_groups_delete',
	// Identity stores
	IDENTITY_STORES_READ = 'identity_stores_read',
	IDENTITY_STORES_CREATE = 'identity_stores_create',
	IDENTITY_STORES_UPDATE = 'identity_stores_update',
	IDENTITY_STORES_DELETE = 'identity_stores_delete',
	// Tenants
	TENANTS_READ = 'tenants_read',
	TENANTS_CREATE = 'tenants_create',
	TENANTS_UPDATE = 'tenants_update',
	TENANTS_DELETE = 'tenants_delete',
	// Geofences
	GEOFENCES_READ = 'geofences_read',
	GEOFENCES_UPDATE = 'geofences_update',
	GEOFENCES_CREATE = 'geofences_create',
	GEOFENCES_DELETE = 'geofences_delete',
	// OAuth clients
	OAUTH_CLIENTS_CREATE = 'oauth_clients_create',
	OAUTH_CLIENTS_READ = 'oauth_clients_read',
	OAUTH_CLIENTS_UPDATE = 'oauth_clients_update',
	OAUTH_CLIENTS_DELETE = 'oauth_clients_delete',
	// Roles
	ROLES_CREATE = 'roles_create',
	ROLES_READ = 'roles_read',
	ROLES_UPDATE = 'roles_update',
	ROLES_DELETE = 'roles_delete',
	// Security policies
	SECURITY_POLICIES_READ = 'security_policies_read',
	SECURITY_POLICIES_CREATE = 'security_policies_create',
	SECURITY_POLICIES_UPDATE = 'security_policies_update',
	SECURITY_POLICIES_DELETE = 'security_policies_delete',
	// SMTP
	SMTP_READ = 'smtp_read',
	SMTP_CREATE = 'smtp_create',
	SMTP_UPDATE = 'smtp_update',
	SMTP_DELETE = 'smtp_delete',
	// Geofence groups
	GEOFENCE_GROUPS_READ = 'geofence_groups_read',
	GEOFENCE_GROUPS_UPDATE = 'geofence_groups_update',
	GEOFENCE_GROUPS_CREATE = 'geofence_groups_create',
	GEOFENCE_GROUPS_DELETE = 'geofence_groups_delete',
	// Settings
	SETTINGS_MAP_READ = 'settings_map_read',
	SETTINGS_MAP_UPDATE = 'settings_map_update',
	SETTINGS_GENERAL_READ = 'settings_general_read',
	SETTINGS_GENERAL_UPDATE = 'settings_general_update',
	// Tokens
	TOKENS_READ_ALL = 'tokens_read_all',
	TOKENS_READ_OWN = 'tokens_read_own',
	TOKENS_DELETE_ALL = 'tokens_delete_all',
	TOKENS_DELETE_OWN = 'tokens_delete_own',
	TOKENS_AUDIT_LOGS_READ_ALL = 'tokens_audit_logs_read_all',
	TOKENS_AUDIT_LOGS_READ_OWN = 'tokens_audit_logs_read_own',
	// Actions
	ACTIONS_READ_ALL = 'actions_read_all',
	ACTIONS_READ_OWN = 'actions_read_own',
	// Network policies
	NETWORK_POLICY_READ_ALL = 'network_policy_read_all',
	NETWORK_POLICY_DELETE_ALL = 'network_policy_delete_all',
	NETWORK_POLICY_CREATE = 'network_policy_create',
	NETWORK_POLICY_UPDATE = 'network_policy_update',
}
