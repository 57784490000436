import * as React from 'react';
import { useFieldArray, Controller } from 'react-hook-form';
import { Box, Button, MenuItem, Select, FormLabel, SelectChangeEvent, Paper, Tooltip } from '@mui/material';
import { Add as AddIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

import { TimeRule } from '../TimeRule/TimeRule';
import { FactorRule } from '../FactorRule/FactorRule';
import { GeofenceRule } from '../GeofenceRule/GeofenceRule';
import { PhoneConnectionRule } from '../PhoneConnectionRule/PhoneConnectionRule';

import { RuleOption, RuleTypeState, RuleProps } from './types';

export const Rules: React.FC<RuleProps> = (props): JSX.Element => {
	const { ruleSet, control, watch } = props;
	const { t } = useTranslation();
	const [removedRules, setRemovedRules] = React.useState({
		factors: false,
		geofence: false,
		time: false,
		phone: false,
	});
	const { fields, append, remove } = useFieldArray({
		control,
		name: 'rules',
	});
	const initialized = React.useRef(false);
	const ruleOptions: RuleOption[] = React.useMemo(() => {
		return [
			{ value: 'time', label: t('page.ruleSet.form.fields.rules.time.label') },
			{ value: 'factors', label: t('page.ruleSet.form.fields.rules.factors.label') },
			{ value: 'geofence', label: t('page.ruleSet.form.fields.rules.geofence.label') },
			{ value: 'phone', label: t('page.ruleSet.form.fields.rules.phoneConnection.label') },
		];
	}, [t]);

	const [ruleTypes, setRuleTypes] = React.useState<RuleTypeState>({});

	const handleRuleTypeChange = React.useCallback(
		(index: number, value: string) => {
			setRuleTypes({ ...ruleTypes, [index]: value });
		},
		[ruleTypes],
	);

	const hasRuleType = (type: string) => {
		return Object.values(ruleTypes).includes(type);
	};

	React.useEffect(() => {
		if (!initialized.current) {
			let newRuleTypes = { ...ruleTypes };
			let updated = false;

			if (!hasRuleType('factors') && ruleSet && ruleSet?.factors?.length > 0) {
				newRuleTypes = { ...newRuleTypes, [Object.keys(newRuleTypes).length]: 'factors' };
				append({ type: 'factors', factors: ruleSet.factors });
				updated = true;
			}
			if (
				!hasRuleType('geofence') &&
				ruleSet &&
				(ruleSet?.geofenceGroups?.length > 0 || ruleSet?.geofences?.length > 0)
			) {
				newRuleTypes = { ...newRuleTypes, [Object.keys(newRuleTypes).length]: 'geofence' };
				append({ type: 'geofence', geofence: ruleSet.geofenceGroups || ruleSet.geofences });
				updated = true;
			}
			if (!hasRuleType('time') && ruleSet && ruleSet?.timeRanges?.length > 0) {
				newRuleTypes = { ...newRuleTypes, [Object.keys(newRuleTypes).length]: 'time' };
				append({ type: 'time', time: ruleSet.timeRanges });
				updated = true;
			}
			if (
				!hasRuleType('phone') &&
				ruleSet &&
				(ruleSet?.phoneConnectionIPs?.length > 0 ||
					ruleSet?.phoneConnectionStatuses?.length > 0 ||
					ruleSet?.ips?.length > 0 ||
					ruleSet?.integrityCheck)
			) {
				newRuleTypes = { ...newRuleTypes, [Object.keys(newRuleTypes).length]: 'phone' };
				append({
					type: 'phone',
					phone: ruleSet?.phoneConnectionIPs || ruleSet?.phoneConnectionStatuses?.length > 0,
				});
				updated = true;
			}

			if (updated) {
				setRuleTypes(newRuleTypes);
				initialized.current = true;
			}
		}
	}, [ruleSet, ruleTypes, append, fields.length]);

	const getFilteredRuleOptions = React.useCallback(
		(currentIndex: number) => {
			const selectedTypes = Object.values(ruleTypes);

			return ruleOptions.filter(
				(option) => !selectedTypes.includes(option.value) || option.value === ruleTypes[currentIndex],
			);
		},
		[ruleTypes, ruleOptions],
	);

	const removeRuleRow = React.useCallback(
		(index: number) => () => {
			remove(index);
			setRemovedRules({ ...removedRules, [ruleTypes[index]]: true });
			const newRuleTypes = Object.entries(ruleTypes)
				.filter(([key]) => Number(key) !== index)
				.reduce((acc, [key, value], i) => {
					acc[i] = value;

					return acc;
				}, {} as RuleTypeState);
			setRuleTypes(newRuleTypes);
		},
		[remove, ruleTypes, removedRules],
	);

	const addRuleRow = React.useCallback(() => {
		append({ type: '', timeRanges: [{ from: null, to: null }], factors: [] });
	}, [append]);

	return (
		<Box sx={{ display: 'flex', flexFlow: 'column', gap: 2, width: '100%' }}>
			<FormLabel
				sx={{
					color: 'text.primary',
				}}
			>
				{t('page.ruleSet.form.body.rules')}
			</FormLabel>
			{fields.map((field, index) => (
				<Paper
					elevation={3}
					key={field.id}
					sx={{
						display: 'flex',
						justifyContent: 'flex-start',
						alignItems: 'flex-start',
						flexWrap: 'wrap',
						gap: 2,
						mb: 2,
						p: 2,
						width: '100%',
					}}
				>
					<Box
						sx={{
							display: 'flex',
							gap: 2,
							flex: ruleTypes[index] ? '1 1 940px' : '1 1 auto',
							flexWrap: { xs: 'wrap', md: 'nowrap' },
						}}
					>
						<Box
							sx={{
								flex: '0 0 180px',
							}}
						>
							<Controller
								control={control}
								name={`rules.${index}.type`}
								render={({ field }) => (
									<Select
										{...field}
										value={ruleTypes[index] || ''}
										onChange={(event: SelectChangeEvent<string>) => {
											field.onChange(event);
											handleRuleTypeChange(index, event.target.value);
										}}
										displayEmpty
										sx={{ width: '100%' }}
									>
										<MenuItem value='' disabled>
											{t('page.ruleSet.form.fields.rules.rule.options.select')}
										</MenuItem>
										{getFilteredRuleOptions(index).map((option: RuleOption) => (
											<MenuItem key={option.value} value={option.value}>
												{option.label}
											</MenuItem>
										))}
									</Select>
								)}
							/>
						</Box>
						<Box sx={{ flex: '2 1 auto' }}>
							{ruleTypes[index] === 'time' && (
								<TimeRule
									control={control}
									name={`rules.${index}.time`}
									initialTimeRanges={removedRules.time ? null : ruleSet?.timeRanges}
								/>
							)}
							{ruleTypes[index] === 'factors' && (
								<FactorRule
									control={control}
									name={`rules.${index}.factors`}
									label={t('page.ruleSet.form.fields.rules.factors.factor.label')}
									intialFactors={removedRules.factors ? null : ruleSet?.factors}
								/>
							)}
							{ruleTypes[index] === 'geofence' && (
								<GeofenceRule
									control={control}
									label={t('page.ruleSet.form.fields.rules.geofence.typeSelect.label')}
									name={`rules.${index}.geofence`}
									initialRuleSet={removedRules.geofence ? null : ruleSet}
								/>
							)}
							{ruleTypes[index] === 'phone' && (
								<PhoneConnectionRule
									control={control}
									watch={watch}
									label={t('page.ruleSet.form.fields.rules.geofence.typeSelect.label')}
									name={`rules.${index}.phoneConnections`}
									initialRuleSet={removedRules.phone ? null : ruleSet}
								/>
							)}
						</Box>
					</Box>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							flexDirection: 'column',
							height: 'auto',
						}}
					>
						<Tooltip
							title={t('page.ruleSet.form.tooltips.removeRule')}
							placement='top'
							enterDelay={500}
							arrow
						>
							<Button
								sx={{
									width: '180px',
									height: '55px',
								}}
								color='error'
								variant='outlined'
								onClick={removeRuleRow(index)}
							>
								{t('page.ruleSet.form.body.removeRule')}
							</Button>
						</Tooltip>
					</Box>
				</Paper>
			))}
			{fields.length < ruleOptions.length && (
				<Tooltip title={t('page.ruleSet.form.tooltips.addRule')} placement='right' enterDelay={500} arrow>
					<Button
						sx={{
							width: '180px',
							color: 'green',
							height: '55px',
						}}
						onClick={addRuleRow}
						startIcon={<AddIcon />}
					>
						{t('page.ruleSet.form.body.addRule')}
					</Button>
				</Tooltip>
			)}
		</Box>
	);
};
