export enum EActionStatus {
	ONGOING = 'ongoing',
	COMPLETED = 'completed',
	FAILED = 'failed',
	TIMEOUTED = 'timeouted',
	CANCELLED = 'cancelled',
}

export enum EActionDetailSections {
	GENERAL = 'GENERAL',
	LOCATION = 'LOCATION',
	VALIDATION_RESULT = 'VALIDATION_RESULT',
}

export enum EResultType {
	'FACTORS' = 'factors',
	'TIME_VALIDATION' = 'timeValidation',
	'DATE_VALIDATION' = 'dateValidation',
	'DAY_VALIDATION' = 'dayValidation',
	'LOCATION_VALIDATION' = 'locationValidation',
	'PHONE_IP_VALIDATION' = 'phoneIPValidation',
	'PHONE_CONNECTION_STATUS_VALIDATION' = 'phoneConnectionStatusValidation',
	'TIME_RANGES' = 'timeRanges',
	'DATE_TIME_TANGE' = 'dateTimeRange',
	'DAYS' = 'days',
	'PHONE_IP_AND_RANGE' = 'phoneIPAndRange',
	'PHONE_CONNECTION_STATUSE' = 'phoneConnectionStatuse',
	'LOCATION' = 'location',
	'SIGNATURE' = 'signature',
	'INTEGRITY_CHECK' = 'integrityCheck',
}
