import { TFunction } from 'i18next';
import { z } from 'zod';

export const getSecurityPolicySchema = (t: TFunction) =>
	z.object({
		name: z
			.string()
			.min(3, { message: t('page.securityPolicy.edit.form.name.Error', { minLength: 3 }) })
			.max(64, { message: t('page.securityPolicy.edit.form.name.ErrorMaxLength', { maxLength: 64 }) }),
		description: z
			.string()
			.max(255, {
				message: t('page.securityPolicy.edit.form.description.ErrorMaxLength', { maxLength: 255 }),
			})
			.optional(),
		userGroups: z.array(z.number()).refine((data) => data.length > 0 || !data, {
			message: t('page.securityPolicy.edit.form.userGroups.helperText.required'),
		}),
		pamTargetGroups: z.array(z.number()).refine((data) => data.length > 0 || !data, {
			message: t('page.securityPolicy.edit.form.pamTargetGroups.helperText.required'),
		}),
		ruleSets: z.array(z.number()).refine((data) => data.length > 0 || !data, {
			message: t('page.securityPolicy.edit.form.ruleSets.helperText.required'),
		}),
	});

export const securityPolicySchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	tenantID: z.number().nullable().optional(),
});

export const pamTargetGroupsSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
});

export const userGroupsSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	totalUsers: z.number(),
	createdAt: z.string(),
	updatedAt: z.string(),
});

export const ruleSetListSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	tenantID: z.number().optional().nullable(),
});
