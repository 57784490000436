import * as React from 'react';
import { TextField as OriginalTextField, Typography } from '@mui/material';

import { Controller, FieldError } from 'react-hook-form';
import { EFieldVariant, EFieldType } from './enums';
import { PortFieldProps } from './types';
import { INVALID_KEYS } from './constants';

export const PortField: React.FC<PortFieldProps> = (props): JSX.Element => {
	const {
		name,
		label,
		helperText,
		InputProps,
		disabled = false,
		fullWidth = true,
		InputLabelProps,
		variant = EFieldVariant.OUTLINED,
		placeholder,
	} = props;

	const getTextFieldProps = React.useCallback(
		(fieldError: FieldError | undefined) => ({
			label,
			error: !!fieldError,
			disabled,
			variant,
			fullWidth,
			placeholder,
			InputLabelProps,
			InputProps,
			helperText:
				fieldError && typeof fieldError.message === 'string' ?
					<Typography component='span' color='error' sx={{ fontSize: 12 }}>
						{fieldError.message}
					</Typography>
				:	helperText,
		}),
		[label, helperText, disabled, fullWidth, variant, placeholder, InputProps],
	);

	const handleKeyDown = React.useCallback((event: React.KeyboardEvent<HTMLInputElement>) => {
		if (INVALID_KEYS.includes(event.key)) {
			event.preventDefault();
		}
	}, []);

	return (
		<Controller
			name={name}
			control={props.control}
			render={({ field, fieldState }) => (
				<OriginalTextField
					type={EFieldType.NUMBER}
					inputProps={{ min: 0, max: 65535 }}
					id={`${name}-textfield`}
					value={field.value || ''}
					onChange={field.onChange}
					{...getTextFieldProps(fieldState.error)}
					onKeyDown={handleKeyDown}
				/>
			)}
		/>
	);
};
