import * as React from 'react';
import {
	Avatar,
	Box,
	Chip,
	CircularProgress,
	Divider,
	Grid,
	Stack,
	Tooltip,
	Typography,
	useTheme,
} from '@mui/material';
import {
	Autorenew as AutorenewIcon,
	DoDisturbAlt as DoDisturbAltIcon,
	Smartphone as SmartphoneIcon,
	Room as RoomIcon,
	Dialpad as DialpadIcon,
	Fingerprint as FingerprintIcon,
	Close as CloseIcon,
	Done as DoneIcon,
	PhoneAndroid as PhoneAndroidIcon,
	PhoneIphone as PhoneIphoneIcon,
	Schedule as ScheduleIcon,
	TabletMac as TabletMacIcon,
	QuestionMark as QuestionMarkIcon,
} from '@mui/icons-material';
import { TabContext, TabPanel } from '@mui/lab';
import { useTranslation } from 'react-i18next';
import SSHIcon from '../../../../assets/images/ssh-icon.png';
import RDPIcon from '../../../../assets/images/rdp-icon.png';
import VNCIcon from '../../../../assets/images/vnc-icon.png';
import PersonIcon from '../../../../assets/images/person-logo.png';
import ExcaliburIcon from '../../../../assets/images/excalibur-logo.png';
import { useFormatDate } from '../../../../hooks/useFormatDate';
import { Heading } from '../../../../components/Heading/Heading';
import { LabeledBox } from '../../../../components/LabeledBox/LabeledBox';
import { ChipArray } from '../../../../components/ChipArray/ChipArray';
import { useACL } from '../../../../hooks/useACL';
import { EPermission } from '../../../../enums/permission/EPermission';
import { GroupDto, TokenTableRowDto } from '../../../../api/Api';
import { useNavigate } from '../../../../hooks/useNavigate';
import { EActionStatus } from '../../enums';
import { GeneralSectionProps } from './types';
import { Target } from '../Target/Target';
import { SectionHeader } from '../../../../components/SectionHeader/SectionHeader';

export const GeneralSection: React.FC<GeneralSectionProps> = ({ actionDetail, tokenDetail, userGroups }) => {
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const formatDate = useFormatDate();
	const theme = useTheme();

	const renderChip = React.useMemo(() => {
		const renderStatusChip = (value: string | undefined): JSX.Element => {
			if (!value) {
				return (
					<Chip
						label={t('page.action.list.table.body.unknown')}
						sx={{ bgcolor: '#bdbdbd', color: 'white' }}
					/>
				);
			}

			switch (value) {
				case EActionStatus.COMPLETED:
					return (
						<Chip icon={<DoneIcon />} label={t('page.action.list.table.body.completed')} color='success' />
					);
				case EActionStatus.FAILED:
					return <Chip icon={<CloseIcon />} label={t('page.action.list.table.body.failed')} color='error' />;
				case EActionStatus.ONGOING:
					return (
						<Chip
							icon={<AutorenewIcon />}
							label={t('page.action.list.table.body.ongoing')}
							color='primary'
						/>
					);
				case EActionStatus.TIMEOUTED:
					return (
						<Chip
							icon={<ScheduleIcon />}
							label={t('page.action.list.table.body.timeouted')}
							color='warning'
						/>
					);
				case EActionStatus.CANCELLED:
					return (
						<Chip
							icon={<DoDisturbAltIcon />}
							label={t('page.action.list.table.body.cancelled')}
							color='error'
						/>
					);
				default:
					return (
						<Chip
							label={t('page.action.list.table.body.unknown')}
							sx={{ bgcolor: '#bdbdbd', color: 'white' }}
						/>
					);
			}
		};

		return renderStatusChip;
	}, [t]);

	const getTargetLogo = React.useCallback((type: string | undefined): string => {
		if (!type) {
			return '';
		}
		const targetTypeLogos = new Map<string, string>([
			['user', PersonIcon],
			['dashboard', ExcaliburIcon],
			['ssh', SSHIcon],
			['rdp', RDPIcon],
			['vnc', VNCIcon],
		]);

		const logo = targetTypeLogos.get(type.trim().toLocaleLowerCase());

		return logo ? logo : '';
	}, []);
	const getAppVersion = () => {
		if (tokenDetail) {
			const extendedDetail: TokenTableRowDto & { version?: number } = tokenDetail;

			return extendedDetail?.version ? extendedDetail.version : t('page.action.list.drawer.body.notAvailable');
		}

		return t('page.action.list.drawer.body.notAvailable');
	};

	// const getVerifiedFactorLogo = React.useCallback((factor: EVerifiedFactor): React.ReactNode => {
	// 	const verifiedFactorLogos = new Map<EVerifiedFactor, React.ReactNode>([
	// 		[
	// 			EVerifiedFactor.Phone,
	// 			<Tooltip title={t('page.action.list.drawer.tooltips.phone')} placement='top' enterDelay={500} arrow>
	// 				<SmartphoneIcon sx={{ fontSize: '45px' }} color='primary' />
	// 			</Tooltip>,
	// 		],
	// 		[
	// 			EVerifiedFactor.Geolocation,
	// 			<Tooltip
	// 				title={t('page.action.list.drawer.tooltips.geolocation')}
	// 				placement='top'
	// 				enterDelay={500}
	// 				arrow
	// 			>
	// 				<RoomIcon sx={{ fontSize: '45px' }} color='primary' />
	// 			</Tooltip>,
	// 		],
	// 		[
	// 			EVerifiedFactor.Pin,
	// 			<Tooltip title={t('page.action.list.drawer.tooltips.pin')} placement='top' enterDelay={500} arrow>
	// 				<DialpadIcon sx={{ fontSize: '45px' }} color='primary' />
	// 			</Tooltip>,
	// 		],
	// 		[
	// 			EVerifiedFactor.Biometry,
	// 			<Tooltip title={t('page.action.list.drawer.tooltips.biometry')} placement='top' enterDelay={500} arrow>
	// 				<FingerprintIcon sx={{ fontSize: '45px' }} color='primary' />
	// 			</Tooltip>,
	// 		],
	// 	]);

	// 	const logo = verifiedFactorLogos.get(factor);

	// 	return logo ? logo : null;
	// }, []);

	const getDeviceLogo = React.useCallback((system: string | undefined): React.ReactNode => {
		if (!system) {
			return (
				<Tooltip
					title={t('page.action.list.drawer.tooltips.unknownDevice')}
					placement='bottom'
					enterDelay={500}
					arrow
				>
					<QuestionMarkIcon sx={{ fontSize: '45px' }} />
				</Tooltip>
			);
		}
		const deviceLogos = new Map<string, React.ReactNode>([
			[
				'android',
				<Tooltip
					title={t('page.action.list.drawer.tooltips.android')}
					placement='bottom'
					enterDelay={500}
					arrow
				>
					<PhoneAndroidIcon sx={{ fontSize: '45px' }} />
				</Tooltip>,
			],
			[
				'ios',
				<Tooltip title={t('page.action.list.drawer.tooltips.ios')} placement='bottom' enterDelay={500} arrow>
					<PhoneIphoneIcon sx={{ fontSize: '45px' }} />
				</Tooltip>,
			],
			[
				'ipados',
				<Tooltip title={t('page.action.list.drawer.tooltips.ipados')} placement='bottom' enterDelay={500} arrow>
					<TabletMacIcon sx={{ fontSize: '45px' }} />
				</Tooltip>,
			],
			[
				'windows',
				<Tooltip
					title={t('page.action.list.drawer.tooltips.windows')}
					placement='bottom'
					enterDelay={500}
					arrow
				>
					<SmartphoneIcon sx={{ fontSize: '45px' }} />
				</Tooltip>,
			],
		]);

		const logo = deviceLogos.get(system);

		if (!logo) {
			return (
				<Tooltip
					title={t('page.action.list.drawer.tooltips.unknownDevice')}
					placement='bottom'
					enterDelay={500}
					arrow
				>
					<QuestionMarkIcon sx={{ fontSize: '45px' }} />
				</Tooltip>
			);
		}

		return logo;
	}, []);

	const handleOnUserGroupChipClick = React.useCallback(
		(group: GroupDto) => {
			if (!isAllowed([EPermission.USER_GROUPS_READ])) {
				return;
			}
			navigate(`/users/userGroups/${group.id}`);
		},
		[isAllowed],
	);

	return (
		<Stack spacing={2}>
			<Stack spacing={1}>
				<SectionHeader
					title={t('page.action.list.drawer.subtitle.general')}
					description={t('page.action.list.drawer.description.general')}
				/>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={4} alignContent='center'>
								<Typography fontWeight='bold'>
									{`${t('page.action.list.drawer.body.user')}: `}
								</Typography>
							</Grid>
							<Grid item xs={8} alignContent='center'>
								<Box display='flex' alignItems='center' gap={2}>
									<Avatar sx={{ width: '30px', height: '30px' }} />
									<Typography>
										{`${actionDetail?.useTitle ? `${actionDetail.useTitle} ` : ''}${actionDetail?.userName} ${actionDetail?.userSurname ? actionDetail.userSurname : ''}`}
									</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={4} alignContent='center'>
								<Typography fontWeight='bold'>
									{`${t('page.action.list.drawer.body.account')}: `}
								</Typography>
							</Grid>
							<Grid item xs={8} alignContent='center'>
								<Typography>{actionDetail?.accountName}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={4} alignContent='center'>
								<Typography fontWeight='bold'>
									{`${t('page.action.list.drawer.body.status')}: `}
								</Typography>
							</Grid>
							<Grid item xs={8} alignContent='center'>
								{renderChip(actionDetail?.status)}
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={4} alignContent='center'>
								<Typography fontWeight='bold'>
									{`${t('page.action.list.drawer.body.time')}: `}
								</Typography>
							</Grid>
							<Grid item xs={8} alignContent='center'>
								<Typography>{formatDate(actionDetail?.createdAt, true)}</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={4} alignContent='center'>
								<Typography fontWeight='bold'>
									{`${t('page.action.list.drawer.body.target')}: `}
								</Typography>
							</Grid>
							<Grid item xs={8} alignContent='center'>
								<Target
									targetID={actionDetail?.targetID as number}
									targetType={actionDetail?.targetType as number}
									targetName={actionDetail?.targetName as string}
									pamTargetType={actionDetail?.pamTargetType}
									auditDateTime={actionDetail?.createdAt}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Stack>

			<Divider />

			<Stack spacing={2}>
				<SectionHeader
					title={t('page.action.list.drawer.subtitle.userGroups')}
					description={t('page.action.list.drawer.description.userGroups')}
				/>
				<LabeledBox label='' sx={{ padding: 2 }}>
					{userGroups && userGroups.length > 0 ?
						<ChipArray
							chipList={
								isAllowed([EPermission.USER_GROUPS_READ, EPermission.USER_GROUPS_READ_OWN], false) ?
									userGroups
								:	undefined
							}
							onChipClick={handleOnUserGroupChipClick}
							wrap={true}
						/>
					:	t('page.action.detail.sections.general.body.noGroupAssigned')}
				</LabeledBox>
			</Stack>

			<Divider />

			{/* <Stack spacing={1}>
				<SectionHeader
					title={t('page.action.list.drawer.subtitle.target')} 
					description={t('page.action.list.drawer.description.target')}
				/>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={4} alignContent='center'>
								<Typography  fontWeight='bold' >
									{`${t('page.action.list.drawer.body.type')}: `}
								</Typography>
							</Grid>
							<Grid item xs={8} alignContent='center'>
								<Box display='flex' alignItems='center' gap={2}>
									<img
												alt={t('page.action.list.table.body.logo')}
												height={30}
												src={getTargetLogo(actionDetail?.targetType)}
												loading='lazy'
												style={{ borderRadius: '4px' }}
											/>
									<Typography  >{actionDetail?.targetType}</Typography>
								</Box>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={4} alignContent='center'>
								<Typography  fontWeight='bold' >
									{`${t('page.action.list.drawer.body.target')}: `}
								</Typography>
							</Grid>
							<Grid item xs={8} alignContent='center'>
								<Typography  >{actionDetail?.targetName}</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Stack> */}

			{/* <Divider /> */}

			{/* <Stack spacing={1}>
						<SectionHeader
							title={t('page.action.list.drawer.subtitle.verifiedFactors')} 
							description={t('page.action.list.drawer.description.verifiedFactors')}
						/>
						<Box display='flex' gap='8px'>
							{actionDetail?.factors.verified.map((factor) => (
								<Box key={factor}>{getVerifiedFactorLogo(factor)}</Box>
							))}
						</Box>
					</Stack> */}

			{/* <Divider /> */}

			<Stack spacing={1}>
				<SectionHeader
					title={t('page.action.list.drawer.subtitle.staticFactors')}
					description={t('page.action.list.drawer.description.staticFactors')}
				/>
				<Grid container spacing={1}>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={4} alignContent='center'>
								<Typography fontWeight='bold'>
									{`${t('page.action.list.drawer.body.phoneIp')}: `}
								</Typography>
							</Grid>
							<Grid item xs={8} alignContent='center'>
								<Typography>
									{actionDetail?.phoneIP ?
										actionDetail.phoneIP
									:	t('page.action.list.drawer.body.notAvailable')}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
					<Grid item xs={12}>
						<Grid container spacing={1}>
							<Grid item xs={4} alignContent='center'>
								<Typography fontWeight='bold'>
									{`${t('page.action.list.drawer.body.phoneStatus')}: `}
								</Typography>
							</Grid>
							<Grid item xs={8} alignContent='center'>
								<Typography>
									{actionDetail?.phoneConnectionStatus ?
										actionDetail?.phoneConnectionStatus
									:	t('page.action.list.drawer.body.notAvailable')}
								</Typography>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Stack>

			<Divider />

			<Stack spacing={2}>
				<SectionHeader
					title={t('page.action.list.drawer.subtitle.token')}
					description={t('page.action.list.drawer.description.token')}
				/>
				<Box
					display='flex'
					justifyContent='center'
					alignItems='center'
					sx={{
						border: '1px solid',
						borderColor: theme.palette.divider,
						borderRadius: 1,
						padding: theme.spacing(2),
					}}
				>
					{tokenDetail ?
						<Box
							display='flex'
							justifyContent='space-between'
							alignItems='center'
							flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
							gap={1}
							width='100%'
						>
							<Box display='flex' alignItems='center' flex='1 1 auto' width='100%'>
								{getDeviceLogo(tokenDetail?.systemName)}
								<Box>
									<Typography>{tokenDetail?.model ? tokenDetail.model : ''}</Typography>
									<Typography>{`${t(`page.action.list.drawer.body.${tokenDetail?.systemName}`)} ${tokenDetail?.systemVersion}`}</Typography>
								</Box>
							</Box>
							<Box flex='1 1 auto'>
								<Typography
									sx={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										fontSize: '14px',
									}}
								>{`${tokenDetail?.lastConnectedAt ? formatDate(tokenDetail.lastConnectedAt) : t('page.action.list.drawer.body.notAvailable')}`}</Typography>
								<Typography
									sx={{
										whiteSpace: 'nowrap',
										overflow: 'hidden',
										textOverflow: 'ellipsis',
										fontSize: '14px',
									}}
								>{`${t('page.action.list.drawer.body.version')}: ${getAppVersion()}`}</Typography>
							</Box>
						</Box>
					:	<CircularProgress sx={{ fontSize: '45px' }} />}
				</Box>
			</Stack>
		</Stack>
	);
};
