import * as React from 'react';
import { AxiosError } from 'axios';
import { useTranslation } from 'react-i18next';
import { Avatar, Box, Chip, Grid, Paper, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import { Smartphone as SmartphoneIcon } from '@mui/icons-material';
import { useParams } from 'react-router-dom';

import AndroidIcon from '../../assets/images/android-logo.png';
import AppleIcon from '../../assets/images/apple-logo.png';
import WindowsIcon from '../../assets/images/windows-logo.png';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { useFormatDate } from '../../hooks/useFormatDate';
import { TokenState, UserState } from './types';
import { Heading } from '../../components/Heading/Heading';
import { Preloader } from '../../components/Preloader/Preloader';
import { ECertificateStatus, ESystemName } from './enums';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';

export const TokenDetail: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const formatDate = useFormatDate();
	const { t } = useTranslation();
	const { id } = useParams();
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const [tokenState, setTokenState] = React.useState<TokenState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const [userDetailState, setUserDetailState] = React.useState<UserState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const userFullName = React.useMemo(() => {
		if (userDetailState.loaded && userDetailState.data) {
			return `${userDetailState.data.name} ${userDetailState.data.surname}`;
		}

		return t('page.token.detail.body.unknown');
	}, [userDetailState]);

	const getToken = React.useCallback(async (tokenID: number): Promise<void> => {
		setTokenState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});

		let userID: number | null = null;

		try {
			const response = await api.tokens.getToken(tokenID);

			userID = response.data?.userID ? response.data.userID : null;

			setTokenState({
				loading: false,
				loaded: true,
				data: response.data,
				error: null,
			});
		} catch (error) {
			console.error(error);
			setTokenState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
		}

		if (!userID) {
			return;
		}

		setUserDetailState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});

		try {
			const response = await api.users.getUserDetail(userID);

			setUserDetailState({
				loading: false,
				loaded: true,
				data: response.data,
				error: null,
			});
		} catch (error) {
			console.error(error);
			setUserDetailState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
		}
	}, []);

	// const renderChip = React.useMemo(() => {
	// 	const renderStatusChip = (value: string | undefined): JSX.Element => {
	// 		if (!value) {
	// 			return <Chip label={t('page.token.detail.body.unknown')} sx={{ bgcolor: '#bdbdbd', color: 'white' }} />;
	// 		}

	// 		switch (value) {
	// 			case ECertificateStatus.ACTIVE:
	// 				return <Chip label={t('page.token.detail.body.active')} color='success' />;
	// 			case ECertificateStatus.REVOKED:
	// 				return <Chip label={t('page.token.detail.body.revoked')} color='warning' />;
	// 			case ECertificateStatus.EXPIRED:
	// 				return <Chip label={t('page.token.detail.body.expired')} color='error' />;
	// 			default:
	// 				return (
	// 					<Chip label={t('page.token.detail.body.unknown')} sx={{ bgcolor: '#bdbdbd', color: 'white' }} />
	// 				);
	// 		}
	// 	};

	// 	return renderStatusChip;
	// }, [t]);

	const getPlatformLogoBySystemName = React.useCallback(
		(systemName: string | undefined): string => {
			if (!systemName) {
				return '';
			}
			const systemLogos = new Map<string, string>([
				[ESystemName.ANDROID, AndroidIcon],
				[ESystemName.IOS, AppleIcon],
				[ESystemName.IPADOS, AppleIcon],
				[ESystemName.WINDOWS, WindowsIcon],
			]);

			const logo = systemLogos.get(systemName.trim().toLocaleLowerCase());

			return logo ? logo : '';
		},
		[ESystemName],
	);

	const formatSystemName = React.useCallback(
		(systemName: string | undefined): string => {
			if (!systemName) {
				return t('page.token.detail.body.unknown');
			}
			const formattedNames = new Map<string, string>([
				[ESystemName.ANDROID, 'Android'],
				[ESystemName.IOS, 'iOS'],
				[ESystemName.IPADOS, 'iPadOS'],
				[ESystemName.WINDOWS, 'Windows'],
			]);

			const name = formattedNames.get(systemName.trim().toLocaleLowerCase());

			return name ? name : t('page.token.detail.body.unknown');
		},
		[ESystemName],
	);

	const getStackWidth = () => {
		if (matchesLG) {
			return '100%';
		}

		return '50%';
	};

	React.useEffect(() => {
		if (
			id &&
			!tokenState.loading &&
			!tokenState.loaded &&
			!tokenState.error &&
			!userDetailState.loading &&
			!userDetailState.loaded &&
			!userDetailState.error
		) {
			getToken(Number(id));
		}
	}, [id, tokenState]);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={2}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.token.detail.title')}
						description={t('page.token.detail.description.main')}
						icon={SmartphoneIcon}
					/>
					{tokenState.loading || !tokenState.loaded ?
						<Preloader />
					:	<Stack
							spacing={2}
							sx={{
								width: getStackWidth(),
							}}
						>
							<Stack spacing={2}>
								<SectionHeader
									title={t('page.token.detail.subtitle.general')}
									description={t('page.token.detail.description.general')}
								/>
								<Grid container gap={2}>
									<Grid item xs={12}>
										<Grid container spacing={1} alignContent='center'>
											<Grid item xs={12} sm={4} md={4} alignContent='center'>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.token.detail.body.name')}: `}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={8} md={8}>
												<Typography px={2}>
													{tokenState.data?.name ?
														tokenState.data.name
													:	t('page.token.detail.body.unknown')}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={1} alignContent='center'>
											<Grid item xs={12} sm={4} md={4} alignContent='center'>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.token.detail.body.user')}: `}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={8} md={8} alignContent='center'>
												<Box display='flex' alignItems='center' gap={2} px={2}>
													<Avatar sx={{ width: '30px', height: '30px' }} />
													<Typography>
														{userFullName ?
															userFullName
														:	t('page.token.detail.body.unknown')}
													</Typography>
												</Box>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={1} alignContent='center'>
											<Grid item xs={12} sm={4} md={4} alignContent='center'>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.token.detail.body.created')}: `}
												</Typography>
											</Grid>
											<Grid item xs={8} alignContent='center'>
												<Typography px={2}>
													{formatDate(tokenState.data?.createdAt, true)}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									{/* <Grid item xs={12}>
										<Grid container spacing={1} alignContent='center'>
											<Grid item xs={12} sm={4} md={4} alignContent='center'>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.token.detail.body.certificate')}: `}
												</Typography>
											</Grid>
											<Grid item xs={8} alignContent='center'>
												<Box display='flex' alignItems='center' gap={2} px={2}>
													{renderChip(tokenState.data?.certificateStatus)}
												</Box>
											</Grid>
										</Grid>
									</Grid> */}
								</Grid>
							</Stack>

							<Stack spacing={2}>
								<SectionHeader
									title={t('page.token.detail.subtitle.device')}
									description={t('page.token.detail.description.device')}
								/>
								<Grid container gap={2}>
									<Grid item xs={12}>
										<Grid container spacing={1} alignContent='center'>
											<Grid item xs={12} sm={4} md={4} alignContent='center'>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.token.detail.body.brand')}: `}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={8} md={8}>
												<Typography px={2}>
													{tokenState.data?.brand ?
														tokenState.data.brand
													:	t('page.token.detail.body.unknown')}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={1} alignContent='center'>
											<Grid item xs={12} sm={4} md={4} alignContent='center'>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.token.detail.body.model')}: `}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={8} md={8}>
												<Typography px={2}>
													{tokenState.data?.model ?
														tokenState.data.model
													:	t('page.token.detail.body.unknown')}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={1} alignContent='center'>
											<Grid item xs={12} sm={4} md={4} alignContent='center'>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.token.detail.body.systemName')}: `}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={8} md={8} alignContent='center'>
												<Box display='flex' alignItems='flex-end' gap={2} px={2}>
													<img
														alt={t('page.token.list.table.body.logo')}
														height={30}
														src={getPlatformLogoBySystemName(tokenState.data?.systemName)}
														loading='lazy'
														style={{ borderRadius: 'none' }}
													/>
													<Typography>
														{formatSystemName(tokenState.data?.systemName)}
													</Typography>
												</Box>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={1} alignContent='center'>
											<Grid item xs={12} sm={4} md={4} alignContent='center'>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.token.detail.body.systemVersion')}: `}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={8} md={8}>
												<Typography px={2}>
													{tokenState.data?.systemVersion ?
														tokenState.data.systemVersion
													:	t('page.token.detail.body.unknown')}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={1} alignContent='center'>
											<Grid item xs={12} sm={4} md={4} alignContent='center'>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.token.detail.body.version')}: `}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={8} md={8}>
												<Typography px={2}>
													{tokenState.data?.appVersion ?
														tokenState.data.appVersion
													:	t('page.token.detail.body.unknown')}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
									<Grid item xs={12}>
										<Grid container spacing={1} alignContent='center'>
											<Grid item xs={12} sm={4} md={4} alignContent='center'>
												<Typography px={1} sx={{ fontWeight: 'bold' }}>
													{`${t('page.token.detail.body.lastConnected')}: `}
												</Typography>
											</Grid>
											<Grid item xs={12} sm={8} md={8}>
												<Typography px={2}>
													{formatDate(tokenState.data?.lastConnectedAt, true)}
												</Typography>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Stack>
						</Stack>
					}
				</Stack>
			</Paper>
		</Box>
	);
};
