import * as React from 'react';
import { useTranslation } from 'react-i18next';
import {
	Checkbox,
	FormControlLabel,
	FormGroup,
	Typography,
	Accordion,
	AccordionDetails,
	Box,
	FormControl,
	Divider,
	AccordionSummary,
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';

import { CheckBoxItem, CheckBoxItemsGroup, CheckBoxGroupProps } from '../../types';

export const CheckBoxGroup: React.FC<CheckBoxGroupProps> = (props): JSX.Element => {
	const { group, groups, setGroups } = props;
	const [expanded, setExpanded] = React.useState<boolean>(true);
	const { t } = useTranslation();
	const groupItems = Array.from(group.checkBoxItemMap.values());

	const handleToggleExpand = () => setExpanded(!expanded);

	const hasAllChecked = (scope: 'system' | 'tenant') =>
		groupItems.every((item) => (scope === 'system' ? item.isSystemChecked : item.isTenantChecked));

	const hasNoneChecked = (scope: 'system' | 'tenant') =>
		groupItems.every((item) => (scope === 'system' ? !item.isSystemChecked : !item.isTenantChecked));

	const toggleSelectAll = (scope: 'system' | 'tenant') => {
		const updatedState = new Map(group.checkBoxItemMap);
		const selectAll = hasNoneChecked(scope);

		groupItems.forEach((item) => {
			updatedState.set(item.id, {
				...item,
				isSystemChecked: scope === 'system' ? selectAll : item.isSystemChecked,
				isTenantChecked: scope === 'tenant' ? selectAll : item.isTenantChecked,
			});
		});

		const updatedGroup: CheckBoxItemsGroup = { ...group, checkBoxItemMap: updatedState };
		setGroups(new Map(groups.set(group.id, updatedGroup)));
	};

	const handleOnSelect = (id: number, scope: 'system' | 'tenant', groupID: number) => {
		const updatedState = new Map(group.checkBoxItemMap);
		const checkBox = updatedState.get(id) as CheckBoxItem;

		updatedState.set(id, {
			...checkBox,
			isSystemChecked: scope === 'system' ? !checkBox.isSystemChecked : checkBox.isSystemChecked,
			isTenantChecked: scope === 'tenant' ? !checkBox.isTenantChecked : checkBox.isTenantChecked,
		});

		const updatedGroup: CheckBoxItemsGroup = { ...group, checkBoxItemMap: updatedState };
		setGroups(new Map(groups.set(groupID, updatedGroup)));
	};

	return (
		<Accordion
			expanded={expanded}
			onChange={handleToggleExpand}
			disableGutters
			sx={{ overflow: 'hidden', border: '1px solid rgba(0, 0, 0, 0.12)' }}
		>
			<AccordionSummary sx={{ borderRadius: expanded ? '8px 8px 0 0' : '8px' }}>
				<Box display='flex' flexDirection='column' width='100%'>
					<Box display='flex' justifyContent='space-between' alignItems='center'>
						<Box>
							<Typography variant='body1'>{t(`other.permissions.group.name.${group.id}`)}</Typography>
							<Typography variant='body2'>
								{t(`other.permissions.group.description.${group.id}`)}
							</Typography>
						</Box>
						<Box display='flex' alignItems='center'>
							<Box
								display='flex'
								alignItems='center'
								onClick={(e) => {
									e.stopPropagation();
									toggleSelectAll('system');
								}}
								sx={{ cursor: 'pointer' }}
							>
								<Checkbox
									checked={hasAllChecked('system')}
									indeterminate={!hasAllChecked('system') && !hasNoneChecked('system')}
								/>
								<Typography variant='body1'>
									{t('component.checkBoxGroupList.selectAllSystem')}
								</Typography>
							</Box>
							<Box
								display='flex'
								alignItems='center'
								onClick={(e) => {
									e.stopPropagation();
									if (group.id === 6) {
										return;
									}
									toggleSelectAll('tenant');
								}}
								sx={{ cursor: 'pointer' }}
							>
								<Checkbox
									disabled={group.id === 6}
									checked={hasAllChecked('tenant')}
									indeterminate={!hasAllChecked('tenant') && !hasNoneChecked('tenant')}
								/>
								<Typography variant='body1'>
									{t('component.checkBoxGroupList.selectAllTenant')}
								</Typography>
							</Box>
						</Box>
					</Box>
				</Box>
			</AccordionSummary>
			<Divider />
			<AccordionDetails sx={{ borderRadius: '0 0 8px 8px', borderTop: '1px solid rgba(0, 0, 0, 0.12)' }}>
				<FormGroup>
					{groupItems.map((item, index) => (
						<React.Fragment key={item.id}>
							{index === 0 && (
								<Box
									display='flex'
									justifyContent='flex-end'
									width='100%'
									sx={{ paddingRight: '16px' }}
								>
									<Typography variant='caption' sx={{ marginRight: '34px' }}>
										{t('component.checkBoxGroupList.systemLabel')}
									</Typography>
									<Typography variant='caption'>
										{t('component.checkBoxGroupList.tenantLabel')}
									</Typography>
								</Box>
							)}
							<Box
								display='flex'
								alignItems='center'
								width='100%'
								sx={{
									cursor: 'pointer',
									'&:hover': { backgroundColor: 'rgba(0, 0, 0, 0.04)' },
								}}
							>
								<FormControl margin='dense' fullWidth>
									<Typography variant='body1' noWrap sx={{ userSelect: 'none' }}>
										{t(`other.permissions.items.name.${item.id}`)}
									</Typography>
									<Typography
										variant='caption'
										color='textSecondary'
										noWrap
										sx={{ userSelect: 'none' }}
									>
										{t(`other.permissions.items.description.${item.id}`)}
									</Typography>
								</FormControl>
								<Checkbox
									onClick={() => handleOnSelect(item.id, 'system', group.id)}
									checked={item.isSystemChecked}
									sx={{ marginRight: '35px' }}
								/>
								<Checkbox
									onClick={() => handleOnSelect(item.id, 'tenant', group.id)}
									checked={group.id === 6 ? false : item.isTenantChecked}
									disabled={group.id === 6}
									sx={{ marginRight: '15px' }}
								/>
							</Box>
							<Divider />
						</React.Fragment>
					))}
				</FormGroup>
			</AccordionDetails>
		</Accordion>
	);
};
