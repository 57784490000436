import React from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { Box, Paper, FormGroup, Stack, useMediaQuery, useTheme } from '@mui/material';
import { ManageAccounts as ManageAccountsIcon } from '@mui/icons-material';
import { useMutation, useQuery } from '@tanstack/react-query';
import { enqueueSnackbar } from 'notistack';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import { Heading } from '../../components/Heading/Heading';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { InvitationExpirationValuesResponseDto } from '../../api/Api';
import { FloatingButtonSave } from '../../components/Buttons/FloatingButton/FloatingButtonSave';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { getDurationInString, getDurationInUnits } from '../../utils/DateTime';
import { FormValues } from './types';
import { Preloader } from '../../components/Preloader/Preloader';
import { TextField } from '../../components/FormFields/TextField/TextField';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { getExpirationTimesSchema } from './schema';
import { EXPIRATION_TIMES_UNITS } from './constants';

export const ExpirationTimePage: React.FC = (): JSX.Element => {
	const theme = useTheme();
	const matchesLG = useMediaQuery(theme.breakpoints.down('lg'));

	const { t } = useTranslation();

	const api = useSwaggerApi();

	const getStackWidth = () => {
		return matchesLG ? '100%' : '50%';
	};

	const { data, isLoading, refetch } = useQuery({
		queryKey: [EQueryKey.EXPIRATION_TIMES_QUERY],
		queryFn: async () => {
			try {
				const { data } = await api.settings.getExpirationProperties();

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
	});

	const {
		handleSubmit,
		register,
		reset,
		formState: { errors },
	} = useForm<FormValues>({
		mode: 'onChange',
		defaultValues: {
			invitationEmailExpiry: '',
			qrVerificationEmailExpiry: '',
			smtpVerificationEmailExpiry: '',
		},
		resolver: zodResolver(getExpirationTimesSchema(t)),
	});

	React.useEffect(() => {
		if (!data) {
			return;
		}

		const expirationValues = data.reduce(
			(obj, { key, value }) => Object.assign(obj, { [key]: getDurationInString(value, EXPIRATION_TIMES_UNITS) }),
			{},
		) as FormValues;

		reset(expirationValues);
	}, [data]);

	const { mutate, isPending } = useMutation({
		mutationFn: ({ values }: { values: InvitationExpirationValuesResponseDto }) =>
			api.settings.updateExpirationProperties(values),
		onSuccess: () => {
			enqueueSnackbar(t('page.expirationTimes.snackbar.update.success'), { variant: 'success' });
			refetch();
		},
		onError: (error) => {
			// eslint-disable-next-line no-console
			console.error(error);
		},
	});

	const onSubmit = (formValues: FormValues) =>
		mutate({
			values: Object.keys(formValues).map((key) => ({
				key: key,
				value: getDurationInUnits(formValues[key as keyof FormValues] as string, EXPIRATION_TIMES_UNITS),
			})),
		});

	return (
		<Box component='form' noValidate onSubmit={handleSubmit(onSubmit)}>
			<Paper elevation={3}>
				<Stack
					spacing={2}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.expirationTimes.title')}
						description={t('page.expirationTimes.description')}
						icon={ManageAccountsIcon}
					/>
					{isLoading && <Preloader />}
					{!isLoading && (
						<FormGroup>
							<Stack
								spacing={1}
								sx={{
									width: getStackWidth(),
								}}
							>
								<Heading label={t('page.expirationTimes.heading.label')} />
								<Stack spacing={2}>
									<TextField
										register={register}
										name='qrVerificationEmailExpiry'
										label={t('page.expirationTimes.qrVerificationEmailExpiry.label')}
										disabled={isPending}
										error={errors.qrVerificationEmailExpiry}
										helperText={t('page.expirationTimes.qrVerificationEmailExpiry.description')}
									/>
									<TextField
										register={register}
										name='smtpVerificationEmailExpiry'
										label={t('page.expirationTimes.smtpVerificationEmailExpiry.label')}
										disabled={isPending}
										error={errors.smtpVerificationEmailExpiry}
										helperText={t('page.expirationTimes.smtpVerificationEmailExpiry.description')}
									/>
									<TextField
										register={register}
										name='invitationEmailExpiry'
										label={t('page.expirationTimes.invitationEmailExpiry.label')}
										disabled={isPending}
										error={errors.invitationEmailExpiry}
										helperText={t('page.expirationTimes.invitationEmailExpiry.description')}
									/>
								</Stack>
							</Stack>
						</FormGroup>
					)}
				</Stack>
			</Paper>
			<FloatingButtonSave
				disabled={isPending}
				ariaLabel={t('page.expirationTimes.ariaLabel')}
				tooltipTitle={t('page.expirationTimes.tooltip')}
				type='submit'
			/>
		</Box>
	);
};

export default ExpirationTimePage;
