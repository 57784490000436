import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton,
	type MRT_ColumnDef,
	MRT_ToggleFullScreenButton,
	MRT_ToggleFiltersButton,
	MRT_ShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton,
	MRT_RowSelectionState,
	MRT_TableInstance,
	MaterialReactTable,
} from 'material-react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import {
	SaveAlt as SaveAltIcon,
	Delete as DeleteIcon,
	Replay as ReplayIcon,
	List as ListIcon,
} from '@mui/icons-material';
import { Avatar, Tooltip, Box, IconButton, Paper, Stack, Chip } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { enqueueSnackbar } from 'notistack';
import { AxiosError } from 'axios';

import { GetUserDetailsResponseDto, GroupDto, UserDetailModel } from '../../api/Api';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';

import { EPermission } from '../../enums/permission/EPermission';
import { ChipArray } from '../../components/ChipArray/ChipArray';
import { useACL } from '../../hooks/useACL';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EConfirmDialogState } from '../../enums/teanant/EConfirmDialogState';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { userListSchema } from './schema';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useNavigate } from '../../hooks/useNavigate';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useTableQuery } from '../../hooks/useTableQuery';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useAuthContext } from '../../contexts/AuthContext/AuthContext';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';

export const UsersPage: React.FC = (): JSX.Element => {
	const { t } = useTranslation();
	const { isAllowed } = useACL();
	const api = useSwaggerApi();
	const reactQueryClient = useReactQueryClient();
	const navigate = useNavigate();
	const formatDate = useFormatDate();
	const authContext = useAuthContext();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [open, setOpen] = React.useState(false);
	const [userIDToDelete, setUserIDToDelete] = React.useState<number | null>(null);
	const [multiUserIDToDelete, setMultiUserIDToDelete] = React.useState<{ ids: number[] }>({ ids: [] });
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const [confirmationText, setConfirmationText] = React.useState('');

	const {
		rowSelection,
		setRowSelection,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['userFullName', 'email', 'lastLoggedAt']);

	const { data, isError, isRefetching, isLoading, error } = useQuery<GetUserDetailsResponseDto>({
		queryKey: [EQueryKey.USER_DETAIL_LIST_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					limit: swaggerQuery.limit,
					offset: swaggerQuery.offset,
					columns: swaggerQuery.columns,
					filter: swaggerQuery.filter,
					sort: swaggerQuery.sort,
				};
				const response = await api.users.getUserDetails(query);
				response.data.entities.forEach((user) => {
					userListSchema.parse(user);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = data || {};

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.users.list.confirmation.delete.title'));
				setConfirmationText(t('page.users.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.users.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.users.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const handleOnOpenUserDeleteDialog = React.useCallback(
		(userID: number) => (event: React.MouseEvent) => {
			event.stopPropagation();
			setOpen(true);
			setUserIDToDelete(userID);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[isAllowed],
	);

	const handleOnOpenMultipleUsersDelete = React.useCallback(
		(table: MRT_TableInstance<Partial<UserDetailModel>>) => () => {
			const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => Number(row.original.id));

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.users.list.errorMessages.noUserSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}
			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiUserIDToDelete({ ids: selectedRowsOnActivePageIds });
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[isAllowed],
	);

	const handleOnConfirmUserDelete = React.useCallback(async () => {
		if (userIDToDelete) {
			try {
				await api.users.deleteUser(userIDToDelete);
				enqueueSnackbar(t('page.users.list.actionMessages.userSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setUserIDToDelete(null);
				updateRowSelection([userIDToDelete]);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		} else if (multiUserIDToDelete.ids.length > 0) {
			try {
				await api.users.deleteUsers(multiUserIDToDelete);
				enqueueSnackbar(t('page.users.list.actionMessages.usersSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setMultiUserIDToDelete({ ids: [] });
				updateRowSelection(multiUserIDToDelete.ids);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [userIDToDelete, multiUserIDToDelete, isAllowed]);

	const handleOnChipClick = React.useCallback(
		(group: GroupDto) => {
			if (!isAllowed([EPermission.USER_GROUPS_READ])) {
				return;
			}
			navigate(`/users/userGroups/${group.id}`);
		},
		[isAllowed],
	);

	const handleClose = React.useCallback((): void => setOpen(false), []);

	React.useEffect(() => {
		if (!isError) {
			return;
		}

		if (error instanceof AxiosError) {
			enqueueSnackbar(error.response?.data.message || error.message, {
				variant: 'error',
				persist: false,
			});
		} else {
			enqueueSnackbar((error as any).toString(), {
				variant: 'error',
				persist: false,
			});
		}
	}, [isError]);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const columns = React.useMemo<MRT_ColumnDef<Partial<UserDetailModel>>[]>(
		() => [
			{
				accessorFn: (user) =>
					`${user.title ? `${user.title} ` : ''}${user.name} ${user.surname ? user.surname : ''}`,
				accessorKey: 'userFullName',
				grow: 1,
				header: t('page.users.list.table.header.name'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Avatar alt={String(renderedCellValue)} />
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
			{
				accessorFn: (row) => `${row.email}`,
				accessorKey: 'email',
				grow: 1,
				header: t('page.users.list.table.header.email'),
			},
			{
				accessorFn: (row) => `${row.groups}`,
				header: t('page.users.list.table.header.groups'),
				enableColumnFilter: false,
				enableGlobalFilter: false,
				enableSorting: false,
				accessorKey: 'groups',
				grow: 3,
				Cell: ({ row }) => (
					<ChipArray
						wrap
						chipList={isAllowed([EPermission.USER_GROUPS_READ]) ? row.original.groups : undefined}
						limitTags={3}
						onChipClick={handleOnChipClick}
					/>
				),
			},
			{
				accessorFn: (row) => `${formatDate(row.lastLoggedAt, true)}`,
				header: t('page.users.list.table.header.lastLoggedAt'),
				filterVariant: 'datetime-range',
				accessorKey: 'lastLoggedAt',
				grow: 3,
				muiFilterDateTimePickerProps: {
					slotProps: {
						textField: {
							sx: { zIndex: 1 },
						},
					},
				},
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
							width: '200px',
						}}
					>
						<span>{renderedCellValue}</span>
					</Box>
				),
			},
		],
		[isAllowed, authContext],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.users.list.title')}
						description={t('page.users.list.description')}
						icon={ListIcon}
					/>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
						<MaterialReactTable
							columns={columns}
							data={entities}
							enableStickyHeader={false}
							enableRowActions
							state={{
								isLoading: isLoading,
								showAlertBanner: error !== null,
								pagination,
								rowSelection,
								showProgressBars: isRefetching,
								columnFilters,
								globalFilter,
								sorting,
								columnVisibility,
							}}
							muiToolbarAlertBannerProps={{
								color: 'error',
								children: <>{error?.message}</>,
							}}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							rowCount={total}
							manualPagination
							manualFiltering
							manualSorting
							onPaginationChange={setPagination}
							onSortingChange={setSorting}
							onGlobalFilterChange={setGlobalFilter}
							onColumnFiltersChange={setColumnFilters}
							onColumnVisibilityChange={setColumnVisibility}
							enableRowSelection={(row) =>
								row.original.id !== authContext.user?.user?.id && isAllowed([EPermission.USERS_DELETE])
							}
							getRowId={(originalRow) => originalRow.id?.toString() || ''}
							onRowSelectionChange={setRowSelection}
							renderRowActions={({ row }) => (
								<Tooltip arrow placement='right' title={t('page.users.list.tooltips.delete')}>
									<span>
										<IconButton
											disabled={
												row.original.id === authContext.user?.user?.id ||
												!isAllowed([EPermission.USERS_DELETE])
											}
											color='error'
											onClick={handleOnOpenUserDeleteDialog(row.original.id as number)}
										>
											<DeleteIcon />
										</IconButton>
									</span>
								</Tooltip>
							)}
							renderToolbarInternalActions={({ table }) => {
								return (
									<Box sx={{ display: 'flex', gap: '1rem' }}>
										<MRT_ToggleGlobalFilterButton
											table={table}
											title={t('component.materialReactTable.header.globalSearch')}
										/>
										<MRT_ToggleFiltersButton
											table={table}
											title={t('component.materialReactTable.header.filters')}
										/>
										<MRT_ShowHideColumnsButton
											table={table}
											title={t('component.materialReactTable.header.columns')}
										/>
										{/* <Tooltip
											title={t('page.users.list.tooltips.export')}
											placement='bottom'
											enterDelay={500}
										>
											<IconButton>
												<SaveAltIcon />
											</IconButton>
										</Tooltip> */}
										{isAllowed([EPermission.USERS_DELETE]) && (
											<Tooltip
												title={t('page.users.list.tooltips.removeSelected')}
												enterDelay={500}
											>
												<span>
													<IconButton
														color='error'
														disabled={table.getSelectedRowModel().rows.length === 0}
														onClick={handleOnOpenMultipleUsersDelete(table)}
													>
														<DeleteIcon />
													</IconButton>
												</span>
											</Tooltip>
										)}
										<MRT_ToggleDensePaddingButton
											table={table}
											title={t('component.materialReactTable.header.density')}
										/>
										<MRT_ToggleFullScreenButton
											table={table}
											title={t('component.materialReactTable.header.fullscreen')}
										/>
									</Box>
								);
							}}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									header: t('page.users.list.table.header.actions'),
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTablePaperProps={({ table }) => ({
								style: {
									zIndex: table.getState().isFullScreen ? 1250 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									paddingLeft: 2,
									paddingBottom: 2,
								},
							})}
							muiTableBodyCellProps={() => ({
								sx: {
									paddingLeft: 2,
								},
							})}
							editDisplayMode='modal'
							positionActionsColumn='last'
							localization={MRTLocalization}
							layoutMode='grid-no-grow'
						/>
					</LocalizationProvider>
				</Stack>
			</Paper>

			{isAllowed([EPermission.USERS_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmUserDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.users.list.confirmation.delete.cancel')}
					confirmText={t('page.users.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
