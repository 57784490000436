import * as React from 'react';
import {
	MRT_RowSelectionState,
	MRT_PaginationState,
	MRT_ColumnFiltersState,
	MRT_SortingState,
	MRT_VisibilityState,
} from 'material-react-table';

import { useDebounce } from './useDebounce';
import { getQueryObject, SwaggerQuery } from '../utils/Query';

type UseTableObject = {
	columnVisibility: MRT_VisibilityState;
	setColumnVisibility: React.Dispatch<React.SetStateAction<MRT_VisibilityState>>;
	globalFilter: string;
	setGlobalFilter: React.Dispatch<React.SetStateAction<string>>;
	sorting: MRT_SortingState;
	setSorting: React.Dispatch<React.SetStateAction<MRT_SortingState>>;
	columnFilters: MRT_ColumnFiltersState;
	setColumnFilters: React.Dispatch<React.SetStateAction<MRT_ColumnFiltersState>>;
	rowSelection: MRT_RowSelectionState;
	setRowSelection: React.Dispatch<React.SetStateAction<MRT_RowSelectionState>>;
	pagination: MRT_PaginationState;
	setPagination: React.Dispatch<React.SetStateAction<MRT_PaginationState>>;
	swaggerQuery: SwaggerQuery;
};

const DEFAULT_PAGE_SIZE = 10;
const DEFAULT_PAGE_INDEX = 0;
const FILTER_DEBOUNCE_TIME = 300;

export const useTableQuery = (
	fields: string[],
	{ defaultSorting = [] }: { defaultSorting?: MRT_SortingState } = {},
): UseTableObject => {
	const [columnVisibility, setColumnVisibility] = React.useState<MRT_VisibilityState>(
		fields.reduce((_visibilityState: MRT_VisibilityState, field: string) => {
			_visibilityState[field] = true;

			return _visibilityState;
		}, {} as MRT_VisibilityState),
	);
	const [globalFilter, setGlobalFilter] = React.useState('');
	const debouncedGlobalFilter = useDebounce(globalFilter, FILTER_DEBOUNCE_TIME);
	const [sorting, setSorting] = React.useState<MRT_SortingState>(defaultSorting);
	const debouncedSorting = useDebounce(sorting, FILTER_DEBOUNCE_TIME);
	const [columnFilters, setColumnFilters] = React.useState<MRT_ColumnFiltersState>([]);
	const debouncedColumnFilters = useDebounce(columnFilters, FILTER_DEBOUNCE_TIME);
	const [rowSelection, setRowSelection] = React.useState<MRT_RowSelectionState>({});
	const [pagination, setPagination] = React.useState<MRT_PaginationState>({
		pageIndex: DEFAULT_PAGE_INDEX,
		pageSize: DEFAULT_PAGE_SIZE,
	});

	return {
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		sorting,
		setSorting,
		columnFilters,
		setColumnFilters,
		rowSelection,
		setRowSelection,
		pagination,
		setPagination,
		swaggerQuery: getQueryObject(
			debouncedGlobalFilter,
			debouncedColumnFilters,
			debouncedSorting,
			pagination.pageIndex,
			pagination.pageSize,
			columnVisibility,
			fields,
		),
	};
};
