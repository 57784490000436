import { Units } from 'parse-duration';

/**
 * This constant should be used when converting string duration to number and vice versa to preserve consistency .
 *
 * This constant depends on the Database - if the database considers number to be in 'second', then this constant should be 'second'.
 *
 * @example const time =  getDurationInUnits('2 minutes', 'second')
 * const duration = getDurationInString(time, 'minute') // Error: should be 'second'
 * // To avoid such errors use this constant
 */
export const OAUTH_CLIENT_TOKEN_DURATION_UNITS: Units = 'second';

const MAX_MARIADB_UINT_VALLUE = 4294967295;

/**
 * @description 136 years
 * @see This should be in sync with the DB. DB uses unsigned int and stores time in minutes
 */
export const MAX_OAUTH_ACCESS_TOKEN_VALIDITY_SEC = MAX_MARIADB_UINT_VALLUE;

/**
 * @description 1 second
 * @see This should be in sync with the DB. DB uses unsigned int and stores time in minutes
 */
export const MIN_OAUTH_ACCESS_TOKEN_VALIDITY_SEC = 1;
