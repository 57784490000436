import { Units } from 'parse-duration';

/**
 * This constant should be used when converting string duration to number and vice versa to preserve consistency .
 *
 * This constant depends on the Database - if the database considers number to be in 'second', then this constant should be 'second'.
 *
 * @example const time =  getDurationInUnits('2 minutes', 'second')
 * const duration = getDurationInString(time, 'minute') // Error: should be 'second'
 * // To avoid such errors use this constant
 */
export const EXPIRATION_TIMES_UNITS: Units = 'millisecond';
