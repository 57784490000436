import * as React from 'react';
import Button from '@mui/material/Button';
import { Card, CardActions, CardContent, Chip, Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import { useTranslation } from 'react-i18next';
import humanizeDuration from 'humanize-duration';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import FileDownloadIcon from '@mui/icons-material/FileDownload';
import { LoadingButton } from '@mui/lab';
import { enqueueSnackbar } from 'notistack';
import copy from 'copy-to-clipboard';

import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { ECertificateType } from '../../enums/saml/ECertificateType';
import { CertificateProps } from './types';
import { ELanguageCode } from '../../api/Api';
import { slovakTimeRelativeViewHumanizationConfig } from '../../utils/WordInflection';

export const Certificate: React.FC<CertificateProps> = ({
	title,
	subject,
	issuer,
	algorithm,
	fingerprint,
	expiration,
	type,
	content,
}) => {
	const [isLoading, setIsLoading] = React.useState(false);
	const { i18n } = useTranslation();
	const { language } = i18n;
	const { t } = useTranslation();
	const [certificateContent, setCertificateContent] = React.useState('');

	const api = useSwaggerApi();

	const isValid = (expiration: Date): boolean => {
		const now = new Date();
		const expiresIn = expiration.getTime() - now.getTime();

		const isValid = expiresIn > 0;

		return isValid;
	};

	const getHumanizedExpiration = (expiration: Date, language: string): string => {
		const now = new Date();
		const expirationDate = expiration.toLocaleString(language);
		const expiresIn = expiration.getTime() - now.getTime();
		const isValid = expiresIn > 0;

		if (language === ELanguageCode.Sk) {
			humanizeDuration.languages.sk = slovakTimeRelativeViewHumanizationConfig;
		}

		const humanized = humanizeDuration(expiresIn, {
			language,
			fallbacks: ['en'],
			round: true,
			largest: 2,
			units: ['y', 'mo', 'd', 'h', 'm', 's'],
			conjunction: t('component.certificate.conjunction'),
			serialComma: false,
		});

		const result = `${expirationDate} (${isValid ? t('component.certificate.validFor') : t('component.certificate.expiredFor')} ${humanized})`;

		return result;
	};

	const downloadCertificate = async () => {
		try {
			setIsLoading(true);

			let content: string;
			let filename: string;

			// if (type === ECertificateType.SIGNIN) {
			// 	content = (await api.saml.downloadSigningCertificate({ format: 'blob' })).data;
			// 	filename = 'excalibur-saml-idp-signing-certificate.crt';
			// } else if (type === ECertificateType.ENCRYPTION) {
			// 	content = (await api.saml.downloadEncryptionCertificate({ format: 'blob' })).data;
			// 	filename = 'excalibur-saml-idp-encryption-certificate.crt';
			// } else
			if (type === ECertificateType.DEPLOYMENT) {
				content = (await api.settings.downloadDeploymentCertificate({ format: 'blob' })).data;
				filename = 'excalibur-deployment-certificate.crt';
			} else {
				enqueueSnackbar(t('component.certificate.errorMessages.invalidType'), {
					variant: 'error',
					persist: false,
				});

				return;
			}

			enqueueSnackbar(t('component.certificate.errorMessages.invalidType'), {
				variant: 'error',
				persist: false,
			});

			return;

			const url = window.URL.createObjectURL(new Blob([content], { type: 'application/x-pem-file' }));
			const link = document.createElement('a');
			link.href = url;
			link.setAttribute('download', filename);
			link.click();
			window.URL.revokeObjectURL(url); // release to prevent memory leaks
		} catch (error: unknown) {
			console.error(error);
		} finally {
			setIsLoading(false);
		}
	};

	React.useEffect(() => {
		setCertificateContent(content);
	}, [content]);

	const copyCertificateContent = () => {
		copy(certificateContent);
		enqueueSnackbar(t('component.certificate.actionMessages.copiedToClipboard'));
	};

	return (
		<Card>
			<CardContent>
				<Stack
					direction='row'
					spacing={1}
					sx={{
						display: 'flex',
						justifyContent: 'space-between',
					}}
				>
					<Typography
						variant='h6'
						sx={{
							paddingBottom: 1,
						}}
					>
						{title}
					</Typography>

					{isValid(expiration) ?
						<Chip label={t('component.certificate.body.valid')} color='success' variant='outlined' />
					:	<Chip label={t('component.certificate.body.invalid')} color='error' variant='outlined' />}
				</Stack>

				<Stack direction='row' spacing={1}>
					<Typography color='text.primary'>{`${t('component.certificate.body.fingerprint')}:`}</Typography>
					<Typography color='text.secondary'>{fingerprint.slice(0, 65)}</Typography>
				</Stack>

				<Stack direction='row' spacing={1}>
					<Typography color='text.primary'>{`${t('component.certificate.body.subject')}:`}</Typography>
					<Typography color='text.secondary'>{subject}</Typography>
				</Stack>

				<Stack direction='row' spacing={1}>
					<Typography color='text.primary'>{`${t('component.certificate.body.issuer')}:`}</Typography>
					<Typography color='text.secondary'>{issuer}</Typography>
				</Stack>

				<Stack direction='row' spacing={1}>
					<Typography color='text.primary'>{`${t('component.certificate.body.algorithm')}:`}</Typography>
					<Typography color='text.secondary'>{algorithm}</Typography>
				</Stack>

				<Stack direction='row' spacing={1}>
					<Typography color='text.primary'>{`${t('component.certificate.body.expiration')}:`}</Typography>
					<Typography color='text.secondary'>{getHumanizedExpiration(expiration, language)}</Typography>
				</Stack>
			</CardContent>
			<CardActions
				sx={{
					justifyContent: 'flex-end',
				}}
			>
				<Button size='small' color='primary' startIcon={<ContentCopyIcon />} onClick={copyCertificateContent}>
					{t('component.certificate.buttons.copy')}
				</Button>

				<LoadingButton
					size='small'
					color='primary'
					loading={isLoading}
					loadingPosition='start'
					startIcon={<FileDownloadIcon />}
					onClick={downloadCertificate}
				>
					{t('component.certificate.buttons.download')}
				</LoadingButton>
			</CardActions>
		</Card>
	);
};
