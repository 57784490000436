import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { Box, IconButton, Tooltip } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMutation, useQuery } from '@tanstack/react-query';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_TableInstance as MRTTableInstance,
	MaterialReactTable,
} from 'material-react-table';
import { enqueueSnackbar } from 'notistack';

import { useTableQuery } from '../../../hooks/useTableQuery';
import { PartialOAuthClientResponseDto, WebhookModel } from '../../../api/Api';
import { formatDateTime } from '../../../utils/DateTime';
import { Link } from '../../../components/Link/Link';
import { useACL } from '../../../hooks/useACL';
import { EPermission } from '../../../enums/permission/EPermission';
import { ConfirmationDialog } from '../../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { EQueryKey } from '../../../enums/reactQuery/EQueryKey';
import { useMRTLocalization } from '../../../hooks/useTableLocalization';
import { useMRTDateAdapterLocale } from '../../../hooks/useMRTDateAdapterLocale';
import { webhookSchema } from './WebhookForm/schemas';

export const WebhookList: React.FC<Pick<PartialOAuthClientResponseDto, 'clientID'>> = ({ clientID }) => {
	const [selectedWebhooksIDs, setSelectedWebhooksIDs] = useState<Array<WebhookModel['id']>>([]);
	const [deleteModalOpen, setDeleteModalOpen] = useState(false);

	const { t } = useTranslation();
	const { isAllowed } = useACL();

	const { rowSelection, setRowSelection } = useTableQuery([]);

	const closeDeleteModal = () => {
		setDeleteModalOpen(false);
		setSelectedWebhooksIDs([]);
	};

	const openDeleteModal = () => {
		setDeleteModalOpen(true);
	};

	const columns = useMemo<Array<MRTColumnDef<WebhookModel>>>(
		() => [
			{
				accessorKey: 'url',
				header: t('page.oAuthClients.webhooks.list.header.url'),
			},
			{
				accessorKey: 'deliveryFailure',
				header: t('page.oAuthClients.webhooks.list.header.deliveryStatus'),
				accessorFn: ({ deliveryFailure }) => {
					return deliveryFailure ?
							t('page.oAuthClients.webhooks.list.deliveryStatus.failed')
						:	t('page.oAuthClients.webhooks.list.deliveryStatus.success');
				},
			},
			{
				accessorKey: 'createdAt',
				header: t('page.oAuthClients.webhooks.list.header.createdAt'),
				filterVariant: 'datetime-range',
				muiFilterDateTimePickerProps: {
					slotProps: {
						textField: {
							sx: { zIndex: 1 },
						},
					},
				},
				accessorFn: (row) => new Date(row.createdAt),
				Cell: ({ row }) => formatDateTime(row.original.createdAt, true),
			},
		],
		[t],
	);

	const updateRowSelection = useCallback(
		(deletedIds: Array<number>) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const api = useSwaggerApi();

	const {
		data: webhooksResponse,
		isLoading: isLoadingWebhooks,
		isRefetching: isRefetchingWebhooks,
		error: webhooksError,
		refetch: refetchWebhooks,
	} = useQuery({
		queryKey: [EQueryKey.OAUTH_WEBHOOK_LIST_QUERY, clientID],
		queryFn: async () => {
			try {
				const { data } = await api.oauthClient.getWebhooksByOauthClient(clientID as string);

				return data;
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);

				return { webhooks: [] };
			}
		},
		select: (data) => {
			data.webhooks.forEach((webhook) => {
				webhookSchema.parse(webhook);
			});

			return data;
		},
	});

	const { mutateAsync } = useMutation({
		mutationFn: async ({ webhookID }: { webhookID: WebhookModel['id'] }) => {
			await api.oauthClient.deleteWebhook(webhookID);
		},
		onSuccess: () => {
			refetchWebhooks();
		},
	});

	const onDeleteConfirm = useCallback(async () => {
		const promises: Array<Promise<any>> = [];

		selectedWebhooksIDs.forEach((webhookID) => {
			promises.push(
				mutateAsync(
					{ webhookID },
					{
						onSuccess: () => {
							enqueueSnackbar(t('page.oAuthClients.webhooks.list.actionMessages.webhookDeleted'), {
								variant: 'success',
							});
						},
					},
				),
			);
		});

		await Promise.all(promises);
		updateRowSelection(selectedWebhooksIDs);
		closeDeleteModal();
	}, [selectedWebhooksIDs, t, mutateAsync, updateRowSelection]);

	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const selectMultipleWebhooks = (table: MRTTableInstance<WebhookModel>) => {
		const selectedRowsOnActivePageIds = table.getSelectedRowModel().rows.map((row) => row.original.id);
		setSelectedWebhooksIDs(selectedRowsOnActivePageIds);
	};

	return (
		<Box>
			<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
				<MaterialReactTable
					columns={columns}
					data={webhooksResponse?.webhooks ?? []}
					enableStickyHeader={false}
					state={{
						rowSelection,
						isLoading: isLoadingWebhooks,
						showAlertBanner: webhooksError !== null,
						showProgressBars: isRefetchingWebhooks,
					}}
					muiToolbarAlertBannerProps={{
						color: 'error',
						children: <>{webhooksError}</>,
					}}
					enableRowActions
					enableRowSelection
					onRowSelectionChange={setRowSelection}
					getRowId={(originalRow) => originalRow.id?.toString() || ''}
					initialState={{ density: 'compact' }}
					renderRowActions={({ row }) => (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '1rem',
							}}
						>
							{isAllowed([EPermission.OAUTH_CLIENTS_UPDATE]) && (
								<Tooltip
									title={t('page.oAuthClients.webhooks.list.tooltips.edit')}
									placement='left'
									enterDelay={500}
									arrow
								>
									<span>
										<Link to={`/security/oauth/${clientID}/edit/${row.original.id}`}>
											<IconButton>
												<EditIcon />
											</IconButton>
										</Link>
									</span>
								</Tooltip>
							)}
							{isAllowed([EPermission.OAUTH_CLIENTS_UPDATE]) && (
								<Tooltip
									title={t('page.oAuthClients.webhooks.list.tooltips.delete')}
									placement='right'
									enterDelay={500}
									arrow
								>
									<IconButton
										color='error'
										onClick={() => {
											setSelectedWebhooksIDs([row.original.id]);
											openDeleteModal();
										}}
									>
										<DeleteIcon />
									</IconButton>
								</Tooltip>
							)}
						</Box>
					)}
					renderToolbarInternalActions={({ table }) => (
						<Box>
							<MRTToggleFiltersButton table={table} />
							<MRTShowHideColumnsButton table={table} />
							{isAllowed([EPermission.OAUTH_CLIENTS_DELETE]) && (
								<Tooltip
									title={t('page.oAuthClients.webhooks.list.tooltips.deleteSelected')}
									enterDelay={500}
								>
									<span>
										<IconButton
											color='error'
											disabled={table.getSelectedRowModel().rows.length === 0}
											onClick={() => {
												selectMultipleWebhooks(table);
												openDeleteModal();
											}}
										>
											<DeleteIcon />
										</IconButton>
									</span>
								</Tooltip>
							)}
							<MRTToggleDensePaddingButton table={table} />
							<MRTFullScreenToggleButton table={table} />
						</Box>
					)}
					displayColumnDefOptions={{
						'mrt-row-actions': {
							header: t('page.oAuthClients.webhooks.list.header.actions'),
						},
						'mrt-row-select': {
							enableHiding: true,
							visibleInShowHideMenu: false,
						},
					}}
					muiTablePaperProps={({ table }) => ({
						style: {
							zIndex: table.getState().isFullScreen ? 1250 : undefined,
							boxShadow: 'none',
							outline: '1px solid #e0e0e0',
						},
					})}
					muiSelectCheckboxProps={() => ({
						sx: {
							width: '50px',
							height: '50px',
						},
					})}
					muiSelectAllCheckboxProps={() => ({
						sx: {
							width: '50px',
							height: '50px',
						},
					})}
					muiTableHeadCellProps={() => ({
						sx: {
							verticalAlign: 'baseline',
						},
					})}
					localization={MRTLocalization}
					positionActionsColumn='last'
				/>
			</LocalizationProvider>

			{isAllowed([EPermission.OAUTH_CLIENTS_DELETE, EPermission.OAUTH_CLIENTS_UPDATE], false) && (
				<ConfirmationDialog
					onClose={closeDeleteModal}
					open={deleteModalOpen}
					onConfirm={onDeleteConfirm}
					title={
						selectedWebhooksIDs.length > 1 ?
							t('page.oAuthClients.webhooks.list.confirmation.delete.titleMultipleIds')
						:	t('page.oAuthClients.webhooks.list.confirmation.delete.title')
					}
					text={
						selectedWebhooksIDs.length > 1 ?
							t('page.oAuthClients.webhooks.list.confirmation.delete.textMultipleIds')
						:	t('page.oAuthClients.webhooks.list.confirmation.delete.text')
					}
					cancelText={t('page.oAuthClients.webhooks.list.confirmation.delete.cancel')}
					confirmText={t('page.oAuthClients.webhooks.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
