import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton,
	type MRT_ColumnDef,
	MRT_ToggleFullScreenButton,
	MRT_ToggleFiltersButton,
	MRT_ShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton,
	MaterialReactTable,
	MRT_TableInstance,
} from 'material-react-table';
import { Typography, Tooltip, Box, Chip, Stack, IconButton, Paper } from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
	AccessTimeFilled as AccessTimeFilledIcon,
	ContentCopy as ContentCopyIcon,
	ForwardToInboxOutlined as ForwardToInboxOutlinedIcon,
	Delete as DeleteIcon,
	GroupAdd as GroupAddIcon,
	SaveAlt as SaveAltIcon,
} from '@mui/icons-material';
import { Duration, formatDuration, intervalToDuration, isBefore } from 'date-fns';
import { enUS as en, sk } from 'date-fns/locale';
import copy from 'copy-to-clipboard';
import { enqueueSnackbar } from 'notistack';

import { Link } from '../../components/Link/Link';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { CreateInvitationResponseDto, EInvitationStatus, PartialDetailedInvitationDto } from '../../api/Api';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { useFormatDate } from '../../hooks/useFormatDate';
import { useTableQuery } from '../../hooks/useTableQuery';
import { FETCH_INTERVAL } from './constants';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import InviteUserDialog from '../../components/InviteUserDialog/InviteUserDialog';
import { UserGroupsIDs } from '../../components/InviteUserDialog/types';
import { useCreateInvitations } from '../../hooks/query/users/useCreateInvitations';
import { useFetchDetailedInvitations } from '../../hooks/query/users/useFetchDetailedInvitations';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';
import { groupBy } from '../../utils/ArrayHelpers';
import { RenderChip } from '../../components/RenderChip/RenderChip';
import { useCurrentLanguage } from '../../contexts/LocalizationContext/LocalizationContext';

export const InvitationsPage: React.FC = (): JSX.Element => {
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const api = useSwaggerApi();
	const formatDate = useFormatDate();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();
	const currentLanguage = useCurrentLanguage();

	const locale = React.useMemo(() => {
		const locales = {
			en: en,
			sk: sk,
		};

		return locales[currentLanguage] || en;
	}, [currentLanguage]);

	const [shouldRefetch, setShouldRefetch] = React.useState<boolean>(false);
	const [reinviteModalOpen, setReinviteModalOpen] = React.useState(false);
	const [deleteModalOpen, setDeleteModalOpen] = React.useState(false);
	const [selectedIDs, setSelectedIDs] = React.useState<Array<number>>([]);
	const {
		rowSelection,
		setRowSelection,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['email', 'createdAt']);

	const { data, isRefetching, isLoading, error, refetch } = useFetchDetailedInvitations(
		{
			query: {
				limit: swaggerQuery.limit,
				offset: swaggerQuery.offset,
				filter: swaggerQuery.filter,
				sort: swaggerQuery.sort,
				columns: swaggerQuery.columns,
			},
		},
		{
			refetchInterval: shouldRefetch ? FETCH_INTERVAL : undefined,
		},
	);

	const { entities = [], total = 0 } = data ? data : {};

	React.useEffect(() => {
		if (entities.length === 0) {
			setShouldRefetch(false);
		} else if (entities.length > 0) {
			const pendingInvitations = entities.filter((entity) => entity.status === 'pending');
			if (pendingInvitations.length > 0) {
				setShouldRefetch(true);
			} else {
				setShouldRefetch(false);
			}
		}
	}, [entities]);

	const updateRowSelection = React.useCallback(
		(deletedIds: number[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const selectMultipleIDs = (table: MRT_TableInstance<PartialDetailedInvitationDto>) =>
		setSelectedIDs(
			table
				.getSelectedRowModel()
				.rows.filter((row) => !!row.original.id)
				.map((row) => row.original.id as number),
		);

	const openDeleteModal = () => setDeleteModalOpen(true);
	const closeDeleteModal = () => {
		setDeleteModalOpen(false);
		setSelectedIDs([]);
	};

	const onDeleteConfirm = React.useCallback(async () => {
		try {
			await api.users.deleteInvitations({ ids: selectedIDs });
			refetch();
		} catch (error) {
			// eslint-disable-next-line no-console
			console.error(error);
		}

		updateRowSelection(selectedIDs);
		closeDeleteModal();
	}, [selectedIDs, updateRowSelection]);

	const openReinviteModal = () => setReinviteModalOpen(true);
	const closeReinviteModal = () => {
		setReinviteModalOpen(false);
		setSelectedIDs([]);
	};

	const { mutateAsync } = useCreateInvitations();

	const onReinviteConfirm = React.useCallback(
		async (userGroupsIDs: UserGroupsIDs) => {
			const entitiesToReinvite = entities
				.filter((entity) => !!entity.id)
				.filter((entity) => !!entity.identityStoreID)
				.filter((entity) => selectedIDs.includes(entity.id as number));

			const groupedByIdentityStoreID = groupBy(
				entitiesToReinvite,
				({ identityStoreID }) => identityStoreID as number,
			);

			const promises: Array<Promise<CreateInvitationResponseDto>> = [];

			groupedByIdentityStoreID.forEach((identityStoreEntities, identityStoreID) => {
				if (!identityStoreEntities || identityStoreEntities.length === 0) {
					return;
				}

				const uuids = identityStoreEntities
					.filter((entity) => !!entity.uuid)
					.map((entity) => entity.uuid as string);

				promises.push(
					mutateAsync(
						{
							data: {
								identityStoreID,
								userGroupsIDs,
								uuids,
							},
							params: undefined,
						},
						{
							onError: (error) => {
								// eslint-disable-next-line no-console
								console.error(error);
							},
						},
					),
				);
			});

			await Promise.all(promises);
			refetch();

			updateRowSelection(selectedIDs);
			closeReinviteModal();
		},
		[selectedIDs, entities, updateRowSelection, mutateAsync],
	);

	const handleOnCopy = React.useCallback(
		(invitationID: number) => async (event: React.MouseEvent) => {
			event.stopPropagation();

			try {
				const response = await api.users.getInvitationLink(invitationID);

				copy(response.data.link);
				enqueueSnackbar(t('page.invitations.actionMessages.invitationLinkCopied'), {
					variant: 'success',
				});
			} catch (error) {
				console.error(error);
			}
		},
		[],
	);

	const columns = React.useMemo<MRT_ColumnDef<PartialDetailedInvitationDto>[]>(
		() => [
			{
				accessorKey: 'email',
				header: t('page.invitations.table.header.email'),
			},
			{
				accessorFn: (row) => `${formatDate(row.createdAt, true)}`,
				accessorKey: 'createdAt',
				filterVariant: 'datetime-range',
				muiFilterDateTimePickerProps: {
					slotProps: {
						textField: {
							sx: { zIndex: 1 },
						},
					},
				},
				header: t('page.invitations.table.header.createdAt'),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Typography>{renderedCellValue}</Typography>
					</Box>
				),
			},
			{
				accessorKey: 'expiresAt',
				filterVariant: 'datetime-range',
				header: t('page.invitations.table.header.expiresIn'),
				Cell: ({
					row: {
						original: { expiresAt },
					},
				}) => {
					if (!expiresAt) {
						return null;
					}

					const now = new Date();
					const expired = isBefore(new Date(expiresAt), now);

					return (
						<Box
							sx={{
								display: 'flex',
								alignItems: 'center',
								gap: '1rem',
							}}
						>
							<Tooltip arrow placement='top' title={formatDate(expiresAt, true)} enterDelay={500}>
								<AccessTimeFilledIcon fontSize='small' />
							</Tooltip>
							<Typography>
								{expired ?
									t('page.invitations.table.expired')
								:	formatDuration(
										intervalToDuration({
											start: now,
											end: new Date(expiresAt),
										}),
										{
											delimiter: ', ',
											format: ['hours', 'minutes'],
											locale: locale,
										},
									)
								}
							</Typography>
						</Box>
					);
				},
			},
			{
				accessorKey: 'status',
				header: t('page.invitations.table.header.status'),
				filterVariant: 'select',
				filterSelectOptions: Object.values(EInvitationStatus).map((status) => ({
					label: t(`page.invitations.table.invitationStatus.emailPerspective.${status}`),
					value: status,
				})),
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						<Stack direction='row' spacing={1}>
							<RenderChip value={renderedCellValue} perspective='emailPerspective' />
						</Stack>
					</Box>
				),
			},
		],
		[],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.invitations.title')}
						description={t('page.invitations.description')}
						icon={GroupAddIcon}
					/>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
						<MaterialReactTable
							columns={columns}
							data={entities}
							rowCount={total}
							enableRowActions
							enableStickyHeader={false}
							state={{
								isLoading: isLoading,
								showAlertBanner: error !== null,
								pagination,
								showProgressBars: isRefetching,
								rowSelection,
								globalFilter,
								columnFilters,
								sorting,
								columnVisibility,
							}}
							manualPagination
							manualFiltering
							manualSorting
							onSortingChange={setSorting}
							onColumnVisibilityChange={setColumnVisibility}
							onColumnFiltersChange={setColumnFilters}
							onPaginationChange={setPagination}
							onGlobalFilterChange={setGlobalFilter}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							enableRowSelection={isAllowed(
								[EPermission.INVITATIONS_DELETE, EPermission.INVITATIONS_CREATE],
								false,
							)}
							onRowSelectionChange={setRowSelection}
							muiTopToolbarProps={{
								sx: { overflow: 'visible' },
							}}
							getRowId={(originalRow) => originalRow.id?.toString() || ''}
							renderRowActions={({ row, table }) => (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: '1rem',
									}}
								>
									{isAllowed([EPermission.INVITATIONS_READ]) && (
										<Tooltip
											title={t('page.invitations.tooltips.copyLink')}
											placement='bottom'
											enterDelay={500}
											arrow
										>
											<span>
												<IconButton onClick={handleOnCopy(row.original.id as number)}>
													<ContentCopyIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
									{isAllowed([EPermission.INVITATIONS_CREATE]) && (
										<Tooltip
											title={t('page.invitations.tooltips.reinvite')}
											placement='left'
											enterDelay={500}
											arrow
										>
											<span>
												<IconButton
													disabled={table.getSelectedRowModel().rows.length > 0}
													onClick={() => {
														setSelectedIDs([row.original.id as number]);
														openReinviteModal();
													}}
												>
													<ForwardToInboxOutlinedIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
									{isAllowed([EPermission.INVITATIONS_DELETE]) && (
										<Tooltip
											title={t('page.invitations.tooltips.deleteInvitation')}
											placement='right'
											enterDelay={500}
											arrow
										>
											<span>
												<IconButton
													color='error'
													disabled={table.getSelectedRowModel().rows.length > 0}
													onClick={() => {
														setSelectedIDs([row.original.id as number]);
														openDeleteModal();
													}}
												>
													<DeleteIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
								</Box>
							)}
							renderToolbarInternalActions={({ table }) => {
								return (
									<Box sx={{ display: 'flex', alignItems: 'center', zIndex: 99900 }}>
										<MRT_ToggleGlobalFilterButton table={table} />
										<MRT_ToggleFiltersButton table={table} />
										<MRT_ShowHideColumnsButton table={table} />
										{/* <Tooltip title={t('page.invitations.tooltips.export')} enterDelay={500}>
											<span>
												<IconButton>
													<SaveAltIcon />
												</IconButton>
											</span>
										</Tooltip> */}
										{isAllowed([EPermission.INVITATIONS_CREATE]) && (
											<Tooltip
												title={t('page.invitations.tooltips.reinviteMultiple')}
												enterDelay={500}
											>
												<span>
													<IconButton
														color='primary'
														disabled={table.getSelectedRowModel().rows.length === 0}
														onClick={() => {
															selectMultipleIDs(table);
															openReinviteModal();
														}}
													>
														<ForwardToInboxOutlinedIcon />
													</IconButton>
												</span>
											</Tooltip>
										)}
										{isAllowed([EPermission.INVITATIONS_DELETE]) && (
											<Tooltip
												title={t('page.invitations.tooltips.deleteMultiple')}
												enterDelay={500}
											>
												<span>
													<IconButton
														color='error'
														disabled={table.getSelectedRowModel().rows.length === 0}
														onClick={() => {
															selectMultipleIDs(table);
															openDeleteModal();
														}}
													>
														<DeleteIcon />
													</IconButton>
												</span>
											</Tooltip>
										)}
										<MRT_ToggleDensePaddingButton table={table} />
										<MRT_ToggleFullScreenButton table={table} />
									</Box>
								);
							}}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									header: t('page.invitations.table.header.actions'),
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTablePaperProps={({ table }) => ({
								style: {
									overflow: 'visible',
									zIndex: table.getState().isFullScreen ? 1250 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiSelectCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiSelectAllCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									verticalAlign: 'baseline',
								},
							})}
							localization={MRTLocalization}
							positionActionsColumn='last'
						/>
					</LocalizationProvider>
				</Stack>
			</Paper>

			{isAllowed([EPermission.INVITATIONS_DELETE]) && (
				<ConfirmationDialog
					open={deleteModalOpen}
					cancelText='Cancel'
					confirmText={
						selectedIDs.length > 1 ?
							t('page.invitations.modalTexts.deleteMultipleConfirmText')
						:	t('page.invitations.modalTexts.deleteSingleConfirmText')
					}
					text={
						selectedIDs.length > 1 ?
							t('page.invitations.modalTexts.deleteMultipleText')
						:	t('page.invitations.modalTexts.deleteSingleText')
					}
					title={
						selectedIDs.length > 1 ?
							t('page.invitations.modalTexts.deleteMultipleTitle')
						:	t('page.invitations.modalTexts.deleteSingleTitle')
					}
					onClose={closeDeleteModal}
					onConfirm={onDeleteConfirm}
				/>
			)}
			{isAllowed([EPermission.INVITATIONS_CREATE, EPermission.INVITATIONS_UPDATE], false) && (
				<InviteUserDialog
					open={reinviteModalOpen}
					confirmText={
						selectedIDs.length > 1 ?
							t('page.invitations.modalTexts.reinviteMultipleConfirmText')
						:	t('page.invitations.modalTexts.reinviteSingleConfirmText')
					}
					text={
						selectedIDs.length > 1 ?
							t('page.invitations.modalTexts.reinviteMultipleText')
						:	t('page.invitations.modalTexts.reinviteSingleText')
					}
					title={
						selectedIDs.length > 1 ?
							t('page.invitations.modalTexts.reinviteMultipleTitle')
						:	t('page.invitations.modalTexts.reinviteSingleTitle')
					}
					onClose={closeReinviteModal}
					onConfirm={onReinviteConfirm}
				/>
			)}
			{isAllowed([EPermission.INVITATIONS_CREATE]) && (
				<Link to='/users/invitations/new'>
					<FloatingButtonAdd
						ariaLabel={t('page.invitations.ariaLabel.addInvitation')}
						tooltipTitle={t('page.invitations.tooltips.addInvitation')}
					/>
				</Link>
			)}
		</Box>
	);
};
