import { useMemo, useState } from 'react';
import { TabContext, TabPanel } from '@mui/lab';
import { Box, Paper, Tab, Tabs, useTheme } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';

import { AuditLogsSection } from './AuditLogsSection';
import { GeneralSection } from './GeneralSection';
import { Preloader } from '../../components/Preloader/Preloader';
import { SEO } from '../../components/SEO/SEO';
import { EPAMDetailSections } from './enums';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { isAuditData } from './helpers';
import { useQuery } from '@tanstack/react-query';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';

export const PAMDetail: React.FC = () => {
	const [currentSection, setCurrentSection] = useState<EPAMDetailSections>(EPAMDetailSections.GENERAL);

	const location = useLocation();

	const auditDateTime = useMemo(() => {
		if (location.state?.auditDateTime) {
			return location.state.auditDateTime;
		}

		return null;
	}, [location.state?.auditDateTime]);

	const { t } = useTranslation();

	const theme = useTheme();

	const { id } = useParams();
	const targetID = id ? Number(id) : null;

	const api = useSwaggerApi();

	const { data: target, isLoading: isLoadingTarget } = useQuery({
		queryKey: [auditDateTime ? EQueryKey.PAM_TARGET_AUDIT_QUERY : EQueryKey.PAM_TARGET_QUERY, id, auditDateTime],
		queryFn: async () => {
			try {
				if (auditDateTime === null) {
					const { data } = await api.pam.getTargetDetail(targetID as number);

					return data;
				}
				const data = { entities: [] };
				// const { data } = await api.pam.getPamTargetAuditCollection(targetID as number, {
				// 	filter: [`<=(modifiedAt,${auditDateTime})`],
				// 	sort: ['-modifiedAt'],
				// 	limit: 1,
				// 	offset: 0,
				// });

				if (data.entities.length === 0) {
					throw new Error(t('page.pam.detail.errorMessages.noDataFound'));
				}

				return data.entities[0];
			} catch (error) {
				// eslint-disable-next-line no-console
				console.error(error);
			}
		},
		enabled: !!targetID,
	});

	const auditData = useMemo(() => {
		if (!target) {
			return;
		}

		return isAuditData(target) ? target : undefined;
	}, [target]);

	const isAudit = useMemo(() => {
		// return auditDateTime !== null && !auditData?.isLatest;
		return false;
	}, [auditData, auditDateTime]);

	const targetType = useMemo(() => {
		if (!target) {
			return null;
		}

		return target.type;
	}, [target]);

	const groups = useMemo(() => {
		if (!target || isAuditData(target)) {
			return [];
		}

		// return target.groups;
		return [];
	}, [target]);

	const tabs = useMemo(
		() => [
			{
				value: EPAMDetailSections.GENERAL,
				label: t('page.pam.detail.sections.general.title'),
				disabled: false,
			},
			// {
			// 	value: EPAMDetailSections.AUDIT_LOGS,
			// 	label: t('page.pam.detail.sections.auditLogs.title'),
			// 	disabled: isAudit,
			// },
		],
		[isAudit, t],
	);

	return (
		<Box height='100%' marginBottom={currentSection === EPAMDetailSections.GENERAL ? 10 : 0}>
			<SEO title={t('page.pam.detail.title')} description={t('page.pam.detail.description')} />
			{isLoadingTarget && <Preloader />}
			{!isLoadingTarget && targetID && targetType && (
				<Paper elevation={3}>
					<TabContext value={currentSection}>
						<Box sx={{ borderBottom: 1, borderColor: theme.palette.divider }}>
							<Tabs
								value={currentSection}
								onChange={(_, newValue: EPAMDetailSections) => setCurrentSection(newValue)}
								TabIndicatorProps={{ style: { transition: 'none' } }}
							>
								{tabs.map((tab) => (
									<Tab key={tab.label} disabled={tab.disabled} label={tab.label} value={tab.value} />
								))}
							</Tabs>
						</Box>

						<TabPanel value={EPAMDetailSections.GENERAL}>
							<GeneralSection
								id={targetID}
								auditDateTime={auditDateTime}
								groups={groups}
								isAudit={isAudit}
								targetType={targetType}
								auditData={auditData}
							/>
						</TabPanel>
						{/* <TabPanel value={EPAMDetailSections.AUDIT_LOGS}>
							<AuditLogsSection id={targetID} />
						</TabPanel> */}
					</TabContext>
				</Paper>
			)}
		</Box>
	);
};
