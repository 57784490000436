import * as React from 'react';
import { Alert, Stack, Typography, Box, Grid } from '@mui/material';
import { DeviceCountCard } from '../../components/DeviceCountCard/DeviceCountCard';
import androidLogo from '../../assets/images/android-logo.png';
import appleLogo from '../../assets/images/apple-logo.png';
import windowsLogo from '../../assets/images/windows-logo.png';
import { DoughnutCard } from '../../components/DoughnutCard/DoughnutCard';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { StatisticsSkeleton } from './StatisticsSkeleton';
import * as semver from 'semver';

export const DeviceStatistics: React.FC = () => {
	const api = useSwaggerApi();

	const [windowsClients, setWindowsClients] = React.useState(0);
	const [totalWindowsClients, setTotalWindowsClients] = React.useState(0);

	const [macosClients, setMacosClients] = React.useState(0);
	const [totalMacosClients, setTotalMacosClients] = React.useState(0);

	const [totalAndroidTokens, setTotalAndroidTokens] = React.useState(0);
	const [androidTokens, setAndroidTokens] = React.useState(0);

	const [iosTokens, setIOSTokens] = React.useState(0);
	const [totalIOSTokens, setTotalIOSTokens] = React.useState(0);

	const [androidVersions, setAndroidVersions] = React.useState<Map<string, number>>(new Map());
	const [androidSystemVersions, setAndroidSystemVersions] = React.useState<Map<string, number>>(new Map());

	const [iosVersions, setIosVersions] = React.useState<Map<string, number>>(new Map());
	const [iosSystemVersions, setIosSystemVersions] = React.useState<Map<string, number>>(new Map());

	const [windowsVersions, setWindowsVersions] = React.useState<Map<string, number>>(new Map());
	const [windowsSystemVersions, setWindowsSystemVersions] = React.useState<Map<string, number>>(new Map());

	React.useEffect(() => {
		(() => {
			try {
				//TODO: Add client and token count based on platform
				// const [
				// 	clientVersions,
				// 	clientSystemVersions,
				// 	windowsClients,
				// 	macosClients,
				// 	tokenVersions,
				// 	tokenSystemVersions,
				// 	androidTokens,
				// 	iosTokens,
				// ] = await Promise.all([
				// 	api.clients.getClientApplicationVersions(),
				// 	api.clients.getSystemVersions(),
				// 	api.clients.getCount(),
				// 	api.clients.getCount(),
				// 	api.tokens.getTokenApplicationVersions(),
				// 	api.tokens.getTokenSystemVersions(),
				// 	api.tokens.getTokenCount(),
				// 	api.tokens.getTokenCount(),
				// ]);

				// Dummy data
				const clientVersions = { data: { windows: { '1.0.0': 10, '1.1.0': 5 } } };
				const clientSystemVersions = { data: { windows: { 'Windows 10': 8, 'Windows 11': 7 } } };
				const windowsClients = { data: { online: 5, total: 10 } };
				const macosClients = { data: { online: 3, total: 6 } };
				const tokenVersions = {
					data: {
						android: { '1.0.0': 15, '1.1.0': 10 },
						ios: { '1.0.0': 10, '1.1.0': 5 },
						windows: { '1.0.0': 8, '1.1.0': 4 },
					},
				};
				const tokenSystemVersions = {
					data: {
						android: { 'Android 10': 12, 'Android 11': 8 },
						ios: { 'iOS 14': 10, 'iOS 15': 5 },
						windows: { 'Windows 10': 6, 'Windows 11': 3 },
					},
				};
				const androidTokens = { data: { online: 7, total: 14 } };
				const iosTokens = { data: { online: 4, total: 8 } };

				setAndroidTokens(androidTokens.data.online);
				setTotalAndroidTokens(androidTokens.data.total);

				setIOSTokens(iosTokens.data.online);
				setTotalIOSTokens(iosTokens.data.total);

				setWindowsClients(windowsClients.data.online);
				setTotalWindowsClients(macosClients.data.total);

				setMacosClients(macosClients.data.online);
				setTotalMacosClients(windowsClients.data.total);

				const processApplicationVersions = (versions: Record<string, number>): Map<string, number> => {
					const entries = Object.entries(versions);

					entries.sort((a, b) => semver.compare(b[0], a[0]));

					return new Map<string, number>(entries);
				};

				const processSystemVersions = (versions: Record<string, number>): Map<string, number> => {
					const entries = Object.entries(versions);

					return new Map<string, number>(entries);
				};

				if (tokenVersions.data.android !== undefined) {
					setAndroidVersions(processApplicationVersions(tokenVersions.data.android));
				}
				if (tokenSystemVersions.data.android !== undefined) {
					setAndroidSystemVersions(processSystemVersions(tokenSystemVersions.data.android));
				}

				if (tokenVersions.data.ios !== undefined) {
					setIosVersions(processApplicationVersions(tokenVersions.data.ios));
				}

				if (tokenSystemVersions.data.ios !== undefined) {
					setIosSystemVersions(processSystemVersions(tokenSystemVersions.data.ios));
				}

				if (clientVersions.data.windows !== undefined) {
					setWindowsVersions(processApplicationVersions(clientVersions.data.windows));
				}

				if (clientSystemVersions.data.windows !== undefined) {
					setWindowsSystemVersions(processSystemVersions(clientSystemVersions.data.windows));
				}
			} catch (error: unknown) {
				console.error(error);
			}
		})();
	}, []);

	return (
		<Box>
			<Box
				sx={{
					paddingBottom: 1,
				}}
			>
				<Typography variant='h5'>Device statistics</Typography>
			</Box>

			<Box>
				<Grid container spacing={2}>
					<Grid item xs={12} sm={6} lg={3}>
						<DeviceCountCard
							image={windowsLogo}
							count={windowsClients}
							total={totalWindowsClients}
							title='Windows clients'
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<DeviceCountCard
							image={appleLogo}
							count={macosClients}
							total={totalMacosClients}
							title='MacOS clients'
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<DeviceCountCard
							image={androidLogo}
							count={androidTokens}
							total={totalAndroidTokens}
							title='Android tokens'
						/>
					</Grid>
					<Grid item xs={12} sm={6} lg={3}>
						<DeviceCountCard
							image={appleLogo}
							count={iosTokens}
							total={totalIOSTokens}
							title='iOS tokens'
						/>
					</Grid>
				</Grid>
			</Box>

			<Box marginTop={2}>
				<Grid container spacing={2}>
					<Grid item xs={12} md={6} lg={4} xl={3}>
						<DoughnutCard
							image={windowsLogo}
							label='# of clients'
							title='Windows client versions'
							data={windowsVersions}
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={4} xl={3}>
						<DoughnutCard
							image={windowsLogo}
							label='# of clients'
							title='Windows system versions'
							data={windowsSystemVersions}
						/>
					</Grid>

					<Grid item xs={12} md={6} lg={4} xl={3}>
						{androidVersions ?
							<DoughnutCard
								image={androidLogo}
								label='# of tokens'
								title='Android system versions'
								data={androidVersions}
							/>
						:	<StatisticsSkeleton listsToRender={5} />}
					</Grid>

					<Grid item xs={12} md={6} lg={4} xl={3}>
						{androidSystemVersions ?
							<DoughnutCard
								image={androidLogo}
								label='# of tokens'
								title='Android token versions'
								data={androidSystemVersions}
							/>
						:	<StatisticsSkeleton listsToRender={5} />}
					</Grid>

					<Grid item xs={12} md={6} lg={4} xl={3}>
						{iosVersions ?
							<DoughnutCard
								image={appleLogo}
								label='# of tokens'
								title='iOS system versions'
								data={iosVersions}
							/>
						:	<StatisticsSkeleton listsToRender={5} />}
					</Grid>

					<Grid item xs={12} md={6} lg={4} xl={3}>
						{iosSystemVersions ?
							<DoughnutCard
								image={appleLogo}
								label='# of tokens'
								title='iOS token versions'
								data={iosSystemVersions}
							/>
						:	<StatisticsSkeleton listsToRender={5} />}
					</Grid>
				</Grid>
			</Box>

			<Stack
				marginTop={2}
				spacing={1}
				sx={{
					width: '100%',
				}}
			>
				<Typography variant='h6'>TODO</Typography>
				<Alert severity='warning'>
					Make graph legend renders into custom container so chart size is preserved and not dependning on
					legend size
				</Alert>
				<Alert severity='warning'>Fetch graph data using API</Alert>
				<Alert severity='warning'>Add graph loader</Alert>
				<Alert severity='warning'>Create color palette for each graph</Alert>
			</Stack>
		</Box>
	);
};
