import * as React from 'react';
import { AxiosError } from 'axios';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { Paper, Box, Grid, Stack, FormGroup, InputAdornment } from '@mui/material';
import { PlaylistAdd as PlaylistAddIcon } from '@mui/icons-material';
import { useForm, SubmitHandler, useWatch } from 'react-hook-form';

import { zodResolver } from '@hookform/resolvers/zod';
import { useNavigate } from '../../../hooks/useNavigate';
import { getPAMSshTargetFormSchema } from './schemas';
import { CreatePAMSSHTargetRequestDto } from '../../../api/Api';
import { DEFAULT_UPLOAD_FILE_SIZE_LIMIT, PAM_SSH_TARGET_DEFAULT_PORT } from './constants';
import { Preloader } from '../../../components/Preloader/Preloader';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { useAuthContext } from '../../../contexts/AuthContext/AuthContext';
import { FormValues, PAMSshTargetState, SubmitFormState } from './types';
import { ConfirmDialogState } from '../types';
import { EAuthenticationType } from '../../../enums/pam/EAuthenticationType';
import { ConfirmationDialog } from '../../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { UpdatePAMSSHTargetRequestDto } from '../../../api/Api';
import { SwitchField } from '../../../components/FormFields/Switch/Switch';
import { usePreviousValue } from '../../../hooks/usePreviousValue';
import { Heading } from '../../../components/Heading/Heading';
import { TextField } from '../../../components/FormFields/TextField/TextField';
import { Textarea } from '../../../components/FormFields/Textarea/Textarea';
import { ToggleButtonGroup } from '../../../components/FormFields/ToggleButtonGroup/ToggleButtonGroup';
import { PasswordField } from '../../../components/FormFields/PasswordField/PasswordField';
import { FileUploader } from '../../../components/FormFields/FileUploader/FileUploader';
import { PageHeader } from '../../../components/PageHeader/PageHeader';
import { FloatingButtonSave } from '../../../components/Buttons/FloatingButton/FloatingButtonSave';
import { convertBytesToMB, convertMBtoBytes } from '../../../utils/FormatSize';
import { useACL } from '../../../hooks/useACL';
import { EPermission } from '../../../enums/permission/EPermission';
import { EFieldType } from '../../../components/FormFields/TextField/enums';
import { SectionHeader } from '../../../components/SectionHeader/SectionHeader';
import { generateVerificationErrors } from '../GenerateVerificationErrors';
import { PortField } from '../../../components/FormFields/PortField/PortField';
import { getDurationInString, getDurationInUnits } from '../../../utils/DateTime';
import { RECORDING_RETENTION_PERIOD_UNITS } from '../constants';

export const PAMAddSshTarget: React.FC = (): JSX.Element => {
	const authContext = useAuthContext();
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const { id } = useParams();
	const navigate = useNavigate();

	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = React.useState(false);
	const [confirmDialogText, setConfirmDialogText] = React.useState<ConfirmDialogState>({
		text: '',
		title: '',
	});
	const [isConfirmAnyway, setIsConfirmAnyway] = React.useState(false);
	const [shouldDisableForm, setShouldDisableForm] = React.useState<boolean>(false);
	const [uploadedPrivateKey, setUploadedPrivateKey] = React.useState<string | null>(null);
	const [authenticationType, setAuthenticationType] = React.useState<EAuthenticationType>(
		EAuthenticationType.PASSWORD,
	);
	const previousIsConfirmAnyway = usePreviousValue(isConfirmAnyway);

	const [submitFormState, setSubmitFormState] = React.useState<SubmitFormState>({
		submitting: false,
		submitted: false,
		error: null,
	});

	const [pamSshTargetState, setPamSshTargetState] = React.useState<PAMSshTargetState>({
		loading: false,
		loaded: false,
		data: null,
		error: null,
	});

	const buttonGroupOptions = React.useMemo(() => {
		return [
			{
				label: t('page.pam.edit.all.body.password'),
				value: EAuthenticationType.PASSWORD,
			},
			{
				label: t('page.pam.edit.all.body.privateKey'),
				value: EAuthenticationType.PRIVATEKEY,
			},
		];
	}, []);

	const {
		handleSubmit,
		register,
		control,
		trigger,
		reset,
		formState: { errors },
		getValues,
	} = useForm<FormValues>({
		mode: 'onChange',
		resolver: zodResolver(getPAMSshTargetFormSchema(t, authenticationType, !!id)),
		defaultValues: {
			port: PAM_SSH_TARGET_DEFAULT_PORT,
			recordingRetentionPeriod: '6 months',
			storeRecordingsPermanently: false,
			enableSessionRecording: true,
			enableFileDownload: false,
			enableFileUpload: false,
			uploadFileSizeLimit: DEFAULT_UPLOAD_FILE_SIZE_LIMIT,
			enableClipboardCopy: false,
			enableClipboardPaste: false,
			useExcaliburCredentials: false,
			authorizationRequired: false,
			unlimitedFileSize: false,
		},
	});
	const file = useWatch({ control: control, name: 'file', defaultValue: null });
	const storeRecordingsPermanently = useWatch({ control: control, name: 'storeRecordingsPermanently' });
	const enableSessionRecording = useWatch({ control: control, name: 'enableSessionRecording' });
	const enableFileUpload = useWatch({ control: control, name: 'enableFileUpload' });
	const useExcaliburCredentials = useWatch({ control: control, name: 'useExcaliburCredentials' });
	const unlimitedFileSize = useWatch({ control: control, name: 'unlimitedFileSize' });

	React.useEffect(() => {
		if (useExcaliburCredentials) {
			reset({ ...getValues(), username: '', password: '', privateKey: '', file: null, passphrase: '' });
		}
	}, [useExcaliburCredentials, getValues, reset]);

	React.useEffect(() => {
		const currentValues = getValues();

		if (authenticationType === EAuthenticationType.PASSWORD) {
			reset({ ...currentValues, privateKey: '', file: null, passphrase: '' });
		} else {
			reset({ ...currentValues, password: '' });
		}
	}, [authenticationType, getValues, reset]);

	const onSubmit = React.useCallback<SubmitHandler<FormValues>>(
		async (formData) => {
			const timeInMin =
				formData.enableSessionRecording ?
					getDurationInUnits(formData.recordingRetentionPeriod, RECORDING_RETENTION_PERIOD_UNITS)
				:	0;

			const requestBody: CreatePAMSSHTargetRequestDto = {
				useExcaliburCredentials: formData.useExcaliburCredentials,
				authorizationRequired: formData.authorizationRequired,
				name: formData.name,
				description: formData.description,
				hostname: formData.hostname,
				port: Number(formData.port),
				username: formData.username,
				password: authenticationType === EAuthenticationType.PASSWORD ? formData.password : undefined,
				privateKey: authenticationType === EAuthenticationType.PRIVATEKEY ? formData.privateKey : undefined,
				passphrase: authenticationType === EAuthenticationType.PRIVATEKEY ? formData.passphrase : undefined,
				recording: {
					retentionPeriod:
						formData.storeRecordingsPermanently ? null
						: timeInMin >= 1 ? timeInMin
						: null,
					enabled: formData.enableSessionRecording,
				},
				fileTransfer: {
					rootDirectory: formData.rootDirectory ?? '',
					uploadEnabled: formData.enableFileUpload,
					downloadEnabled: formData.enableFileDownload,
					uploadFileSizeLimit:
						unlimitedFileSize ? null
						: formData.uploadFileSizeLimit === undefined ? convertMBtoBytes(DEFAULT_UPLOAD_FILE_SIZE_LIMIT)
						: convertMBtoBytes(formData.uploadFileSizeLimit),
				},
				clipboard: {
					enablePaste: formData.enableClipboardPaste,
					enableCopy: formData.enableClipboardCopy,
				},
				additionalArguments: [],
			};

			try {
				setSubmitFormState({
					submitted: false,
					submitting: true,
					error: null,
				});

				if (!isConfirmAnyway) {
					const activeTenantID = authContext.userTenants.activeTenantID;
					const { data: verifyPamTargetResponse } = await api.pam.verifyPamTarget({
						networkAddress: formData.hostname,
						port: Number(formData.port),
						tenantID: activeTenantID,
						targetID: id ? Number(id) : null,
					});
					let nameExists = false;
					if (formData.name !== pamSshTargetState.data?.name) {
						const query = {
							limit: 1,
							filter: [`=(name,${formData.name})`],
						};

						const name = await api.pam.getTargetDetails(query);

						nameExists = name.data.entities.length > 0;
					}
					const errors = generateVerificationErrors(verifyPamTargetResponse, nameExists, t);

					if (errors.length > 0) {
						setIsConfirmDialogOpen(true);
						const combined = `${errors
							.map((error) => error.text)
							.join('\n')}\n${t('page.pam.confirmation.reusableText')}`;

						let title;

						if (errors.length > 1) {
							title = 'Multiple Errors Detected';
						} else {
							title = errors[0].title;
						}

						setConfirmDialogText({
							text: combined,
							title,
							combinedText: errors.length > 1 ? combined : undefined,
						});

						setSubmitFormState({
							submitted: false,
							submitting: false,
							error: null,
						});

						return;
					}
				}

				if (id) {
					const requestUpdateBody: UpdatePAMSSHTargetRequestDto = {
						useExcaliburCredentials: formData.useExcaliburCredentials,
						authorizationRequired: formData.authorizationRequired,
					};
					requestUpdateBody.fileTransfer = requestUpdateBody.fileTransfer || {};
					if (formData.name !== pamSshTargetState.data?.name) {
						requestUpdateBody.name = formData.name;
					}
					if (formData.description !== pamSshTargetState.data?.description) {
						requestUpdateBody.description = formData.description;
					}
					if (formData.hostname !== pamSshTargetState.data?.hostname) {
						requestUpdateBody.hostname = formData.hostname;
					}
					if (formData.port !== pamSshTargetState.data?.port.toString()) {
						requestUpdateBody.port = Number(formData.port);
					}
					if (formData.username !== pamSshTargetState.data?.username) {
						requestUpdateBody.username = formData.username;
					}
					if (formData.password) {
						requestUpdateBody.password = formData.password;
					}
					if (formData.privateKey) {
						requestUpdateBody.privateKey = formData.privateKey;
					}
					if (formData.passphrase) {
						requestUpdateBody.passphrase = formData.passphrase;
					}
					if (
						formData.enableSessionRecording !== pamSshTargetState.data?.recording.enabled ||
						formData.recordingRetentionPeriod !==
							pamSshTargetState.data?.recording.retentionPeriod?.toString()
					) {
						requestUpdateBody.recording = {
							enabled: formData.enableSessionRecording,
							retentionPeriod:
								formData.storeRecordingsPermanently ? null
								: timeInMin >= 1 ? timeInMin
								: null,
						};
					}
					if (formData.enableFileUpload !== pamSshTargetState.data?.fileTransfer.uploadEnabled) {
						requestUpdateBody.fileTransfer.uploadEnabled = formData.enableFileUpload;
					}

					if (formData.enableFileDownload !== pamSshTargetState.data?.fileTransfer.downloadEnabled) {
						requestUpdateBody.fileTransfer.downloadEnabled = formData.enableFileDownload;
					}
					if (requestUpdateBody.fileTransfer.uploadEnabled !== undefined) {
						requestUpdateBody.fileTransfer.rootDirectory = formData.rootDirectory ?? '';
					}
					if (formData.uploadFileSizeLimit !== undefined) {
						const uploadFileSizeLimit = convertMBtoBytes(formData.uploadFileSizeLimit);
						if (uploadFileSizeLimit !== pamSshTargetState.data?.fileTransfer.uploadFileSizeLimit) {
							requestUpdateBody.fileTransfer.uploadFileSizeLimit = uploadFileSizeLimit;
						}
					}
					if (unlimitedFileSize && pamSshTargetState.data?.fileTransfer.uploadFileSizeLimit !== null) {
						requestUpdateBody.fileTransfer.uploadFileSizeLimit = null;
					}

					if (!unlimitedFileSize && !formData.uploadFileSizeLimit) {
						requestUpdateBody.fileTransfer.uploadFileSizeLimit =
							convertMBtoBytes(DEFAULT_UPLOAD_FILE_SIZE_LIMIT);
					}
					if (formData.rootDirectory !== pamSshTargetState.data?.fileTransfer.rootDirectory) {
						requestUpdateBody.fileTransfer = {
							rootDirectory: formData.rootDirectory ?? '',
						};
					}
					if (formData.enableClipboardCopy !== pamSshTargetState.data?.clipboard.enableCopy) {
						requestUpdateBody.clipboard = {
							enableCopy: formData.enableClipboardCopy,
							enablePaste: formData.enableClipboardPaste,
						};
					}
					await api.pam.updateSshTarget(Number(id), requestUpdateBody);
					enqueueSnackbar(t('page.pam.edit.all.actionMessages.pamTargetSuccessfullyUpdated'), {
						variant: 'success',
						persist: false,
					});
					navigate(`/pam`);
				} else {
					await api.pam.createSshTarget(requestBody);
					navigate(`/pam`);
				}

				setSubmitFormState({
					submitted: true,
					submitting: false,
					error: null,
				});
			} catch (error: unknown) {
				console.error(error);
				setSubmitFormState({
					submitted: false,
					submitting: false,
					error: error as AxiosError,
				});
			}
		},
		[
			api.pam,
			enqueueSnackbar,
			setSubmitFormState,
			id,
			authenticationType,
			uploadedPrivateKey,
			pamSshTargetState,
			isConfirmDialogOpen,
			isConfirmAnyway,
			unlimitedFileSize,
		],
	);

	const retrySubmit = React.useCallback(() => {
		setIsConfirmDialogOpen(false);
		setIsConfirmAnyway(true);
	}, [isConfirmDialogOpen, isConfirmAnyway]);

	const closeConfirmDialog = React.useCallback(() => {
		setIsConfirmDialogOpen(false);
		setIsConfirmAnyway(false);
		setSubmitFormState({
			submitted: false,
			submitting: false,
			error: null,
		});
	}, []);

	React.useEffect(() => {
		if (!previousIsConfirmAnyway && isConfirmAnyway && !submitFormState.submitting) {
			onSubmit(getValues());
		}
	}, [isConfirmAnyway, submitFormState, previousIsConfirmAnyway]);

	const getPAMSshTarget = async (targetID: number) => {
		setPamSshTargetState({
			loading: true,
			loaded: false,
			data: null,
			error: null,
		});

		try {
			const response = await api.pam.getSshTarget(targetID);
			setPamSshTargetState({
				loading: false,
				loaded: true,
				data: response.data,
				error: null,
			});
			const fileSizeLimit = response.data.fileTransfer.uploadFileSizeLimit;

			reset({
				name: response.data.name,
				useExcaliburCredentials: response.data.useExcaliburCredentials,
				authorizationRequired: response.data.authorizationRequired,
				description: response.data.description,
				hostname: response.data.hostname,
				port: response.data.port.toString(),
				username: response.data.username,
				password: '',
				privateKey: '',
				passphrase: '',
				enableSessionRecording: response.data.recording.enabled,
				storeRecordingsPermanently:
					response.data.recording.enabled &&
					(response.data.recording.retentionPeriod === null || response.data.recording.retentionPeriod === 0),
				recordingRetentionPeriod:
					response.data.recording.retentionPeriod ?
						getDurationInString(response.data.recording.retentionPeriod, RECORDING_RETENTION_PERIOD_UNITS)
					:	'',
				enableFileUpload: response.data.fileTransfer.uploadEnabled,
				enableFileDownload: response.data.fileTransfer.downloadEnabled,
				uploadFileSizeLimit: fileSizeLimit ? convertBytesToMB(fileSizeLimit) : '',
				rootDirectory: response.data.fileTransfer.rootDirectory,
				enableClipboardCopy: response.data.clipboard.enableCopy,
				enableClipboardPaste: response.data.clipboard.enablePaste,
				unlimitedFileSize: fileSizeLimit === null,
			});
		} catch (error) {
			console.error(error);
			setPamSshTargetState({
				loading: false,
				loaded: false,
				data: null,
				error: error as AxiosError,
			});
		}
	};

	React.useEffect(() => {
		if (id && !pamSshTargetState.loading && !pamSshTargetState.loaded && !pamSshTargetState.error) {
			getPAMSshTarget(Number(id));
		}
	}, [id, pamSshTargetState]);

	React.useEffect(() => {
		if (shouldDisableForm && !submitFormState.submitting && !pamSshTargetState.loading) {
			setShouldDisableForm(false);
		} else if (!shouldDisableForm && (submitFormState.submitting || pamSshTargetState.loading)) {
			setShouldDisableForm(true);
		}
	}, [pamSshTargetState, submitFormState, shouldDisableForm]);

	React.useEffect(() => {
		const currentValues = getValues();
		if (uploadedPrivateKey) {
			reset({ ...currentValues, privateKey: uploadedPrivateKey });
		} else {
			reset({ ...currentValues, privateKey: '' });
		}
	}, [uploadedPrivateKey]);

	const handleChangeFile = React.useCallback(
		async (file: File): Promise<void> => {
			if (!file) {
				enqueueSnackbar(t('page.pam.edit.all.errorMessages.noSelectedFile'), {
					variant: 'error',
					persist: false,
				});

				return;
			}

			const isValid = await trigger('file');

			if (!isValid) {
				return;
			}

			const selectedFile = file;

			const reader = new FileReader();

			reader.onload = (event) => {
				const privateKey = event.target?.result;

				if (typeof privateKey !== 'string') {
					enqueueSnackbar(t('page.pam.edit.all.errorMessages.privateKeyFormat'), {
						variant: 'error',
						persist: false,
					});

					return;
				}

				setUploadedPrivateKey(privateKey);
			};

			reader.onerror = (error) => {
				enqueueSnackbar(error.toString(), {
					variant: 'error',
					persist: false,
				});
			};

			reader.readAsText(selectedFile);
		},
		[enqueueSnackbar, setUploadedPrivateKey],
	);

	const handleOnChangeAuthentication = (event: React.ChangeEvent<any>, value: EAuthenticationType) => {
		if (value) {
			setAuthenticationType(value);
		}
	};

	React.useEffect(() => {
		if (!file || !!errors.file) {
			return;
		}
		handleChangeFile(file);
	}, [file, errors.file]);

	return (
		<>
			<Box component={'form'} noValidate onSubmit={handleSubmit(onSubmit)}>
				{id && (pamSshTargetState.loading || !pamSshTargetState.loaded) ?
					<Preloader />
				:	<Paper elevation={3}>
						<Box sx={{ paddingTop: 2, paddingLeft: 2, paddingRight: 2 }}>
							<PageHeader
								title={id ? t('page.pam.edit.ssh.title') : t('page.pam.add.ssh.title')}
								description={
									id ? t('page.pam.edit.ssh.description') : t('page.pam.add.ssh.description')
								}
								icon={PlaylistAddIcon}
							/>
						</Box>
						<Grid container>
							<Grid item xs={12} md={6} order={{ xs: 1, md: 1 }}>
								<FormGroup
									sx={{
										padding: 2,
									}}
								>
									<Stack spacing={1}>
										<Heading label={t('page.pam.edit.all.subtitle.general')} />
										<Stack spacing={2}>
											<TextField
												name={'name'}
												register={register}
												label={t('page.pam.edit.all.form.name.label')}
												error={errors.name}
												disabled={shouldDisableForm}
												helperText={t('page.pam.edit.all.form.name.helperText')}
											/>
											<Textarea
												name={'description'}
												register={register}
												label={t('page.pam.edit.all.form.description.label')}
												error={errors.description}
												disabled={shouldDisableForm}
												helperText={t('page.pam.edit.all.form.description.helperText')}
												rows={4}
											/>
										</Stack>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6} order={{ xs: 2, md: 3 }}>
								<FormGroup sx={{ padding: 2 }}>
									<Stack spacing={1}>
										<Heading label={t('page.pam.edit.all.subtitle.network')} />
										<Stack spacing={2}>
											<TextField
												name={'hostname'}
												register={register}
												label={t('page.pam.edit.all.form.hostname.label')}
												error={errors.hostname}
												disabled={shouldDisableForm}
												helperText={t('page.pam.edit.all.form.hostname.helperText', {
													type: 'SSH',
												})}
											/>
											<PortField
												name={'port'}
												control={control}
												label={t('page.pam.edit.all.form.port.label')}
												disabled={shouldDisableForm}
												helperText={t('page.pam.edit.all.form.port.helperText.ssh')}
											/>
										</Stack>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6} order={{ xs: 3, md: 5 }}>
								<FormGroup
									sx={{
										padding: 2,
									}}
								>
									<Stack spacing={2}>
										<SectionHeader
											title={t('page.pam.edit.all.subtitle.authentication')}
											description={t('page.pam.edit.all.body.description.authentication')}
										/>
										<Stack spacing={2}>
											<SwitchField
												name='authorizationRequired'
												control={control}
												label={t('page.pam.edit.all.authorizationRequired.label')}
												helperText={t('page.pam.edit.all.authorizationRequired.helperText')}
											/>
											<SwitchField
												name='useExcaliburCredentials'
												control={control}
												label={t('page.pam.edit.all.useExcaliburCredentials.label')}
												helperText={t('page.pam.edit.all.useExcaliburCredentials.helperText')}
											/>
											<TextField
												name={'username'}
												register={register}
												label={t('page.pam.edit.all.form.username.label')}
												error={errors.username}
												disabled={shouldDisableForm || useExcaliburCredentials}
												helperText={t('page.pam.edit.all.form.username.helperText', {
													type: 'SSH',
												})}
												InputLabelProps={{ shrink: true }}
											/>
											<ToggleButtonGroup
												options={buttonGroupOptions}
												value={authenticationType}
												onChange={handleOnChangeAuthentication}
												disabled={shouldDisableForm || useExcaliburCredentials}
												aria-label={t('page.pam.edit.all.ariaLabel.authType')}
											/>
											{authenticationType === EAuthenticationType.PASSWORD && (
												<Grid item>
													<PasswordField
														name={'password'}
														register={register}
														label={t('page.pam.edit.all.form.password.label')}
														error={errors.password}
														disabled={shouldDisableForm || useExcaliburCredentials}
														helperText={t('page.pam.edit.all.form.password.helperText', {
															type: 'SSH',
														})}
													/>
												</Grid>
											)}
											{authenticationType === EAuthenticationType.PRIVATEKEY && (
												<>
													<Grid item>
														<Textarea
															name={'privateKey'}
															register={register}
															label={t('page.pam.edit.all.form.privateKey.label')}
															error={errors.privateKey}
															disabled={
																!!file || shouldDisableForm || useExcaliburCredentials
															}
															helperText={t(
																'page.pam.edit.all.form.privateKey.helperText',
															)}
															rows={6}
														/>
													</Grid>
													<Grid item>
														<FileUploader
															control={control}
															name={'file'}
															error={errors.file}
															disabled={shouldDisableForm || useExcaliburCredentials}
															helperText={t('page.pam.edit.all.form.file.label')}
															multiple={false}
														/>
													</Grid>
													<Grid item xs={12}>
														<PasswordField
															name={'passphrase'}
															register={register}
															label={t('page.pam.edit.all.form.passphrase.label')}
															error={errors.passphrase}
															disabled={shouldDisableForm || useExcaliburCredentials}
															helperText={t(
																'page.pam.edit.all.form.passphrase.helperText',
															)}
														/>
													</Grid>
												</>
											)}
										</Stack>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6} order={{ xs: 4, md: 2 }}>
								<FormGroup
									sx={{
										padding: 2,
									}}
								>
									<Stack spacing={2}>
										<SectionHeader
											title={t('page.pam.edit.all.subtitle.recording')}
											description={t('page.pam.edit.all.body.description.recording')}
										/>
										<Box>
											<SwitchField
												name={'enableSessionRecording'}
												control={control}
												label={t('page.pam.edit.all.form.enableSessionRecording.label')}
												disabled={shouldDisableForm}
											/>
											<SwitchField
												name={'storeRecordingsPermanently'}
												control={control}
												label={t('page.pam.edit.all.form.storeRecordingsPermanently.label')}
												disabled={!enableSessionRecording || shouldDisableForm}
											/>
										</Box>
										<TextField
											name={'recordingRetentionPeriod'}
											register={register}
											label={t('page.pam.edit.all.form.recordingRetentionPeriod.label')}
											error={errors.recordingRetentionPeriod}
											disabled={
												!enableSessionRecording ||
												storeRecordingsPermanently ||
												shouldDisableForm
											}
											placeholder={'6 months'}
											helperText={t('page.pam.edit.all.form.recordingRetentionPeriod.helperText')}
										/>
									</Stack>
								</FormGroup>
							</Grid>
							<Grid item xs={12} md={6} order={{ xs: 6, md: 4 }}>
								<FormGroup
									sx={{
										padding: 2,
									}}
								>
									<Stack spacing={2}>
										<SectionHeader
											title={t('page.pam.edit.all.subtitle.clipboard')}
											description={t('page.pam.edit.all.body.description.clipboard')}
										/>
										<Box>
											<SwitchField
												name={'enableClipboardCopy'}
												control={control}
												label={t('page.pam.edit.all.form.enableClipboardCopy.label')}
												disabled={shouldDisableForm}
											/>
											<SwitchField
												name={'enableClipboardPaste'}
												control={control}
												label={t('page.pam.edit.all.form.enableClipboardPaste.label')}
												disabled={shouldDisableForm}
											/>
										</Box>
									</Stack>
								</FormGroup>
							</Grid>

							<Grid item xs={12} md={6} order={{ xs: 5, md: 6 }}>
								<FormGroup
									sx={{
										padding: 2,
									}}
								>
									<Stack spacing={1}>
										<SectionHeader
											title={t('page.pam.edit.all.subtitle.sftp')}
											description={t('page.pam.edit.all.body.description.sftp')}
										/>
										<Box>
											<SwitchField
												name='enableFileDownload'
												control={control}
												label={t('page.pam.edit.all.form.enableFileDownload.label')}
												error={errors.enableFileDownload}
												disabled={shouldDisableForm}
											/>
											<SwitchField
												name='enableFileUpload'
												control={control}
												label={t('page.pam.edit.all.form.enableFileUpload.label')}
												error={errors.enableFileUpload}
												disabled={shouldDisableForm}
											/>
											<Stack spacing={2}>
												<SwitchField
													name='unlimitedFileSize'
													disabled={!enableFileUpload || shouldDisableForm}
													control={control}
													label={t('page.pam.edit.all.unlimitedFileSize.label')}
													helperText={t('page.pam.edit.all.unlimitedFileSize.helperText')}
												/>
												<TextField
													name={'uploadFileSizeLimit'}
													type={EFieldType.NUMBER}
													register={register}
													label={t('page.pam.edit.all.form.uploadFileSizeLimit.label')}
													error={errors.uploadFileSizeLimit}
													disabled={
														!enableFileUpload || unlimitedFileSize || shouldDisableForm
													}
													helperText={t(
														'page.pam.edit.all.form.uploadFileSizeLimit.helperText',
													)}
													InputProps={{
														endAdornment: (
															<InputAdornment position='end'>{'MB'}</InputAdornment>
														),
													}}
												/>
												<TextField
													name={'rootDirectory'}
													register={register}
													label={t('page.pam.edit.all.form.rootDirectory.label')}
													error={errors.rootDirectory}
													placeholder={'/'}
													disabled={!enableFileUpload || shouldDisableForm}
													helperText={t('page.pam.edit.all.form.rootDirectory.helperText')}
												/>
											</Stack>
										</Box>
									</Stack>
								</FormGroup>
							</Grid>
						</Grid>

						{isAllowed([EPermission.PAM_TARGETS_CREATE, EPermission.PAM_TARGETS_UPDATE], false) && (
							<FloatingButtonSave
								type='submit'
								disabled={shouldDisableForm}
								ariaLabel={t('page.pam.edit.all.ariaLabel.save')}
								tooltipTitle={
									id ? t('page.pam.edit.all.tooltips.save') : t('page.pam.edit.all.tooltips.create')
								}
							/>
						)}
					</Paper>
				}
			</Box>

			{isAllowed([EPermission.PAM_TARGETS_CREATE, EPermission.PAM_TARGETS_UPDATE], false) && (
				<ConfirmationDialog
					open={isConfirmDialogOpen}
					onClose={closeConfirmDialog}
					onConfirm={retrySubmit}
					text={confirmDialogText.text}
					title={confirmDialogText.title}
					content={confirmDialogText.combinedText}
				/>
			)}
		</>
	);
};
