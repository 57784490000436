import * as React from 'react';
import i18n from 'i18next';
import { initReactI18next, I18nextProvider } from 'react-i18next';
import Backend from 'i18next-http-backend';

import { LocalizationContextProviderProps } from './types';
import { useLocalStorage } from '../../hooks/useLocalStorage';
import { useNavigatorLanguageCode } from '../../hooks/useNavigatorLanguage';
import { DEFAULT_LANGUAGE, LOCAL_STORAGE_LANGUAGE_KEY, LANGUAGES } from './constants';
import { ELanguageCode } from '../../api/Api';
import { isValidLanguageCode } from './helpers';

i18n.use(Backend)
	.use(initReactI18next)
	.init({
		fallbackLng: DEFAULT_LANGUAGE,
		lng: DEFAULT_LANGUAGE,
		debug: true,
		backend: {
			loadPath: '/locales/{{lng}}/{{ns}}.json',
		},
		keySeparator: '.',
		interpolation: {
			escapeValue: false,
		},
	});

export const LocalizationProvider: React.FC<LocalizationContextProviderProps> = ({ children }): JSX.Element => {
	const browserLanguage = useNavigatorLanguageCode();
	const defaultLanguage = isValidLanguageCode(browserLanguage) ? browserLanguage : DEFAULT_LANGUAGE;
	const [localStorageLang] = useLocalStorage(LOCAL_STORAGE_LANGUAGE_KEY, defaultLanguage);
	const [isI18nInitialized, setIsI18nInitialized] = React.useState(false);

	React.useEffect(() => {
		if (localStorageLang && isValidLanguageCode(localStorageLang)) {
			i18n.changeLanguage(localStorageLang, () => {
				setIsI18nInitialized(true);
			});
		} else {
			setIsI18nInitialized(true);
		}
	}, [localStorageLang]);

	if (!isI18nInitialized) {
		return <></>;
	}

	return (
		<I18nextProvider i18n={i18n} defaultNS={'translation'}>
			{children}
		</I18nextProvider>
	);
};

export const useChangeLanguage = (): ((lang: ELanguageCode) => void) => {
	const browserLanguage = useNavigatorLanguageCode();
	const defaultLanguage = isValidLanguageCode(browserLanguage) ? browserLanguage : DEFAULT_LANGUAGE;

	const [, setLocalStorageLang] = useLocalStorage(LOCAL_STORAGE_LANGUAGE_KEY, defaultLanguage);

	const changeLanguage = (lang: ELanguageCode) => {
		if (isValidLanguageCode(lang)) {
			setLocalStorageLang(lang);
			i18n.changeLanguage(lang);
		} else {
			console.error(`Invalid language code ${lang}`);
		}
	};

	return changeLanguage;
};

export const useCurrentLanguage = (): ELanguageCode => {
	return i18n.language as ELanguageCode;
};
