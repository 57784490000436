import * as React from 'react';
import { Box, AppBar, Toolbar, Typography, Container, FormControl, useTheme } from '@mui/material';
import { Outlet } from 'react-router-dom';

import { usePathName } from '../hooks/usePathName';
import { useDeviceDetect } from '../hooks/useDeviceDetect';
import { ExcaliburLogo } from '../components/ExcaliburLogo/ExcaliburLogo';
import { LanguageSwitcher } from '../components/LanguageSwitcher/LanguageSwitcher';
import { SETUP_ROUTE } from './constants';

export const AuthLayout: React.FC = (): JSX.Element => {
	const theme = useTheme();
	const device = useDeviceDetect();
	const pathname = usePathName();

	const isSetupPage = pathname.startsWith(SETUP_ROUTE);
	const appBarColor = isSetupPage ? '#ffffff' : '#00172e';
	const textColor = isSetupPage ? '#000000' : '#ffffff';
	const boxShadow = isSetupPage ? 'none' : theme.shadows[4];

	return (
		<Box
			sx={{
				display: 'flex',
				flexDirection: 'column',
				height: '100vh',
			}}
		>
			<AppBar position='static' sx={{ backgroundColor: appBarColor, boxShadow: boxShadow }}>
				<Toolbar>
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: 1,
							padding: 1,
							flexGrow: 1,
						}}
					>
						<ExcaliburLogo color={isSetupPage ? 'black' : 'white'} width={40} height={40} />
						{device !== 'mobile' && (
							<Typography variant='subtitle1' sx={{ color: textColor, marginLeft: 1 }}>
								Excalibur Enterprise
							</Typography>
						)}
					</Box>
					<FormControl sx={{ maxWidth: 120 }}>
						<LanguageSwitcher isCompactMode={false} isAuthLayout={true} textColor={textColor} />
					</FormControl>
				</Toolbar>
			</AppBar>
			<Container maxWidth={false} disableGutters sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>
				<Outlet />
			</Container>
		</Box>
	);
};
