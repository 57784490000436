import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MaterialReactTable,
	MRT_TableInstance,
} from 'material-react-table';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { Box, Tooltip, IconButton, Stack, Paper } from '@mui/material';
import {
	Edit as EditIcon,
	Delete as DeleteIcon,
	SaveAlt as SaveAltIcon,
	Token as TokenIcon,
} from '@mui/icons-material';
import { enqueueSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

import { Link } from '../../components/Link/Link';
import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import {
	PagedResultPartialOAuthClientsCollectionResponseDto,
	PartialOAuthClientsCollectionResponseDto,
} from '../../api/Api';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { oAuthClientListSchema } from './schemas';
import { EConfirmDialogState } from '../../enums/teanant/EConfirmDialogState';
import { FloatingButtonAdd } from '../../components/Buttons/FloatingButton/FloatingButtonAdd';
import { useTableQuery } from '../../hooks/useTableQuery';
import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useACL } from '../../hooks/useACL';
import { useFormatDate } from '../../hooks/useFormatDate';
import { EPermission } from '../../enums/permission/EPermission';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';
import { useNavigate } from '../../hooks/useNavigate';

export const OAuthClientList: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const navigate = useNavigate();
	const formatDate = useFormatDate();
	const reactQueryClient = useReactQueryClient();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [open, setOpen] = React.useState(false);
	const [clientIDToDelete, setClientIDToDelete] = React.useState<string | null>(null);
	const [multiClientIDToDelete, setMultiClientIDToDelete] = React.useState<string[]>([]);
	const [confirmationText, setConfirmationText] = React.useState('');
	const [confirmationTitle, setConfirmationTitle] = React.useState('');
	const handleClose = () => setOpen(false);

	const {
		rowSelection,
		setRowSelection,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['clientID', 'name', 'createdAt']);

	const { data, isRefetching, isLoading, error, refetch } =
		useQuery<PagedResultPartialOAuthClientsCollectionResponseDto>({
			queryKey: [EQueryKey.OAUTH_CLIENT_LIST_QUERY, swaggerQuery],
			queryFn: async () => {
				try {
					const response = await api.oauthClient.getOAuthClientsCollection({
						limit: swaggerQuery.limit,
						offset: swaggerQuery.offset,
						sort: swaggerQuery.sort,
						filter: swaggerQuery.filter,
						columns: swaggerQuery.columns,
					});
					response.data.entities.forEach((oauthClient) => {
						oAuthClientListSchema.parse(oauthClient);
					});

					return response.data;
				} catch (error) {
					console.error(error);

					return { entities: [], total: 0 };
				}
			},
			placeholderData: keepPreviousData,
			refetchOnWindowFocus: false,
		});
	const { entities = [], total = 0 } = data ? data : {};

	const updateRowSelection = React.useCallback(
		(deletedIds: string[]) => {
			if (Object.keys(rowSelection).length === 0) {
				return;
			}

			const newRowSelection = { ...rowSelection };
			deletedIds.forEach((id) => {
				delete newRowSelection[id];
			});

			setRowSelection(newRowSelection);
		},
		[rowSelection],
	);

	const handleOnChangeConfirmDialog = React.useCallback((state: EConfirmDialogState): void => {
		switch (state) {
			case EConfirmDialogState.DELETE_SINGLE:
				setConfirmationTitle(t('page.oAuthClients.list.confirmation.delete.title'));
				setConfirmationText(t('page.oAuthClients.list.confirmation.delete.text'));

				return;
			case EConfirmDialogState.DELETE_MULTIPLE:
				setConfirmationTitle(t('page.oAuthClients.list.confirmation.delete.titleMultipleIds'));
				setConfirmationText(t('page.oAuthClients.list.confirmation.delete.textMultipleIds'));

				return;
			case EConfirmDialogState.RESET:
				setConfirmationTitle('');
				setConfirmationText('');

				return;
			default:
				return;
		}
	}, []);

	const hadleOnOpenOAuthClientDeleteDialog = React.useCallback(
		(clientID: string) => (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
			event.stopPropagation();

			setClientIDToDelete(clientID);
			setOpen(true);
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_SINGLE);
		},
		[isAllowed],
	);

	const handleOnOpenMultipleOauthClientDeleteDialog = React.useCallback(
		(table: MRT_TableInstance<PartialOAuthClientsCollectionResponseDto>) => () => {
			const selectedRowsOnActivePageIds = table
				.getSelectedRowModel()
				.rows.map((row) => row.original.clientID as string);

			if (selectedRowsOnActivePageIds.length === 0) {
				enqueueSnackbar(t('page.oAuthClients.list.actionMessages.noClientsSelected'), {
					variant: 'warning',
					persist: false,
				});

				return;
			}
			setOpen(true);
			if (Array.isArray(selectedRowsOnActivePageIds)) {
				setMultiClientIDToDelete(selectedRowsOnActivePageIds);
			}
			handleOnChangeConfirmDialog(EConfirmDialogState.DELETE_MULTIPLE);
		},
		[confirmationText, confirmationTitle, enqueueSnackbar],
	);

	const handleOnConfirmOAuthClientDelete = React.useCallback(async (): Promise<void> => {
		if (clientIDToDelete) {
			try {
				await api.oauthClient.deleteOauthClient(clientIDToDelete);
				enqueueSnackbar(t('page.oAuthClients.list.actionMessages.clientSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setClientIDToDelete(null);
				updateRowSelection([clientIDToDelete]);
			} catch (error) {
				console.error(error);
			}
		} else if (multiClientIDToDelete.length > 0) {
			try {
				await api.oauthClient.deleteOauthClients({ ids: multiClientIDToDelete });

				enqueueSnackbar(t('page.identityStore.list.actionMessages.identityStoresSuccessfullyDeleted'), {
					variant: 'success',
					persist: false,
				});
				setMultiClientIDToDelete([]);
				updateRowSelection(multiClientIDToDelete);
				handleOnChangeConfirmDialog(EConfirmDialogState.RESET);
			} catch (error) {
				console.error(error);
			}
		}

		setOpen(false);
		reactQueryClient.invalidateQueries();
	}, [clientIDToDelete, multiClientIDToDelete, refetch]);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	const handleRedirect = (path: string) => (event: React.MouseEvent) => {
		event.stopPropagation();
		navigate(path);
	};

	const columns = React.useMemo<MRTColumnDef<PartialOAuthClientsCollectionResponseDto>[]>(
		() => [
			{
				accessorKey: 'clientID',
				header: t('page.oAuthClients.list.header.clientID'),
			},
			{
				accessorKey: 'name',
				header: t('page.oAuthClients.list.header.name'),
			},
			{
				accessorFn: (row) => `${formatDate(row.createdAt, true)}`,
				accessorKey: 'createdAt',
				filterVariant: 'datetime-range',
				muiFilterDateTimePickerProps: {
					slotProps: {
						textField: {
							sx: { zIndex: 1 },
						},
					},
				},
				header: t('page.oAuthClients.list.header.createdAt'),
				minSize: 340,
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{renderedCellValue}
					</Box>
				),
			},
		],
		[],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper elevation={3}>
				<Stack
					spacing={3}
					sx={{
						padding: 2,
					}}
				>
					<PageHeader
						title={t('page.oAuthClients.list.title')}
						description={t('page.oAuthClients.list.description')}
						icon={TokenIcon}
					/>
					<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
						<MaterialReactTable
							layoutMode='grid'
							columns={columns}
							data={entities}
							enableRowActions
							enableStickyHeader={false}
							state={{
								isLoading: isLoading,
								showAlertBanner: error !== null,
								pagination,
								rowSelection,
								globalFilter,
								columnFilters,
								sorting,
								columnVisibility,
								showProgressBars: isRefetching,
							}}
							muiToolbarAlertBannerProps={{
								color: 'error',
								children: <>{error}</>,
							}}
							rowCount={total}
							manualPagination
							manualFiltering
							manualSorting
							onSortingChange={setSorting}
							onGlobalFilterChange={setGlobalFilter}
							onPaginationChange={setPagination}
							onColumnFiltersChange={setColumnFilters}
							onColumnVisibilityChange={setColumnVisibility}
							enableRowSelection={isAllowed([EPermission.OAUTH_CLIENTS_DELETE])}
							onRowSelectionChange={setRowSelection}
							getRowId={(originalRow) => originalRow.clientID?.toString() || ''}
							initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
							renderRowActions={({ row }) => (
								<Box
									sx={{
										display: 'flex',
										alignItems: 'center',
										gap: '1rem',
									}}
								>
									{isAllowed([EPermission.OAUTH_CLIENTS_UPDATE]) && (
										<Tooltip
											title={t('page.oAuthClients.list.tooltips.edit')}
											placement='left'
											enterDelay={500}
											arrow
										>
											<span>
												<IconButton
													onClick={handleRedirect(
														`/security/oauth/edit/${row.original.clientID}`,
													)}
												>
													<EditIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
									{isAllowed([EPermission.OAUTH_CLIENTS_DELETE]) && (
										<Tooltip
											title={t('page.oAuthClients.list.tooltips.delete')}
											placement='right'
											enterDelay={500}
											arrow
										>
											<IconButton
												color='error'
												onClick={hadleOnOpenOAuthClientDeleteDialog(
													row.original.clientID as string,
												)}
											>
												<DeleteIcon />
											</IconButton>
										</Tooltip>
									)}
								</Box>
							)}
							renderToolbarInternalActions={({ table }) => (
								<Box>
									<MRTToggleGlobalFilterButton table={table} />
									<MRTToggleFiltersButton table={table} />
									<MRTShowHideColumnsButton table={table} />
									{/* <Tooltip arrow title='Export'>
										<IconButton>
											<SaveAltIcon />
										</IconButton>
									</Tooltip> */}
									{isAllowed([EPermission.OAUTH_CLIENTS_DELETE]) && (
										<Tooltip
											title={t('page.oAuthClients.list.tooltips.removeSelected')}
											enterDelay={500}
										>
											<span>
												<IconButton
													color='error'
													disabled={table.getSelectedRowModel().rows.length === 0}
													onClick={handleOnOpenMultipleOauthClientDeleteDialog(table)}
												>
													<DeleteIcon />
												</IconButton>
											</span>
										</Tooltip>
									)}
									<MRTToggleDensePaddingButton table={table} />
									<MRTFullScreenToggleButton table={table} />
								</Box>
							)}
							displayColumnDefOptions={{
								'mrt-row-actions': {
									header: t('page.oAuthClients.list.header.actions'),
								},
								'mrt-row-select': {
									enableHiding: true,
									visibleInShowHideMenu: false,
								},
							}}
							muiTablePaperProps={({ table }) => ({
								style: {
									zIndex: table.getState().isFullScreen ? 1250 : undefined,
									boxShadow: 'none',
									outline: '1px solid #e0e0e0',
								},
							})}
							muiSelectCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiSelectAllCheckboxProps={() => ({
								sx: {
									width: '50px',
									height: '50px',
								},
							})}
							muiTableHeadCellProps={() => ({
								sx: {
									verticalAlign: 'baseline',
								},
							})}
							muiTableBodyRowProps={({ row }) => ({
								onClick:
									isAllowed([EPermission.OAUTH_CLIENTS_READ]) ?
										handleRedirect(`/security/oauth/${row.original.clientID}`)
									:	undefined,
								sx: { cursor: 'pointer' },
							})}
							localization={MRTLocalization}
							positionActionsColumn='last'
						/>
					</LocalizationProvider>
				</Stack>
			</Paper>
			{isAllowed([EPermission.OAUTH_CLIENTS_CREATE]) && (
				<Link to='/security/oauth/new'>
					<FloatingButtonAdd
						ariaLabel={t('page.oAuthClients.list.ariaLabel.add')}
						tooltipTitle={t('page.oAuthClients.list.tooltips.add')}
					/>
				</Link>
			)}

			{isAllowed([EPermission.OAUTH_CLIENTS_DELETE]) && (
				<ConfirmationDialog
					onClose={handleClose}
					open={open}
					onConfirm={handleOnConfirmOAuthClientDelete}
					title={confirmationTitle}
					text={confirmationText}
					cancelText={t('page.oAuthClients.list.confirmation.delete.cancel')}
					confirmText={t('page.oAuthClients.list.confirmation.delete.confirm')}
				/>
			)}
		</Box>
	);
};
