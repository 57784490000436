import * as React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { Dialog, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { PAMSessionPlayer } from '../../PAMSessionPlayer/PAMSessionPlayer';
import { useTranslation } from 'react-i18next';
import { SessionPlayerDialogProps } from './types';
import { useSwaggerApi } from '../../../hooks/useSwaggerApi';
import { Preloader } from '../../Preloader/Preloader';

export const SessionPlayerDialog: React.FC<SessionPlayerDialogProps> = ({
	open,
	src,
	userInput,
	startPosition,
	onClose,
}): JSX.Element => {
	const [sessionDialogState, setSessionDialogState] = React.useState<string>('loading');
	const { t } = useTranslation();
	const swaggerApi = useSwaggerApi();

	const handleRefreshSession = async () => {
		setSessionDialogState('loading');
		await swaggerApi.auth.session();
		setSessionDialogState('loaded');
	};

	React.useEffect(() => {
		handleRefreshSession();

		return () => {
			setSessionDialogState('loading');
		};
	}, []);

	return (
		<Dialog open={open} onClose={onClose} maxWidth={'lg'} fullWidth>
			<DialogTitle>{t('component.sessionPlayerDialog.title')}</DialogTitle>
			<IconButton
				aria-label='close'
				onClick={onClose}
				sx={{
					position: 'absolute',
					right: 6,
					top: 8,
					color: (theme) => theme.palette.grey[500],
				}}
			>
				<CloseIcon />
			</IconButton>
			<DialogContent>
				{sessionDialogState === 'loading' && <Preloader />}
				{sessionDialogState === 'loaded' && (
					<PAMSessionPlayer src={src} userInput={userInput} startPosition={startPosition} />
				)}
			</DialogContent>
		</Dialog>
	);
};
