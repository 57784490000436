import { useNavigate as useRouterNavigate, NavigateOptions, To } from 'react-router-dom';

import { getTenantFromPath } from '../utils/Routing';
import { useUnsavedChangesContext } from './useUnsavedChangesContext';

export const useNavigate = (): ((to: To | number, options?: NavigateOptions) => void) => {
	const { hasUnsavedChanges, setShowDialog, setPathParams } = useUnsavedChangesContext();

	const routerNavigate = useRouterNavigate();
	const { tenantID, tenantSlug } = getTenantFromPath();

	return (to: To | number, options?: NavigateOptions) => {
		let navigateTo: To | number = to;

		if (tenantID && typeof to !== 'number') {
			navigateTo = `/tenant/${tenantID}${to}`;
		} else if (tenantSlug && typeof to !== 'number') {
			navigateTo = `/tenant/${tenantSlug}${to}`;
		}

		if (hasUnsavedChanges) {
			setShowDialog(true);
			setPathParams({ options, to: navigateTo as To });

			return;
		}

		routerNavigate(navigateTo as To, options);
	};
};
