import { z } from 'zod';
import { TFunction } from 'i18next';

export const getRuleSetSchema = (t: TFunction) => {
	return z.object({
		name: z
			.string()
			.min(3, { message: t('page.securityPolicy.edit.form.name.Error', { minLength: 3 }) })
			.max(64, { message: t('page.securityPolicy.edit.form.name.ErrorMaxLength', { maxLength: 64 }) }),
		description: z.string().optional(),
		type: z.string(),
		timeRange: z
			.object({
				end: z.string(),
				start: z.string(),
			})
			.optional(),
		days: z.array(z.string()).optional(),
		rules: z.array(z.any()),
	});
};

export const ruleSetListSchema = z.object({
	id: z.number(),
	name: z.string(),
	description: z.string().optional(),
	tenantID: z.number().optional().nullable(),
	securityPolicies: z.array(z.object({ id: z.number(), name: z.string() })),
	securityPoliciesAllCount: z.number(),
});

export const ruleSet = (t: TFunction) =>
	z
		.object({
			name: z.string().max(64, t('page.ruleSet.form.fields.name.error.maxLength', { maxLength: 64 })),
			description: z
				.string()
				.max(255, t('page.ruleSet.form.fields.description.error.maxLength', { maxLength: 255 })),
			factors: z.array(z.array(z.string())),
			geofenceGroups: z.array(z.array(z.number())),
			geofences: z.array(z.array(z.number())),
			days: z.array(z.string()),
			phoneConnectionIPs: z.array(z.object({ from: z.string(), to: z.string() })),
			ips: z.array(z.string()),
			phoneConnectionStatuses: z.array(z.string()),
			timeRanges: z.array(z.object({ from: z.string(), to: z.string() })),
			timeRange: z.object({ start: z.string(), end: z.string() }).optional(),
		})
		.refine(
			(ruleSet) =>
				!(
					ruleSet.timeRanges.length === 0 &&
					ruleSet.ips.length === 0 &&
					ruleSet.phoneConnectionIPs.length === 0 &&
					ruleSet.phoneConnectionStatuses.length === 0 &&
					ruleSet.factors.length === 0 &&
					ruleSet.geofenceGroups.length === 0 &&
					ruleSet.geofences.length === 0
				),
			t('page.ruleSet.form.errorMessage.creationFailedMissingRule'),
		);
