import { Units } from 'parse-duration';

/**
 * This constant should be used when converting string duration to number and vice versa to preserve consistency .
 *
 * This constant depends on the Database - if the database considers number to be in 'second', then this constant should be 'second'.
 *
 * @example const time =  getDurationInUnits('2 minutes', 'second')
 * const duration = getDurationInString(time, 'minute') // Error: should be 'second'
 * // To avoid such errors use this constant
 */
export const RECORDING_RETENTION_PERIOD_UNITS: Units = 'minute';

export const MAX_FILE_SIZE = 1000000;

const MAX_MARIADB_UINT_VALUE = 4294967295;
/**
 * @description 1 second
 * @see This should be in sync with the DB. DB uses unsigned int and stores time in minutes
 * */
export const MIN_VALID_RETENTION_PERIOD_MINUTES = 1;
/**
 * @description Approximately 8100 years
 * @link Should be in sync with the DB. DB uses unsigned int and stores time in minutes.
 * Currently it corresponds to uint max value in MariaDB. But it should be keep as separate constant for situations where DB type will be changed or time resolution (min, sec, day) will be changed too.
 */
export const MAX_VALID_RETENTION_PERIOD_MINUTES = MAX_MARIADB_UINT_VALUE;

/**
 * @description 1 day
 * This can be used as a minimum value for retention period or to warn user about 'strange' values.
 * The reason behind this is that backend clean recordings every day at 3 AM so it doesn't make sense to have retention period less than 1 day.
 * We may warn user about this value but we should not prevent him from saving it.
 */
export const MIN_REASONABLE_RETENTION_PERIOD_MINUTES = 1 * 24 * 60; // 1 day
/**
 * @description 5 years
 * This can be used as a maximum value for retention period or to warn user about 'strange' values.
 * The reason behind this is that we don't want to store recordings for such a long time when we have `permanent` store option.
 * We may warn user about this value but we should not prevent him from saving it.
 */
export const MAX_REASONABLE_RETENTION_PERIOD_MINUTES = 5 * 365 * 24 * 60; // 5 years

/**
 * @description 1MB
 * @see This should be in sync with the DB. DB uses unsigned int and stores file size in bytes
 */
export const MIN_UPLOAD_FILE_SIZE_MB = 1;

export const MAX_UPLOAD_FILE_SIZE_BYTE = MAX_MARIADB_UINT_VALUE;

/**
 * @description 4000MB
 * @see This should be in sync with the DB. DB uses unsigned int and stores file size in bytes
 * User enters file size in MB but it is converted to bytes before saving to DB
 */
export const MAX_UPLOAD_FILE_SIZE_MB = Math.floor(MAX_UPLOAD_FILE_SIZE_BYTE / 1024 / 1024);

export const ACCOUNT_EXPIRED_ERROR_CODES = [300023, 300024, 300061, 400014];

export const FETCH_INTERVAL = 5000;
