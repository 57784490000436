import { z } from 'zod';
import { TFunction } from 'i18next';

const ACCEPTED_FILE_EXTENSIONS_IS = ['.pem', '.cer'];
const ACCEPTED_FILE_EXTENSIONS_CSR = ['.lic'];

/*
 * Validation schema for server url setup form
 */
export const getServerSetupSchema = (t: TFunction) => {
	return z.object({
		hostname: z
			.string()
			.max(255, { message: t('page.setupWizard.validationMessages.hostname.maxLength', { maxLength: 255 }) })
			.min(1, t('page.setupWizard.validationMessages.hostname.required')),
		tokenUrl: z
			.string()
			.min(1, t('page.setupWizard.validationMessages.tokenUrl.required'))
			.max(1600, { message: t('page.setupWizard.validationMessages.tokenUrl.maxLength', { maxLength: 1600 }) })
			.optional(),
		timezone: z.string().min(1, t('page.setupWizard.validationMessages.timezone.invalid')).optional(),
	});
};

/*
 * Validation schema for CSR setup form
 */
export const getCSRSetupSchema = (t: TFunction) => {
	return z.object({
		licenseFile: z.string().min(1, t('page.setupWizard.validationMessages.licenseFile.required')),
		licenseKey: z
			.string()
			.min(1, t('page.setupWizard.validationMessages.licenseKey.required'))
			.min(10, t('page.setupWizard.validationMessages.licenseKey.length'))
			.regex(/^([A-Z0-9]{5}-){4}[A-Z0-9]{5}$/, t('page.setupWizard.validationMessages.licenseKey.invalid')),
		file: z
			.instanceof(File)
			.optional()
			.nullable()
			.refine((file) => !file || ACCEPTED_FILE_EXTENSIONS_CSR.some((ext) => file.name.endsWith(ext)), {
				message: t('page.setupWizard.texts.CSR.file.ErrorExtension'),
			}),
	});
};

/*
 * Validation schema for SMTP setup form
 */
export const getSMTPSetupSchema = (t: TFunction) => {
	const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;
	const portRegex = /^\d+$/;

	return z
		.object({
			name: z
				.string()
				.min(1, t('page.setupWizard.validationMessages.name.required'))
				.max(64, t('page.setupWizard.validationMessages.name.maxLength', { maxLength: 64 })),
			description: z
				.string()
				.max(255, {
					message: t('page.setupWizard.validationMessages.description.maxLength', { maxLength: 255 }),
				})
				.optional(),
			host: z
				.string()
				.min(1, t('page.setupWizard.validationMessages.host.required'))
				.max(255, { message: t('page.setupWizard.validationMessages.host.maxLength', { maxLength: 255 }) }),
			port: z
				.string()
				.min(1, t('page.setupWizard.validationMessages.port.required'))
				.refine((val) => portRegex.test(val), t('page.setupWizard.validationMessages.port.required'))
				.refine((value) => parseInt(value, 10) >= 0 && parseInt(value, 10) <= 65535, {
					message: t('page.smtp.edit.form.port.ErrorMaxLength', { maxLength: 65535 }),
				})
				.optional(),
			userName: z
				.string()
				.max(255, { message: t('page.setupWizard.validationMessages.userName.maxLength', { maxLength: 255 }) })
				.optional(),
			password: z
				.string()
				.max(255, { message: t('page.setupWizard.validationMessages.password.maxLength', { maxLength: 255 }) })
				.optional(),
			sender: z
				.string()
				.min(1, t('page.setupWizard.validationMessages.sender.required'))
				.max(255, { message: t('page.setupWizard.validationMessages.sender.maxLength', { maxLength: 255 }) })
				.refine((val) => emailRegex.test(val), t('page.setupWizard.validationMessages.sender.invalid')),
			email: z
				.string()
				.min(1, t('page.setupWizard.validationMessages.email.required'))
				.max(255, { message: t('page.setupWizard.validationMessages.email.maxLength', { maxLength: 255 }) })
				.refine((val) => emailRegex.test(val), t('page.setupWizard.validationMessages.email.invalid')),
			emailAgain: z
				.string()
				.min(1, t('page.setupWizard.validationMessages.emailAgain.required'))
				.max(255, {
					message: t('page.setupWizard.validationMessages.emailAgain.maxLength', { maxLength: 255 }),
				})
				.refine((val) => emailRegex.test(val), t('page.setupWizard.validationMessages.emailAgain.invalid')),
			security: z.string().min(1, t('page.setupWizard.validationMessages.security.required')),
		})
		.refine((data) => data.email === data.emailAgain, {
			message: t('page.setupWizard.validationMessages.emailAgain.match'),
			path: ['emailAgain'],
		});
};

/*
 * Validation schema for email verification form
 */
export const getVerificationSchema = (t: TFunction) => {
	return z.object({
		verificationCode: z.string().min(6, t('page.setupWizard.validationMessages.verificationCode.required')),
	});
};

/*
 * Validation schema for identity store setup form
 */
export const getIdentityStoreSchema = (t: TFunction) => {
	const portRegex = /^\d+$/;

	return z.object({
		name: z
			.string()
			.min(1, t('page.setupWizard.validationMessages.name.required'))
			.max(64, t('page.setupWizard.validationMessages.name.maxLength', { maxLength: 64 })),
		description: z
			.string()
			.max(255, t('page.setupWizard.validationMessages.description.maxLength', { maxLength: 255 }))
			.optional(),
		useTLS: z.boolean(),
		host: z
			.string()
			.min(1, t('page.setupWizard.validationMessages.host.required'))
			.max(255, t('page.setupWizard.validationMessages.host.maxLength', { maxLength: 255 })),
		port: z
			.string()
			.min(1, t('page.setupWizard.validationMessages.port.required'))
			.refine((val) => portRegex.test(val), t('page.setupWizard.validationMessages.port.required'))
			.refine((value) => parseInt(value, 10) >= 0 && parseInt(value, 10) <= 65535, {
				message: t('page.smtp.edit.form.port.ErrorMaxLength', { maxLength: 65535 }),
			}),
		caCertificate: z.string().optional(),
		baseDN: z.string().min(1, t('page.setupWizard.validationMessages.baseDN.required')),
		bindDN: z.string().min(1, t('page.setupWizard.validationMessages.bindDN.required')),
		password: z
			.string()
			.min(1, t('page.setupWizard.validationMessages.password.required'))
			.max(255, t('page.setupWizard.validationMessages.password.maxLength', { maxLength: 255 })),
		file: z
			.instanceof(File)
			.optional()
			.nullable()
			.refine((file) => !file || ACCEPTED_FILE_EXTENSIONS_IS.some((ext) => file.name.endsWith(ext)), {
				message: t('page.setupWizard.texts.identityStore.form.file.ErrorExtension'),
			}),
	});
};

/*
 * Validation schema for identity store authentication form
 */
export const getIdentityStoreAuthenticationSchema = (t: TFunction) => {
	return z.object({
		username: z.string().min(1, t('page.setupWizard.validationMessages.username.required')),
		password: z.string().min(1, t('page.setupWizard.validationMessages.password.required')),
	});
};
