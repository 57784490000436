import { TFunction } from 'i18next';
import { RoutesGetter } from './types';

export const getRoutes: RoutesGetter = (t: TFunction) => [
	{
		path: '/',
		breadcrumb: t('breadcrumbs.home'),
		children: [
			{
				path: 'me',
				breadcrumb: t('breadcrumbs.profile'),
				children: [
					{
						path: 'settings',
						breadcrumb: t('breadcrumbs.preferences'),
					},
				],
			},
			{
				path: 'users',
				breadcrumb: t('breadcrumbs.users'),
				children: [
					{
						path: 'invitations',
						breadcrumb: t('breadcrumbs.invitations'),
						children: [{ path: 'new', breadcrumb: t('breadcrumbs.new') }],
					},
					{
						path: 'userGroups',
						breadcrumb: t('breadcrumbs.userGroups'),
						children: [
							{
								path: 'edit',
								breadcrumb: null,
								children: [
									{
										path: ':id',
										breadcrumb: t('breadcrumbs.edit'),
									},
								],
							},
							{ path: 'new', breadcrumb: t('breadcrumbs.new') },
							{
								path: ':id',
								breadcrumb: t('breadcrumbs.detail'),
							},
						],
					},
					{
						path: ':userID',
						breadcrumb: null,
						children: [
							{
								path: 'detail',
								breadcrumb: t('breadcrumbs.userDetail'),
							},
						],
					},
				],
			},
			{
				path: 'tokens',
				breadcrumb: t('breadcrumbs.tokens'),
				children: [
					{
						path: ':id',
						breadcrumb: t('breadcrumbs.detail'),
					},
				],
			},
			{
				path: 'actions',
				breadcrumb: t('breadcrumbs.actions'),
			},
			{
				path: 'pam',
				breadcrumb: t('breadcrumbs.pamTargets'),
				children: [
					{
						path: 'rdp',
						breadcrumb: null,
						children: [
							{
								path: 'edit',
								breadcrumb: null,
								children: [
									{
										path: ':id',
										breadcrumb: t('breadcrumbs.pamTargetRdpEdit'),
									},
								],
							},
							{ path: 'new', breadcrumb: t('breadcrumbs.pamTargetRdpNew') },
						],
					},
					{
						path: 'ssh',
						breadcrumb: null,
						children: [
							{
								path: 'edit',
								breadcrumb: null,
								children: [
									{
										path: ':id',
										breadcrumb: t('breadcrumbs.pamTargetSshEdit'),
									},
								],
							},
							{ path: 'new', breadcrumb: t('breadcrumbs.pamTargetSshNew') },
						],
					},
					{
						path: 'groups',
						breadcrumb: t('breadcrumbs.pamTargetGroups'),
						children: [
							{
								path: 'edit',
								breadcrumb: null,
								children: [
									{
										path: ':id',
										breadcrumb: t('breadcrumbs.edit'),
									},
								],
							},
							{ path: 'new', breadcrumb: t('breadcrumbs.new') },
							{
								path: ':id',
								breadcrumb: t('breadcrumbs.detail'),
							},
						],
					},
					{
						path: 'sessions',
						breadcrumb: t('breadcrumbs.sessions'),
						children: [
							{
								path: 'search',
								breadcrumb: t('breadcrumbs.fullTextSearch'),
							},
							{
								path: ':id',
								breadcrumb: t('breadcrumbs.detail'),
							},
						],
					},
					{
						path: ':id',
						breadcrumb: t('breadcrumbs.detail'),
					},
				],
			},
			{
				path: 'identityStores',
				breadcrumb: t('breadcrumbs.identityStores'),
				children: [
					{
						path: 'edit',
						breadcrumb: null,
						children: [
							{
								path: ':id',
								breadcrumb: t('breadcrumbs.edit'),
							},
						],
					},
					{ path: 'new', breadcrumb: t('breadcrumbs.new') },
				],
			},
			{
				path: 'tenants',
				breadcrumb: t('breadcrumbs.tenants'),
				children: [
					{
						path: 'edit',
						breadcrumb: null,
						children: [
							{
								path: ':id',
								breadcrumb: t('breadcrumbs.edit'),
							},
						],
					},
					{ path: 'new', breadcrumb: t('breadcrumbs.new') },
					{
						path: ':tenantId',
						breadcrumb: t('breadcrumbs.detail'),
						children: [
							{
								path: 'invitations',
								breadcrumb: null,
								children: [{ path: 'new', breadcrumb: t('breadcrumbs.newInvitations') }],
							},
							{
								path: 'identityStores',
								breadcrumb: null,
								children: [
									{ path: 'new', breadcrumb: t('breadcrumbs.newIdentityStores') },
									{
										path: 'edit',
										breadcrumb: null,
										children: [{ path: ':id', breadcrumb: t('breadcrumbs.editIdentityStores') }],
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'geofences',
				breadcrumb: t('breadcrumbs.geofences'),
				children: [
					{
						path: 'groups',
						breadcrumb: t('breadcrumbs.geofenceGroups'),
						children: [
							{
								path: 'edit',
								breadcrumb: null,
								children: [
									{
										path: ':id',
										breadcrumb: t('breadcrumbs.edit'),
									},
								],
							},
							{ path: 'new', breadcrumb: t('breadcrumbs.new') },
							{
								path: ':id',
								breadcrumb: t('breadcrumbs.detail'),
							},
						],
					},
				],
			},
			{
				path: 'security',
				breadcrumb: null,
				children: [
					{
						path: 'oauth',
						breadcrumb: t('breadcrumbs.oAuthClients'),
						children: [
							{ path: 'new', breadcrumb: t('breadcrumbs.new') },
							{
								path: 'edit',
								breadcrumb: null,
								children: [
									{
										path: ':id',
										breadcrumb: t('breadcrumbs.edit'),
									},
								],
							},
							{
								path: ':id',
								breadcrumb: t('breadcrumbs.detail'),
								children: [
									{ path: 'new', breadcrumb: t('breadcrumbs.newWebhook') },
									{
										path: 'edit',
										breadcrumb: null,
										children: [
											{
												path: ':webhookID',
												breadcrumb: t('breadcrumbs.editWebhook'),
											},
										],
									},
								],
							},
						],
					},
					{
						path: 'securityPolicy',
						breadcrumb: t('breadcrumbs.securityPolicy'),
						children: [
							{
								path: 'edit',
								breadcrumb: null,
								children: [
									{
										path: ':id',
										breadcrumb: t('breadcrumbs.edit'),
									},
								],
							},
							{ path: 'new', breadcrumb: t('breadcrumbs.new') },
							{
								path: ':id',
								breadcrumb: t('breadcrumbs.detail'),
							},
						],
					},
					{
						path: 'ruleSets',
						breadcrumb: t('breadcrumbs.ruleSets'),
						children: [
							{
								path: 'edit',
								breadcrumb: null,
								children: [
									{
										path: ':id',
										breadcrumb: t('breadcrumbs.edit'),
									},
								],
							},
							{ path: 'new', breadcrumb: t('breadcrumbs.new') },
						],
					},
					{
						path: 'networkPolicy',
						breadcrumb: t('breadcrumbs.networkPolicy'),
					},
				],
			},
			{
				path: 'settings',
				breadcrumb: null,
				children: [
					{
						path: 'general',
						breadcrumb: null,
						children: [
							{
								path: 'server',
								breadcrumb: t('breadcrumbs.serverSettings'),
							},
							{
								path: 'map',
								breadcrumb: t('breadcrumbs.mapSettings'),
							},
							{
								path: 'email',
								breadcrumb: null,
								children: [
									{
										path: 'smtp',
										breadcrumb: t('breadcrumbs.smtpSettings'),
										children: [
											{
												path: 'edit',
												breadcrumb: null,
												children: [
													{
														path: ':id',
														breadcrumb: t('breadcrumbs.edit'),
													},
												],
											},
											{ path: 'new', breadcrumb: t('breadcrumbs.new') },
											{
												path: ':id',
												breadcrumb: t('breadcrumbs.edit'),
											},
										],
									},
									{
										path: 'expirationTimes',
										breadcrumb: t('breadcrumbs.expirationTimes'),
									},
								],
							},
						],
					},
				],
			},
			{
				path: 'about',
				breadcrumb: t('breadcrumbs.about'),
			},
		],
	},
];
