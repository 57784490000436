/* eslint-disable @typescript-eslint/no-empty-function */
import React, { createContext, Dispatch, SetStateAction, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { ConfirmationDialog } from '../../components/Dialog/ConfirmationDialog/ConfirmationDialog';
import { PathParams } from './types';

export const UnsavedChangesContext = createContext({
	hasUnsavedChanges: false,
	showDialog: false,
	pathParams: { to: '' } as PathParams,
	setHasUnsavedChanges: ((_) => {}) as Dispatch<SetStateAction<boolean>>,
	setShowDialog: ((_) => {}) as Dispatch<SetStateAction<boolean>>,
	setPathParams: ((_) => {}) as Dispatch<SetStateAction<PathParams>>,
});

export const UnsavedChangesModalContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
	const [hasUnsavedChanges, setHasUnsavedChanges] = React.useState(false);
	const [showDialog, setShowDialog] = React.useState(false);
	const [pathParams, setPathParams] = React.useState<PathParams>({ to: '' });

	const { t } = useTranslation();

	const navigate = useNavigate();

	const onConfirm = useCallback(() => {
		setHasUnsavedChanges(false);
		setShowDialog(false);

		navigate(pathParams.to, pathParams.options);
	}, [pathParams, navigate]);

	return (
		<UnsavedChangesContext.Provider
			value={{
				hasUnsavedChanges,
				showDialog,
				pathParams,
				setHasUnsavedChanges,
				setShowDialog,
				setPathParams,
			}}
		>
			{children}
			<ConfirmationDialog
				open={showDialog && hasUnsavedChanges}
				onClose={() => setShowDialog(false)}
				onConfirm={onConfirm}
				title={t('component.unsavedChangesDialog.title')}
				text={t('component.unsavedChangesDialog.text')}
				confirmText={t('component.unsavedChangesDialog.confirm')}
				cancelText={t('component.unsavedChangesDialog.cancel')}
			/>
		</UnsavedChangesContext.Provider>
	);
};
