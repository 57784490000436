import React, { useCallback, useState } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField } from '@mui/material';
import { t } from 'i18next';
import { enqueueSnackbar } from 'notistack';
import { NetworkAddressesImportModalProps } from './types';
import { FileUploader } from '../FormFields/FileUploader/FileUploader';

export const NetworkAddressesImportModal: React.FC<NetworkAddressesImportModalProps> = ({
	open,
	control,
	name,
	error,
	disabled = false,
	trigger,
	onClose,
	onImport,
	setValue,
}) => {
	const [combinedContent, setCombinedContent] = useState<string>('');

	const handleFileChange = async (file: File | null): Promise<void> => {
		if (!file) {
			return;
		}

		const isValid = await trigger(name);
		if (!isValid) {
			return;
		}

		const reader = new FileReader();
		reader.onload = (e) => {
			if (e.target) {
				const content = e.target.result as string;
				setCombinedContent((prevContent) => {
					return prevContent ? `${prevContent}\n${content}` : content;
				});
			}
		};
		reader.onerror = (error) => {
			enqueueSnackbar(error.toString(), { variant: 'error' });
		};
		reader.readAsText(file);
	};

	const handleContentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
		setCombinedContent(event.target.value);
	};

	const handleClose = useCallback(() => {
		setCombinedContent('');
		onClose();
		setValue('file', null);
	}, [onClose]);

	const handleImport = React.useCallback(() => {
		const commaSeparatedRanges = combinedContent.split(',').map((range) => range.trim());
		const networkAddresses = commaSeparatedRanges.flatMap((range) =>
			range.split(/\r?\n/).map((line) => line.trim()),
		);
		const filteredNetworkAddresses = networkAddresses.filter((range) => range.length > 0);
		onImport(filteredNetworkAddresses);
		handleClose();
	}, [combinedContent, onImport, handleClose]);

	return (
		<Dialog fullWidth={true} maxWidth={'md'} open={open} onClose={handleClose}>
			<DialogTitle>{t('component.networkAddressImportModal.title')}</DialogTitle>
			<DialogContent>
				<FileUploader
					control={control}
					name={name}
					error={error}
					disabled={disabled}
					helperText={t('component.networkAddressImportModal.helperText')}
					multiple={false}
					onChangeCallback={handleFileChange}
				/>
				<TextField
					multiline
					fullWidth
					minRows={4}
					value={combinedContent}
					onChange={handleContentChange}
					helperText={t('component.networkAddressImportModal.pasteNetworkAddressesHelperText')}
					sx={{ mt: 3 }}
				/>
			</DialogContent>
			<DialogActions>
				<Button onClick={handleClose} color='primary'>
					{t('component.networkAddressImportModal.cancel')}
				</Button>
				<Button disabled={!combinedContent || !!error} onClick={handleImport} color='primary'>
					{t('component.networkAddressImportModal.confirm')}
				</Button>
			</DialogActions>
		</Dialog>
	);
};
