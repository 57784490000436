import * as React from 'react';
import {
	MRT_ToggleDensePaddingButton as MRTToggleDensePaddingButton,
	type MRT_ColumnDef as MRTColumnDef,
	MRT_ToggleFullScreenButton as MRTFullScreenToggleButton,
	MRT_ToggleFiltersButton as MRTToggleFiltersButton,
	MRT_ShowHideColumnsButton as MRTShowHideColumnsButton,
	MRT_ToggleGlobalFilterButton as MRTToggleGlobalFilterButton,
	MRT_PaginationState as MRTPaginationState,
	MRT_ColumnFiltersState as MRTColumnFiltersState,
	MRT_SortingState as MRTSortingState,
	MaterialReactTable,
} from 'material-react-table';
import { Box, Chip, Grid, Paper, Tooltip, Typography, IconButton, Button, Stack } from '@mui/material';
import {
	Download as DownloadIcon,
	Upload as UploadIcon,
	PlaylistPlay as PlaylistPlayIcon,
	SaveAlt as SaveAltIcon,
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { EFileTransfer } from '../../enums/session/EFileTransfer';
import { PAMSessionPlayer } from '../../components/PAMSessionPlayer/PAMSessionPlayer';
import { calculateDuration, formatDurationToString } from '../../utils/SessionDateHelpers';
import { useSwaggerApi } from '../../hooks/useSwaggerApi';
import { keepPreviousData, useQuery } from '@tanstack/react-query';
import { EFileTransferType, FileTransferDto, PagedResultFileTransferDto, PartialPamSessionDto } from '../../api/Api';
import { fileTransferSchema, sessionDetailSchema } from './schema';
import { getSessionTargetLogo } from './utils';

import { useReactQueryClient } from '../../hooks/useReactQueryClient';
import { EQueryKey } from '../../enums/reactQuery/EQueryKey';
import { useFormatDate } from '../../hooks/useFormatDate';
import { ESessionTarget } from '../../enums/session/ESessionTarget';
import { EPermission } from '../../enums/permission/EPermission';
import { useACL } from '../../hooks/useACL';
import { PageHeader } from '../../components/PageHeader/PageHeader';
import { Heading } from '../../components/Heading/Heading';
import { useMRTLocalization } from '../../hooks/useTableLocalization';
import { useTableQuery } from '../../hooks/useTableQuery';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useMRTDateAdapterLocale } from '../../hooks/useMRTDateAdapterLocale';
import { SectionHeader } from '../../components/SectionHeader/SectionHeader';

export const SessionDetailPage: React.FC = (): JSX.Element => {
	const api = useSwaggerApi();
	const { isAllowed } = useACL();
	const { t } = useTranslation();
	const reactQueryClient = useReactQueryClient();
	const { id } = useParams();
	const formatDate = useFormatDate();
	const { MRTLocalization } = useMRTLocalization();
	const { MRTDateAdapterLocale: adapterLocale } = useMRTDateAdapterLocale();

	const [hasRunningSession, setHasRunningSession] = React.useState(false);
	const [durationIncrement, setDurationIncrement] = React.useState(0);
	const [isRunning, setIsRunning] = React.useState(false);

	const intervalRef = React.useRef<NodeJS.Timeout | null>(null);

	const FETCH_INTERVAL = 5000;

	const {
		data: sessionData,
		isRefetching: isSessionRefetching,
		isLoading: isSessionLoading,
		error: sessionError,
	} = useQuery<PartialPamSessionDto | null>({
		queryKey: [EQueryKey.SESSION_DETAIL_QUERY, id],
		queryFn: async () => {
			try {
				const response = await api.pam.getPamSessionById(Number(id));
				sessionDetailSchema.parse(response.data);
				response.data?.endAt ? setIsRunning(false) : setIsRunning(true);

				return response.data;
			} catch (error) {
				console.error(error);

				return null;
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});

	const {
		rowSelection,
		setRowSelection,
		columnFilters,
		setColumnFilters,
		sorting,
		setSorting,
		columnVisibility,
		setColumnVisibility,
		globalFilter,
		setGlobalFilter,
		pagination,
		setPagination,
		swaggerQuery,
	} = useTableQuery(['type', 'fileName', 'fileExtension', 'fileSize', 'createdAt']);

	const {
		data: fileTransferData,
		isRefetching: isRefetching,
		isLoading: isLoading,
		error: error,
	} = useQuery<PagedResultFileTransferDto>({
		queryKey: [EQueryKey.PAM_SESSION_FILE_TRANSFERS_QUERY, swaggerQuery],
		queryFn: async () => {
			try {
				const query = {
					filter: swaggerQuery.filter,
					sort: swaggerQuery.sort,
					columns: swaggerQuery.columns,
					offset: swaggerQuery.offset,
					limit: swaggerQuery.limit,
				};

				const response = await api.pam.getFileTransfers(Number(id), query);
				response.data.entities.forEach((fileTransfer) => {
					fileTransferSchema.parse(fileTransfer);
				});

				return response.data;
			} catch (error) {
				console.error(error);

				return { entities: [], total: 0 };
			}
		},
		placeholderData: keepPreviousData,
		refetchOnWindowFocus: false,
	});
	const { entities = [], total = 0 } = fileTransferData ? fileTransferData : {};

	const handleDownloadFile = React.useCallback(
		(fileName: string) => async () => {
			// TODO: Implement download file
		},
		[],
	);

	const getTypescriptUrl = React.useMemo(
		() =>
			(id: number | undefined): string => {
				if (!id) {
					return '';
				}

				return `/api/v1/pam/sessions/${id}/typescript`;
			},
		[],
	);

	const handleDownload = React.useCallback(
		(sessionPath: string | undefined) => (event: React.MouseEvent) => {
			event.stopPropagation();

			if (!sessionPath || !isAllowed([EPermission.PAM_RECORDINGS_READ])) {
				return;
			}

			try {
				const link = document.createElement('a');

				link.href = sessionPath;
				link.setAttribute('download', 'true');

				document.body.appendChild(link);
				link.click();

				document.body.removeChild(link);
			} catch (error) {
				console.error(error);
			}
		},
		[isAllowed],
	);

	const formatBytes = React.useCallback((bytes: number, decimals: number): string => {
		if (!bytes) {
			return '0 Bytes';
		}

		const k = 1024;
		const dm = decimals <= 0 ? 0 : decimals || 2;
		const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

		const i = Math.floor(Math.log(bytes) / Math.log(k));

		return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`;
	}, []);

	const getRecordingUrl = React.useMemo(
		() =>
			(id: number | undefined): string => {
				if (!id) {
					return '';
				}

				return `/api/v1/pam/sessions/${id}/recording`;
			},
		[],
	);

	const renderTransferTypeIcon = React.useMemo(() => {
		const renderFileTransferTypeIcon = (value: string) => {
			if (!value) {
				return <Typography>{t('page.sessions.table.body.unknown')}</Typography>;
			}

			switch (value) {
				case EFileTransfer.DOWNLOAD:
					return (
						<Tooltip arrow placement={'right'} title={t('page.sessions.tooltips.download')}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '0.2rem',
									backgroundColor: '#0e910ea3',
									color: 'white',
									borderRadius: '50%',
								}}
							>
								<DownloadIcon color={'inherit'} fontSize={'large'} />
							</Box>
						</Tooltip>
					);
				case EFileTransfer.UPLOAD:
					return (
						<Tooltip arrow placement={'right'} title={t('page.sessions.tooltips.upload')}>
							<Box
								sx={{
									display: 'flex',
									justifyContent: 'center',
									alignItems: 'center',
									padding: '0.2rem',
									backgroundColor: '#0288d196',
									color: 'white',
									borderRadius: '50%',
								}}
							>
								<UploadIcon color={'inherit'} fontSize={'large'} />
							</Box>
						</Tooltip>
					);
				default:
					return <Typography>{t('page.sessions.tooltips.unknown')}</Typography>;
			}
		};

		return renderFileTransferTypeIcon;
	}, []);

	const checkHasRunningSession = React.useCallback((sessions: PartialPamSessionDto[]): boolean => {
		if (sessions.length === 0) {
			return false;
		}

		let refreshNeeded = false;

		sessions.forEach((session) => {
			if (!session.endAt) {
				refreshNeeded = true;
			}
		});

		setHasRunningSession(refreshNeeded);

		return refreshNeeded;
	}, []);

	const renderDuration = React.useMemo(() => {
		const renderSessionDuration = (startAt: string | undefined, endAt: string | undefined | null): string => {
			const duration = calculateDuration(startAt, endAt, durationIncrement);
			const durationString = formatDurationToString(duration);

			return durationString;
		};

		return renderSessionDuration;
	}, [durationIncrement]);

	React.useEffect(() => {
		if (!sessionData) {
			return;
		}

		checkHasRunningSession([sessionData]);
	}, [sessionData]);

	React.useEffect(() => {
		if (!sessionData) {
			return;
		}

		const handleDurationIncrement = () => {
			if (!hasRunningSession) {
				return;
			}
			intervalRef.current = setInterval(() => {
				setDurationIncrement((prevValue) => prevValue + 1000);
			}, 1000);
		};

		if (intervalRef.current) {
			clearInterval(intervalRef.current);
		}

		if (!intervalRef.current && durationIncrement === 0) {
			handleDurationIncrement();
		}
	}, [hasRunningSession]);

	React.useEffect(() => {
		return () => {
			reactQueryClient.unmountReactQuery();
		};
	}, []);

	React.useEffect(() => {
		if (!durationIncrement) {
			return;
		}

		if (durationIncrement > FETCH_INTERVAL) {
			reactQueryClient.invalidateQueries();
			setDurationIncrement(0);
		}
	}, [durationIncrement]);

	const columns = React.useMemo<MRTColumnDef<FileTransferDto>[]>(
		() => [
			{
				accessorKey: 'type',
				filterVariant: 'select',
				filterSelectOptions: Object.values(EFileTransferType).map((transferType) => ({
					label: t(`page.sessions.table.transferType.${transferType}`),
					value: transferType,
				})),
				header: t('page.sessions.table.header.transferType'),
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{renderTransferTypeIcon(row.original.type)}
					</Box>
				),
			},
			{
				accessorKey: 'fileName',
				header: t('page.sessions.table.header.name'),
			},
			{
				accessorKey: 'fileExtension',
				header: t('page.sessions.table.header.extension'),
			},
			{
				accessorKey: 'createdAt',
				filterVariant: 'datetime-range',
				header: t('page.sessions.table.header.timestamp'),
				minSize: 340,
				muiFilterDateTimePickerProps: {
					slotProps: {
						textField: {
							sx: { zIndex: 1 },
						},
					},
				},
				Cell: ({ renderedCellValue }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{formatDate(renderedCellValue as string, true)}
					</Box>
				),
			},
			{
				accessorKey: 'fileSize',
				header: t('page.sessions.table.header.fileSize'),
				enableGlobalFilter: false,
				enableColumnFilter: false,
				Cell: ({ row }) => (
					<Box
						sx={{
							display: 'flex',
							alignItems: 'center',
							gap: '1rem',
						}}
					>
						{formatBytes(row.original.fileSize, 2)}
					</Box>
				),
			},
		],
		[],
	);

	return (
		<Box sx={{ marginBottom: 10 }}>
			<Paper
				sx={{
					padding: 2,
				}}
			>
				<Box sx={{ marginBottom: 2 }}>
					<PageHeader
						title={t('page.sessions.titleDetail')}
						description={t('page.sessions.descriptionDetail')}
						icon={PlaylistPlayIcon}
					/>
				</Box>
				<Grid container direction={'column'} spacing={2}>
					<Grid item xs={12}>
						<Grid container justifyContent={'space-between'} spacing={2}>
							<Grid item xs={12} md={4}>
								<Grid container direction={'column'} spacing={1}>
									{sessionData && (
										<>
											<Grid container padding={'8px'} alignItems='center'>
												<Grid item xs={5}>
													<Typography>
														{
															<strong>{`${t('page.sessions.table.header.target')}: `}</strong>
														}
													</Typography>
												</Grid>
												<Grid item xs={7}>
													<Stack alignItems='center' direction='row' spacing={1}>
														<Tooltip arrow placement='right' title={sessionData.targetType}>
															<img
																alt='Logo'
																height={28}
																src={getSessionTargetLogo(sessionData?.targetType)}
																loading='lazy'
																style={{ borderRadius: '4px' }}
															/>
														</Tooltip>
														<Typography>{sessionData.targetName}</Typography>
													</Stack>
												</Grid>
											</Grid>
											<Grid container padding={'8px'}>
												<Grid item xs={5}>
													<Typography>
														{
															<strong>{`${t('page.sessions.table.body.username')}: `}</strong>
														}
													</Typography>
												</Grid>
												<Grid item xs={7}>
													<Typography>{`${sessionData.userName} ${sessionData.userSurname ? sessionData.userSurname : ''}`}</Typography>
												</Grid>
											</Grid>
											<Grid container padding={'8px'}>
												<Grid item xs={5}>
													<Typography>
														{
															<strong>{`${t('page.sessions.table.header.account')}: `}</strong>
														}
													</Typography>
												</Grid>
												<Grid item xs={7}>
													<Typography>{sessionData.accountName}</Typography>
												</Grid>
											</Grid>
											<Grid container padding={'8px'}>
												<Grid item xs={5}>
													<Typography>
														{
															<strong>{`${t('page.sessions.table.header.start')}: `}</strong>
														}
													</Typography>
												</Grid>
												<Grid item xs={7}>
													<Typography>
														{sessionData.startAt && formatDate(sessionData.startAt, true)}
													</Typography>
												</Grid>
											</Grid>
											<Grid container padding={'8px'}>
												<Grid item xs={5}>
													<Typography>
														{<strong>{`${t('page.sessions.table.header.end')}: `}</strong>}
													</Typography>
												</Grid>
												<Grid item xs={7}>
													{isRunning ?
														<Chip
															label={t('page.sessions.table.body.running')}
															variant='outlined'
															color='success'
															sx={{ width: '100px' }}
														/>
													:	sessionData.endAt && formatDate(sessionData.endAt, true)}
												</Grid>
											</Grid>
											<Grid container padding={'8px'}>
												<Grid item xs={5}>
													<Typography>
														{
															<strong>{`${t('page.sessions.table.header.duration')}: `}</strong>
														}
													</Typography>
												</Grid>
												<Grid item xs={7}>
													<Typography>
														{renderDuration(sessionData.startAt, sessionData.endAt)}
													</Typography>
												</Grid>
											</Grid>
										</>
									)}
								</Grid>
							</Grid>
							{isAllowed([EPermission.PAM_RECORDINGS_READ]) && sessionData?.recordingPath && (
								<Grid item xs={12} md={8}>
									{sessionData?.id && <PAMSessionPlayer src={getRecordingUrl(sessionData?.id)} />}
								</Grid>
							)}
							{sessionData?.recordingPath && (
								<Grid container padding={'8px'}>
									{isAllowed(
										[EPermission.PAM_SESSIONS_READ_ALL, EPermission.PAM_SESSIONS_READ_OWN],
										false,
									) &&
										sessionData?.targetType === ESessionTarget.SSH && (
											<Grid item>
												<Tooltip
													arrow
													placement='bottom'
													title={t('page.sessions.tooltips.downloadTypescript')}
												>
													<IconButton
														onClick={handleDownload(getTypescriptUrl(sessionData?.id))}
													>
														<span style={{ color: 'black', fontSize: 'small' }}>
															{t('page.sessions.table.body.typescript')}
														</span>
														<DownloadIcon />
													</IconButton>
												</Tooltip>
											</Grid>
										)}
									<Grid item xs style={{ textAlign: 'right' }}>
										{isAllowed([EPermission.PAM_RECORDINGS_READ]) && (
											<Tooltip
												arrow
												placement='bottom'
												title={t('page.sessions.tooltips.downloadRecording')}
											>
												<IconButton onClick={handleDownload(getRecordingUrl(sessionData?.id))}>
													<DownloadIcon />
													<span style={{ color: 'black', fontSize: 'small' }}>
														{t('page.sessions.table.body.recording')}
													</span>
												</IconButton>
											</Tooltip>
										)}
									</Grid>
								</Grid>
							)}
						</Grid>
					</Grid>

					<Grid item xs={12} sx={{ width: '100%' }}>
						<Stack spacing={2}>
							<SectionHeader
								title={t('page.sessions.subtitle.fileTransfers')}
								description={t('page.sessions.table.description.fileTransfers')}
							/>
							<LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={adapterLocale}>
								<MaterialReactTable
									layoutMode='grid'
									columns={columns}
									data={entities}
									enableRowActions
									enableStickyHeader={false}
									state={{
										isLoading: isLoading,
										showAlertBanner: error !== null,
										pagination,
										rowSelection,
										showProgressBars: isRefetching,
										columnFilters,
										globalFilter,
										sorting,
										columnVisibility,
									}}
									muiToolbarAlertBannerProps={{
										color: 'error',
										children: <>{error}</>,
									}}
									initialState={{ columnVisibility: { createdAt: false }, density: 'compact' }}
									rowCount={total}
									manualPagination
									manualFiltering
									manualSorting
									onSortingChange={setSorting}
									onGlobalFilterChange={setGlobalFilter}
									onColumnFiltersChange={setColumnFilters}
									onPaginationChange={setPagination}
									onColumnVisibilityChange={setColumnVisibility}
									onRowSelectionChange={setRowSelection}
									renderRowActions={({ row }) => (
										<Box
											sx={{
												display: 'flex',
												alignItems: 'center',
												gap: '1rem',
											}}
										>
											{isAllowed([EPermission.PAM_RECORDINGS_READ]) && (
												<Box sx={{ display: 'flex', justifyContent: 'center', gap: '1rem' }}>
													<Tooltip
														arrow
														placement='right'
														title={t('page.sessions.tooltips.download')}
													>
														<IconButton onClick={handleDownloadFile(row.original.fileName)}>
															<DownloadIcon />
														</IconButton>
													</Tooltip>
												</Box>
											)}
										</Box>
									)}
									renderToolbarInternalActions={({ table }) => (
										<Box>
											<MRTToggleGlobalFilterButton table={table} />
											<MRTToggleFiltersButton table={table} />
											<MRTShowHideColumnsButton table={table} />
											{/* <Tooltip arrow title={t('page.sessions.tooltips.export')}>
											<IconButton>
												<SaveAltIcon />
											</IconButton>
										</Tooltip> */}
											<MRTToggleDensePaddingButton table={table} />
											<MRTFullScreenToggleButton table={table} />
										</Box>
									)}
									displayColumnDefOptions={{
										'mrt-row-actions': {
											header: t('page.sessions.table.header.actions'),
										},
										'mrt-row-select': {
											enableHiding: true,
											visibleInShowHideMenu: false,
										},
									}}
									muiTablePaperProps={({ table }) => ({
										style: {
											zIndex: table.getState().isFullScreen ? 1250 : undefined,
											boxShadow: 'none',
											outline: '1px solid #e0e0e0',
										},
									})}
									muiSelectCheckboxProps={() => ({
										sx: {
											width: '50px',
											height: '50px',
										},
									})}
									muiSelectAllCheckboxProps={() => ({
										sx: {
											width: '50px',
											height: '50px',
										},
									})}
									muiTableHeadCellProps={() => ({
										sx: {
											verticalAlign: 'baseline',
										},
									})}
									localization={MRTLocalization}
									positionActionsColumn='last'
								/>
							</LocalizationProvider>
						</Stack>
					</Grid>
				</Grid>
			</Paper>
		</Box>
	);
};
